import React, { useRef } from 'react'

const debug = require('debug')('app:ui:common:Video')

type Props = React.VideoHTMLAttributes & {
  onWatched: Function
}

export function Video(props: Props) {
  const { onWatched, ...videoProps } = props
  const ref = useRef({ prevNow: Date.now(), total: 0, currentTimeAtSeekStart: 0 })

  const onPlay = () => {
    ref.current.prevNow = Date.now()
  }

  const checkStatus = (video: HTMLVideoElement) => {
    if (video?.played) {
      let duration = 0
      for (let i = 0; i < video.played.length; i++) {
        const start = video.played.start(i)
        const end = video.played.end(i)
        duration += end - start
        debug('[checkStatus] start=%s end=%s', start, end)
      }
      const almostFullDuration = video.duration * 0.75
      const amountWatched = duration
      debug('[checkStatus] almostFullDuration=%s amountWatched=%s', almostFullDuration, amountWatched)
      if (amountWatched > almostFullDuration) {
        debug('[checkStatus] onWatched')
        onWatched()
      }
    } else {
      const now = Date.now()
      const playedFor = now - ref.current.prevNow
      ref.current.total += playedFor
      ref.current.prevNow = now

      const almostFullDuration = video.duration * 0.75
      const amountWatched = ref.current.total / 1000

      debug(
        '[checkStatus] playedFor=%s total=%s almostFullDuration=%s amountWatched=%s',
        playedFor,
        ref.current.total,
        almostFullDuration,
        amountWatched
      )
      if (amountWatched > almostFullDuration) {
        debug('[checkStatus] onWatched')
        onWatched()
      }
    }
  }

  return (
    <video
      controls
      {...videoProps}
      onPlay={onPlay}
      onProgress={e => checkStatus(e.target)}
      onPause={e => checkStatus(e.target)}
    />
  )
}
