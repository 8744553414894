import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { t } from '../../../i18n'
import { useEmployeeSignupContext } from '../../../hooks'
import { FileUploadButton } from '../../common'
import type { User } from '../../../types'

import './Step3.scss'

export function Step3({ singleStepMode }) {
  const { user, setUser, submit } = useEmployeeSignupContext()
  const navigate = useNavigate()

  const next = async () => {
    if (singleStepMode) {
      await submit().then(() => {
          navigate('/profile')
      })
    } else {
      navigate('/register/step-4')
    }
  };
  
  const back = () => {
    if (singleStepMode) {
      navigate('/profile')
    } else {
      navigate(-1)
    }
  };

  const onChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    _.set(tmp, prop, val)
    setUser(tmp)
  }
  
  return (
    <Dialog
      fullWidth
      open={true}
      className="step3"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Sign Up')}
        </Typography>        
      </DialogTitle>
      <DialogContent>
        <Grid container my={2} spacing={2}>
          <Grid item xs={12}>
            <FileUploadButton
              value={user?.profile?.profile_pic}
              onChange={e => onChange('profile.profile_pic', e.target.files[0])}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant="h6" color="text.secondary">
              {t('Please attach a photo where you are smiling. You are much more likely to get selected. At Compago we only hire people that are friendly and that add to the overall culture of the amazing jobsites that we work on.')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={back}>
              {singleStepMode ? t('Cancel') : t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button disabled={!isValid(user)} fullWidth size="large" color="primary" variant="contained" onClick={next}>
              {singleStepMode ? t('Save') : t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

function isValid(user: User) {
  let valid = false
  try {
    valid = !!user?.profile?.profile_pic
  } catch {
    //
  }
  return valid
}
