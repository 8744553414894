import './Clients.scss'

import { NavLink, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Tab,
  Tabs,
  TextField
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import { useMatchListQuery, useOrgDetailQuery, useOrgListQuery } from '../../../hooks'
import { LOADING } from '../../../constants'
import { useContext, useMemo, useState } from 'react'
import { Matches } from '../employer/Matches'
import { OrgContext, UserDetailBaseUrlContext } from '../../../context'
import { UserDetail } from '../../user'
import { api } from '../../../api'
import { toast, toastWarn } from '../../../events'
import { useEffect } from 'react'
import _ from 'lodash'

export function Clients() {
  const { data: clients, loading, refetch } = useOrgListQuery()

  return (
    <div className="Clients">
      <ClientList clients={clients} loading={loading} refetch={refetch} />
      <Routes>
        <Route path=":clientId/*" element={<ClientDetail clients={clients} />} />
      </Routes>
    </div>
  )
}

export function ClientList({ clients, loading, refetch }) {
  const [show, setShow] = useState(false)
  if (loading || clients === LOADING) {
    return <CircularProgress />
  }

  return (
    <div className="ClientList">
      {clients.map(org => (
        <NavLink key={org.id} to={`/clients/${org.id}`}>
          {org.name}
        </NavLink>
      ))}

      <Fab
        color="primary"
        size="medium"
        sx={{ position: 'absolute', bottom: 20, left: 10 }}
        onClick={() => setShow(true)}
      >
        <AddIcon />
      </Fab>

      {show ? <ClientForm refetch={refetch} onClose={() => setShow(false)} /> : null}
    </div>
  )
}

export function ClientForm({ refetch, onClose }) {
  const navigate = useNavigate()
  const [name, setName] = useState('')

  const onSave = async () => {
    try {
      const org = await api.orgs.create({ name: name.trim() })
      console.log(org)
      toast('Saved')
      onClose()
      refetch()
      navigate(`/clients/${org.id}`)
    } catch (err) {
      toastWarn(err.message)
    }
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>New Client</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="name"
          label="Name"
          onChange={e => setName(e.target.value)}
          sx={{ width: 300 }}
          autoFocus
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export function ClientDetail() {
  const location = useLocation()
  const navigate = useNavigate()
  const id = Number(useParams().clientId)
  const { data: org, refetch: refetchOrg } = useOrgDetailQuery(id)
  const params = useMemo(() => ({ orgId: id }), [id])
  const { data, refetch } = useMatchListQuery(params)
  const [tab, setTab] = useState(0)

  const contextValue = useMemo(() => {
    return { org, refetchOrg }
  }, [org, refetchOrg])

  if (org === LOADING) {
    return <CircularProgress />
  }

  const onClose = () => {
    let parts = location.pathname.split('/')
    parts.pop()
    parts.pop()
    const url = parts.join('/')
    navigate(url)
  }

  return (
    <div className="ClientDetail">
      <OrgContext.Provider value={contextValue}>
        <UserDetailBaseUrlContext.Provider value={location.pathname + '/users'}>
          <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
            <Tab label="Matches" />
            <Tab label="Account" />
            <Tab label="Profile" />
          </Tabs>
          {tab === 0 ? <Matches data={data} refetch={refetch} /> : null}
          {tab === 1 ? <ClientAccount /> : null}
          {tab === 2 ? <ClientProfile /> : null}
          <Routes>
            <Route path="users/:userId" element={<UserDetail onClose={onClose} />} />
          </Routes>
        </UserDetailBaseUrlContext.Provider>
      </OrgContext.Provider>
    </div>
  )
}

export function ClientAccount() {
  // eslint-disable-next-line no-unused-vars
  const { org } = useContext(OrgContext)

  return (
    <div className="ClientAccount" style={{ padding: 20 }}>
      <Button variant="contained" disabled>
        Send notes via email
      </Button>
    </div>
  )
}

export function ClientProfile() {
  const { org } = useContext(OrgContext)
  const [users, setUsers] = useState([])

  useEffect(() => {
    console.log('[useEffect] org.users')
    setUsers(_.cloneDeep(org.users))
  }, [org.users])

  const onSaveClick = async () => {
    if (!users.length) {
      return
    }

    try {
      for (const user of users) {
        if (user.id) {
          await api.users.update({
            id: user.id,
            first_name: user.first_name.trim(),
            last_name: user.last_name.trim(),
            email: user.email.trim()
          })
        } else {
          const savedUser = await api.users.save({
            ...user,
            username: user.email,
            org: org.id,
            type: 'EMPLOYER',
            status: 'INVITED'
          })
          console.log(savedUser)
        }
      }

      await api.orgs.sendInvites(org)

      toast('Saved.')
    } catch (err) {
      toastWarn(err.message)
    }
  }

  const onAddClick = () => {
    setUsers([{ first_name: '', last_name: '', email: '' }, ...users])
  }

  const onChange = (k, v, i) => {
    const users2 = _.cloneDeep(users)
    users2[i][k] = v
    setUsers(users2)
  }

  return (
    <div className="ClientProfile" style={{ padding: 20 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
        <h3 style={{ margin: 0 }}>Users</h3>
        <Button variant="outlined" onClick={onAddClick} startIcon={<AddIcon />} sx={{ ml: 3 }} size="small">
          Add User
        </Button>
      </Box>
      {users.map((user, i) => (
        <Box
          key={user.id || -i}
          sx={{ pt: 1, pb: 1, display: 'grid', columnGap: 2, gridTemplateColumns: '1fr 1fr 1fr' }}
        >
          <TextField
            label="First name"
            value={user.first_name}
            onChange={e => onChange('first_name', e.target.value, i)}
          />
          <TextField
            label="Last name"
            value={user.last_name}
            onChange={e => onChange('last_name', e.target.value, i)}
          />
          <TextField label="Email" value={user.email} onChange={e => onChange('email', e.target.value, i)} />
        </Box>
      ))}
      <Box sx={{ pt: 2, display: 'flex', alignItems: 'center' }}>
        <Button variant="contained" onClick={onSaveClick} startIcon={<SaveIcon />}>
          Save
        </Button>
        <span style={{ marginLeft: 20, fontSize: '14px', color: '#aaa' }}>
          New users will receive an invitation email with a link to complete their Compago account.
        </span>
      </Box>
    </div>
  )
}
