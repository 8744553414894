import './Matches.scss'

import { useContext, useState } from 'react'
import { CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { NavLink } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import { useMatchListQuery } from '../../../hooks'
import { LOADING } from '../../../constants'
import { UserDetailBaseUrlContext } from '../../../context'
import { UserAvatar } from '../../common'
import { api } from '../../../api'

export function MatchesPage() {
  const { data, refetch } = useMatchListQuery()
  return <Matches data={data} refetch={refetch} />
}

export function Matches({ data, refetch }) {
  const onDragEnd = async result => {
    if (!result.destination || result.destination.droppableId === result.source.droppableId) {
      return
    }
    const label = result.destination.droppableId.toUpperCase()
    const id = Number(result.draggableId)
    const match = data.find(m => m.id === id)
    if (!match) {
      throw new Error(`Match ${id} not found`)
    }
    await api.matches.update({ id, label })
    await refetch()
  }

  const newMatches = data?.filter?.(m => m.label === 'NEW' || !m.label)
  const yesMatches = data?.filter?.(m => m.label === 'YES')
  const maybeMatches = data?.filter?.(m => m.label === 'MAYBE')
  const noMatches = data?.filter?.(m => m.label === 'NO')
  const all = [
    [newMatches, 'New'],
    [yesMatches, 'Yes'],
    [maybeMatches, 'Maybe'],
    [noMatches, 'No']
  ]

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="Matches">
        {data === LOADING ? (
          <CircularProgress />
        ) : (
          all.map(([list, label], i) => <MatchGroup key={i} matches={list} label={label} refetch={refetch} />)
        )}
      </div>
    </DragDropContext>
  )
}

function MatchGroup({ matches, label, refetch }) {
  return (
    <Droppable droppableId={label}>
      {(provided, snapshot) => (
        <div
          className={classNames('match-group shadow-1', label)}
          ref={provided.innerRef}
          style={{ backgroundColor: snapshot.isDraggingOver ? '#fafafa' : '' }}
          {...provided.droppableProps}
        >
          <div className="header">{label}</div>
          <div className="matches">
            {matches.length === 0 ? (
              <div className="empty-msg">
                <span>Empty</span>
              </div>
            ) : (
              matches.map((m, i) => <Match key={m.id} match={m} index={i} refetch={refetch} />)
            )}
          </div>
        </div>
      )}
    </Droppable>
  )
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? '#eeea' : '',
  ...draggableStyle
})

function Match({ match, index, filter, refetch }) {
  const baseUrl = useContext(UserDetailBaseUrlContext)

  return (
    <Draggable draggableId={String(match.id)} index={index}>
      {(provided, snapshot) => (
        <div
          className="Match"
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <div>
            <IconButton size="small" variant="text" {...provided.dragHandleProps}>
              <DragIndicatorIcon />
            </IconButton>
          </div>
          <div>
            <UserAvatar user={match.user} useDataUrl={false} />
          </div>
          <div>
            <NavLink to={`${baseUrl}/${match?.user?.id}`}>{match?.user?.full_name}</NavLink>
          </div>
          <div>{match?.user?.profile?.data?.trade}</div>
          <div>
            <Actions match={match} filter={filter} refetch={refetch} />
          </div>
        </div>
      )}
    </Draggable>
  )
}

function Actions({ match, refetch }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const id = `match_${match.id}_actions`

  const onChange = async (label: String) => {
    await api.matches.update({ id: match.id, label })
    setAnchorEl(null)
    refetch()
  }

  return (
    <div className="Actions">
      <IconButton size="small" aria-describedby={id} variant="text" onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreHorizIcon />
      </IconButton>
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List>
          {![null, 'NEW'].includes(match.label) ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => onChange('NEW')}>
                <ListItemText primary="Move to New group" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {match.label !== 'YES' ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => onChange('YES')}>
                <ListItemText primary="Move to Yes group" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {match.label !== 'MAYBE' ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => onChange('MAYBE')}>
                <ListItemText primary="Move to Maybe group" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {match.label !== 'NO' ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => onChange('NO')}>
                <ListItemText primary="Move to No group" />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Popover>
    </div>
  )
}
