import { Button, Checkbox, Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useState } from 'react'

import { t } from '../../../i18n'
import { useEmployeeSignupContext } from '../../../hooks'
import { formatPhoneNumber, isEmptyString, isValidEmail } from '../../../util/stringUtil'
import type { User } from '../../../types'

export function Step2({ singleStepMode }) {
  const { user, setUser, submit } = useEmployeeSignupContext()
  const [termsChecked, setTermsChecked] = useState(false)
  const navigate = useNavigate()

  const next = async () => {
    if (singleStepMode) {
      await submit().then(() => {
          navigate('/profile')
      })
    } else {
      navigate('/register/step-3')
    }
  };
  
  const back = () => {
    if (singleStepMode) {
      navigate('/profile')
    } else {
      navigate(-1)
    }
  };

  const onChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    _.set(tmp, prop, val)
    setUser(tmp)
  }
  
  const isValid = (user: User) => {
    let valid = false
    try {
      valid =
        (!isEmptyString(user.first_name) &&
        !isEmptyString(user.last_name) &&
        !isEmptyString(user?.profile?.phone_number) &&
        isValidEmail(user.email)) && termsChecked
    } catch {
      // todo: error response, consider using formik
    }
    return valid
  }
  
  return (
    <Dialog
      fullWidth
      open={true}
      className="step2"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Sign Up')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container my={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('First Name')}
            </Typography>
            <TextField
              autoFocus
              id="first_name"
              value={user.first_name}
              onChange={e => onChange('first_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('Last Name')}
            </Typography>
            <TextField
              id="last_name"
              value={user.last_name}
              onChange={e => onChange('last_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('Email')}
            </Typography>
            <TextField
              id="email"
              value={user.email}
              onChange={e => onChange('email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('Phone Number')}
            </Typography>
            <TextField
              id="phone_number"
              value={user?.profile?.data?.phone_number}
              onChange={e => onChange('profile.data.phone_number', e.target.value)}
              onBlur={() => onChange('profile.data.phone_number', formatPhoneNumber(user?.profile?.data?.phone_number))}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('GDPR Agreement')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Checkbox
              value={termsChecked}
              onChange={e => setTermsChecked(e.target.checked)}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1" color="text.secondary">
              {t('I consent to receive communications by email and text message (SMS).')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={back}>
              {singleStepMode ? t('Cancel') : t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button disabled={!isValid(user)} fullWidth size="large" color="primary" variant="contained" onClick={next}>
              {singleStepMode ? t('Save') : t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
