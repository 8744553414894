import React from 'react'
import { useNavigate } from "react-router-dom";
import ButtonCustom from '.';
import { t } from "../../../i18n";

const FindWorkButton = () => {
    const navigate = useNavigate();
    return (
        <ButtonCustom
            variant="contained"
            className="custom-btn-outline"
            onClick={() => navigate("/register")}
            >
            {t("Find work")}
        </ButtonCustom>
    )
}

export default FindWorkButton
