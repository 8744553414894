/* eslint-disable no-nested-ternary */
import { Button } from "@mui/material";

export const ThemeButton = (props) => {
  return (
    <Button
      className={`btn ${
        props.variant === "outlined"
          ? `btn_outlined`
          : `btn_${props.color ?? "primary"}`
      }`}
      disabled={props.disabled}
      {...props}
      disableRipple={props.variant === "text"}
    />
  );
};
