import { useContext } from 'react'
import { EmployeeSignupContext, EmployeeSignupRoundTwoContext } from '../context'

export * from './useForceUpdate'
export * from './useQuery'
export * from './useQueryParam'
export * from './useScrollToTop'

export function useEmployeeSignupContext() {
  return useContext(EmployeeSignupContext)
}

export function useEmployeeSignupRoundTwoContext() {
  return useContext(EmployeeSignupRoundTwoContext)
}
