import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import mission from "../../../../assets/about_page/mission_border_line.png";
import img4_grey from "../../../../assets/compago_university_page/img4-grey.png";
import {
  default as img1_grey,
  default as img1_white,
} from "../../../../assets/compago_university_page/img_5.png";
import {
  default as img2_grey,
  default as img2_white,
} from "../../../../assets/compago_university_page/img_6.png";
import {
  default as img3_grey,
  default as img3_white,
} from "../../../../assets/compago_university_page/img_7.png";
import ButtonCustom from "../../../common/Button";

import { t } from "../../../../i18n";
import { Link } from 'react-router-dom'

const GroundWork = () => {
  return (
    <div className="groundWorkSection">
      <Container maxWidth="xl" className="groundWork_container">
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={8} textAlign={"center"}>
            <Box className="groundWorkSection-heading">
              <img src={mission} alt="" className="mission" />
              <Typography variant="h2" gutterBottom>
                {t("Supplemental Courses")}
              </Typography>
              <div className="groundWorkSection-sub-heading">
                <Typography variant="subtitle1">
                  {t(
                    `Supplemental courses are sent to our team on a regular basis. These courses teach new lessons but also are a refresher to remind them that they are part of a bigger team and a company that invests in their professional and personal growth.`
                  )}
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="margin-top-2">
          <Grid item xs={12} md={6} className="margin-bottom-1">
            <GroundWorkCard
              title={t("Smartphone Etiquette")}
              imgGrey={img1_grey}
              imgWhite={img1_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239703-smartphone-etiquette-on-the-jobsite-balancing-connectivity-and-productivity"
              btn={t("View Course")}
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <GroundWorkCard
              title={t("Scan the Ground as You Walk Around")}
              imgGrey={img2_grey}
              imgWhite={img2_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239797-scan-the-ground-as-you-walk-around"
              btn={t("View Course")}
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <GroundWorkCard
              title={t("Finding Fulfillment in Your Career")}
              imgGrey={img3_grey}
              imgWhite={img3_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239033-compago-university-elevating-our-team"
              btn={t("View Course")}
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <GroundWorkCard
              title={t(
                "We Add New Courses on a Regular Basis to Keep the Content Fresh"
              )}
              description={t("This course offers guidance on navigating transitions at Compago, highlighting our commitment to support, growth, and open dialogue.")}
              imgGrey={img4_grey}
              imgWhite={img3_white}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default GroundWork;

export const CommingSoon = ({ title, subTitle }) => (
  <Card className="groundWorkSection-card_v2">
    <Box className="groundWorkSection-icon-element_v2">
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {subTitle}
      </Typography>
    </Box>
  </Card>
);

export const GroundWorkCard = ({ title, description, imgGrey, imgWhite, accessLink, btn }) => (
  <Card className="groundWorkSection-card">
    <Box className="groundWorkSection-icon-element">
      <span className="imgGrey">
        {" "}
        <img src={imgGrey} alt="" />
      </span>
      <span className="imgWhite_overlay"></span>
    </Box>
    <Box className="groundWorkSection-content-element">
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      <p>{description}</p>

      {btn && (
        <Link to={accessLink} target="_blank"><ButtonCustom variant="contained" className="custom-btn-outline">
          {btn}
        </ButtonCustom></Link>
      )}
    </Box>
  </Card>
);
