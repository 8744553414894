import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import vector1 from "../../../../assets/about_page/mission_border_line.png";
import SkillIcon1 from "../../../../assets/services_page/SkillIcon1.png";
import SkillIcon2 from "../../../../assets/services_page/SkillIcon2.png";
import SkillIcon3 from "../../../../assets/services_page/SkillIcon3.png";
import img1 from "../../../../assets/services_page/skill-1.png";
import img2 from "../../../../assets/services_page/skill-2.png";
import img3 from "../../../../assets/services_page/skill-3.png";
import ServiceCard from "../../../common/ServiceCard";

import { t } from "../../../../i18n";

const Custom = () => {
  return (
    <div className="custom">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="custom-heading">
              <img src={vector1} alt="" className="mission" />
              <Typography variant="h2" gutterBottom>
                {t("Skilled Construction Tradesmen")}
              </Typography>
              <div className="custom-sub-heading">
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>
                  {t(
                    "We provide high quality tradesmen from skilled concrete workers to finish carpenters. Let us know how we can help!"
                  )}
                </Typography>
                {/* <Typography variant="subtitle1">
                  {t(
                    "construction solutions, tailored to meet unique client visions."
                  )}
                </Typography> */}
              </div>
            </Box>
          </Grid>
        </Grid>
        <div className="blue-highlight"></div>
        <Grid container spacing={3} className="relative margin-top-2">
          <Grid item xs={12} md={4}>
            <ServiceCard
              title={t("Experienced Tradesman")}
              cardImage={img1}
              showUserImg2={SkillIcon1}
              content={t(
                "Expert professionals skilled in complex trades, ensuring excellence and safety in jobsite settings. Our tradesmen deliver consistent quality and reliability."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceCard
              title={t(`Quality Work`)}
              // subTitle={t(`Custom Luxury Construction`)}
              cardImage={img3}
              showUserImg2={SkillIcon2}
              content={t(
                "Delivering superior results through meticulous processes, ensuring excellence and precision in every project."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceCard
              title={t("Fill the Gaps")}
              cardImage={img2}
              showUserImg2={SkillIcon3}
              content={t(
                `Seamlessly integrating skilled professionals to enhance your workforce, ensuring no disruption and maintaining timelines and productivity.`
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Custom;
