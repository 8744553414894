import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
const SimpleCard = ({ text, image }) => {
  return (
    <>
      <Card className="simple-card">
        <div className="simple-card-media">
          <CardMedia
            component="img"
            height="150"
            image={image}
            alt="green iguana"
            className="simple-card-img"
          />
        </div>
        <CardContent className="simple-card-content">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="simple-card-title"
          >
            {text}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default SimpleCard;
