import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Form, validators } from '../common/Form'
import { toastWarn } from '../../events'
import { t } from '../../i18n'

import './Login.scss'

export function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const [fields, setFields] = useState([
    ['Email', 'email', '', 'text', { autoFocus: true }],
    ['Password', 'password', '', 'password']
  ])

  const onSubmit = async () => {
    const email = fields[0][2]
    const password = fields[1][2]
    try {
      await axios.post('/login', { email, password })
    } catch (err) {
      toastWarn(err.response.data[0])
      return
    }

    const params = new URLSearchParams(location.search)
    const url = params.has('redirect') ? decodeURIComponent(params.get('redirect')) : '/'
    navigate(url)
    window.location.reload()
  }

  return (
    <>
      <div className="Login container">
        <div className="box">
          <h3>{t('Login')}</h3>
          <Form fields={fields} onChange={setFields} validate={validators.allNonEmpty} onSubmit={onSubmit} />
        </div>
      </div>
    </>
  )
}
