/* eslint-disable jsx-a11y/alt-text */
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { toast } from '../events'
import { getFullImageUrl } from '../util/stringUtil'
import { Footer, UserAvatar } from './common'
import { HomePageNav } from './homepage/HomePageNav'

import './TeamMembers.scss'

export function TeamMembers({ listOnly }) {
  const [loading, setLoading] = useState(true)
  const [employees, setEmployees] = useState([])

  useEffect(() => {
    axios
      .get('/team')
      .then(res => {
        setEmployees(res.data)
        setLoading(false)
      })
      .catch(err => {
        toast(err.message)
        setLoading(false)
      })
  }, [])

  return (
    <>
      {listOnly ? null : <HomePageNav />}
      <div className="TeamMembers">
        {listOnly ? null : <h1>The Team</h1>}
        {loading ? <CircularProgress /> : null}
        <div className="employees">
          {employees.map(e => (
            <Employee key={e.id} data={e} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}

function Employee({ data }) {
  const parts = _.compact([
    data.name,
    data.hometown ? `from ${data.hometown}` : null,
    data.job_title,
    data.work_location
  ])

  return (
    <div className="Employee">
      <UserAvatar src={getFullImageUrl(data.profile_pic)} width={100} height={100} />
      <div>
        <div className="title">{parts.join(' • ')}</div>
        <div className="about">{data.about_me}</div>
      </div>
    </div>
  )
}
