import { Resource } from './base'

export class NoteResource extends Resource {
  constructor() {
    super('notes')
    this.toggleVisibility = this._createEndpoint({ endpointName: 'toggle_visibility', httpVerb: 'put', detail: true })
  }
}

export const notes = new NoteResource()
