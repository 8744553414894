import './Step2.scss'

import _ from 'lodash'
import { t } from '../../../../i18n'
import { FileUploadButton } from '../../../common'
import { useEmployeeSignupRoundTwoContext } from '../../../../hooks'
import { ONE_MB } from '../../../../constants'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export function Step2() {
  const { user, setUser } = useEmployeeSignupRoundTwoContext()

  const onVideoChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    const allowedFormats = ["mp4","avi","mov","wmv","flv","mkv","webm","mpeg","mpg"];
    const fileFormat = val.name.split('.').pop().toLowerCase();
    if (allowedFormats.includes(fileFormat)) {
      _.set(tmp, prop, val)
    }
    // const fileUrl = URL.createObjectURL(val);
    // _.set(tmp, prop, { url: fileUrl, name: val.name })
    

    setUser(tmp)
  }

  const p1 = t(
    'Take a quick selfie video of yourself. This doesn’t have to be perfect, our clients want to see that you have the type of personality that will fit in with their jobsite culture.'
  )
  const p2 = t('Suggested topics to include:')
  const points = [
    t('Name'),
    t('What type of work do you do'),
    t('Years of experience'),
    t('Type of projects you have worked on'),
    t('Share your strengths')
  ]
  const p3 = t('The profiles that do the best have videos that show you smiling, being relaxed and being yourself!')
  const p4 = t(
    'Example: Hi I’m John, I’m excited about working with you. I am a general handyman, I have been working in construction for 5 years. Basically I can figure out how to fix most things. I have worked on several high end projects and I take pride in my work, do it right the first time and I if I don’t know how to do something I take the time to figure it out or I will ask for help.'
  )
  const navigate = useNavigate()
  const back = () => {
    navigate('/signup-round-two/step-1')
  };

  const next = async () => {
    navigate('/signup-round-two/step-3')
  };
  return (
    <Dialog
      fullWidth
      open={true}
      className="step2"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Signup - Round Two')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p2}
              <List>
                {points?.map((p, i) => (
                  <ListItem key={i}>{p}</ListItem>
                ))}
              </List>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p4}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FileUploadButton
              accept=".mp4,.avi,.mov,.wmv,.flv,.mkv,.webm,.mpeg,.mpg"
              maxSize={ONE_MB * 200}
              value={user.profile?.profile_vid}
              onChange={e => onVideoChange('profile.profile_vid', e.target.files[0])}
            />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button
              fullWidth size="large"
              color="primary"
              variant="contained"
              onClick={back}
            >
              {t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button
              disabled={!user.profile?.profile_vid}
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              onClick={next}
            >
              {t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
