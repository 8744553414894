import { useCallback, useMemo, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'
import _ from 'lodash'

import { t } from '../../../i18n'
import { EmployeeSignupContext } from '../../../context'
import { LinkTab, LinkTabs } from '../../common'
import type { User } from '../../../types'
import { validateUser } from '../../../forms'
import { Step2 } from '../../signup/employee/Step2'
import { Step3 } from '../../signup/employee/Step3'
import { Step4 } from '../../signup/employee/Step4'
import { Step3b } from '../../signup/employee/Step3b'
import { useEmployeeSignupContext } from '../../../hooks'
import { api } from '../../../api'

import './Profile.scss'

export function Profile() {
  const [user, setUser] = useState(_.cloneDeep(api.user))
  const [saving, setSaving] = useState(false)
  const location = useLocation()

  const submit = useCallback(async () => {
    try {
      setSaving(true)
      const userInput = validateUser(user)
      if (!location.pathname.includes('/step-3')) {
        delete user.profile.profile_pic
      }
      if (!location.pathname.includes('/step-4')) {
        delete user.profile.resume
      }

      const savedUser: User = await api.employees.update(userInput)
      console.log('[submit] savedUser=%o', savedUser)
      api.init()
      setUser(savedUser)
      setSaving(false)
    } catch (err) {
      setSaving(false)
    }
  }, [user, location])

  const employeeSignupContextValue = useMemo(() => {
    return { user, setUser, saving, setSaving, submit }
  }, [user, setUser, saving, setSaving, submit])

  return (
    <EmployeeSignupContext.Provider value={employeeSignupContextValue}>
      <div className="ProfileTab EmployeeSignup">
        <div className="content">
          <div className="header">
            <LinkTabs>
              <LinkTab href="step-2" value="/step-2" label="Basic Info" />
              <LinkTab href="step-3" value="/step-3" label="Profile Pic" />
              <LinkTab href="step-3b" value="/step-3b" label="Trades" />
              <LinkTab href="step-4" value="/step-4" label="Change Password" />
            </LinkTabs>
          </div>
          <div className="body">
            <Routes>
              <Route path="step-2" element={<Step2 singleStepMode />} />
              <Route path="step-3" element={<Step3 singleStepMode />} />
              <Route path="step-3b" element={<Step3b singleStepMode />} />
              <Route path="step-4" element={<Step4 singleStepMode />} />
            </Routes>
          </div>
          <ProfileTabSaveButtons />
        </div>
      </div>
    </EmployeeSignupContext.Provider>
  )
}

function ProfileTabSaveButtons() {
  const { submit, saving, setSaving } = useEmployeeSignupContext()

  const save = async () => {
    setSaving(true)
    try {
      await submit()
      setSaving(false)
    } catch {
      setSaving(false)
    }
  }

  return (
    <div className="ProfileTabSaveButtons">
      <Button color="primary" variant="contained" disabled={saving} onClick={save}>
        {t('Save')}
      </Button>
    </div>
  )
}
