/* eslint-disable jsx-a11y/alt-text */
import './HomePageFooter.scss'

import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { t } from '../../i18n'
import logo from '../../assets/logo_transparent_background_blue.png'

const links = [
  ['/contact', 'Contact Us'],
  // ['/testimonials', 'Testimonials'],
  ['/careers', 'Careers']
]

export function HomePageFooter({ onGetQuoteClick }) {
  return (
    <div className="HomePageFooter">
      <div className="homepage-container">
        <div className="row-1">
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="links">
            {links.map(([to, label]) => (
              <Link key={to} to={to}>
                {t(label)}
              </Link>
            ))}
          </div>
          <div className="cta">
            <Button variant="contained" className="button" onClick={onGetQuoteClick}>
              {t('Learn More')}
            </Button>
          </div>
        </div>
        <div className="row-2">
          <PrivacyButton />
          <AccessiblityButton />
          <span>{t('Copyright') + ` ${new Date().getFullYear()} COMPAGO. ` + t('All rights reserved.')}</span>
        </div>
      </div>
    </div>
  )
}

function PrivacyButton() {
  const onClick = () => {
    //
  }

  return (
    <button className="PrivacyButton" onClick={onClick}>
      {t('Privacy')}
    </button>
  )
}

function AccessiblityButton() {
  const onClick = () => {
    //
  }

  return (
    <button className="AccessiblityButton" onClick={onClick}>
      {t('Accessiblity')}
    </button>
  )
}
