import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Container,
  Divider,
  Drawer,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import mainLogo from "../../../assets/homepage/mainLogo.png";
import { ThemeButton } from "../../common/theme/ThemeButton";
import flagUS from "../../../assets/homepage/flags/us.png";
import flagMX from "../../../assets/homepage/flags/maxico.png";
import { t } from "../../../i18n";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const drawerWidth = 240;

const GlobalHeader = ({ isHomePage, showLogOut }) => {
  const pathName = window.location.pathname;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedLang, setSelectedLang] = useState("en");

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const updateLang = (lang) => {
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    setSelectedLang(lang);
  }, []);

  const logout = async () => {
    await axios.post("/logout");
    navigate("/");
    window.location.reload();
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      className="sidebar-drawer-mobile sidebar-background"
    >
      <div className="mobile-logo">
        <img className="mainLogo" src={mainLogo} alt="" />
      </div>
      <Divider htmlColor="#fff" />
      <List className="sidebar-background">
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("HOME")}
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/")}
            />
          </ListItemButton>
        </ListItem>{" "}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("ABOUT")}
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/about")}
            />
          </ListItemButton>
        </ListItem>{" "}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("SERVICES")}
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/services")}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary={t("COMPAGO UNIVERSITY")}
              sx={{ textAlign: "center" }}
              onClick={() => navigate("/compago-university")}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <div className="buttonWrap-mobile">
        {!showLogOut ? (
          <>
            <ThemeButton color="secondary" onClick={() => navigate("/login")}>
              {t("Sign In")}
            </ThemeButton>
            <ThemeButton onClick={() => navigate("/register/step-1")}>
              {t("Find Work")}
            </ThemeButton>
          </>
        ) : (
          <Button sx={{ color: "#fff" }} onClick={logout}>
            {t("Log out")}
          </Button>
        )}
      </div>
    </Box>
  );

  return (
    <div className={`headerMainWrap ${isHomePage ? "isHomePage" : ""}`}>
      <Container maxWidth="xl" className="headerContainer">
        <img
          className="mainLogo"
          src={mainLogo}
          alt=""
          onClick={() => navigate("/")}
        />
        <div
          className={`pagesWrap hide-mobile ${
            !!showLogOut && "hide_after_login"
          }`}
        >
          <span
            className={`primaryColor ${pathName === "/" ? "active" : ""}`}
            onClick={() => navigate("/")}
          >
            {t("HOME")}
          </span>
          <span
            className={`primaryColor ${pathName === "/about" ? "active" : ""}`}
            onClick={() => navigate("/about")}
          >
            {t("ABOUT")}
          </span>
          {/* <span
            className={`primaryColor ${
              pathName === "/management" ? "active" : ""
            }`}
            onClick={() => navigate("/management")}
          >
            {t("MANAGEMENT")}
          </span> */}
          <span
            className={`primaryColor ${
              pathName === "/services" ? "active" : ""
            }`}
            onClick={() => navigate("/services")}
          >
            {t("SERVICES")}
          </span>
          <span
            className={`primaryColor ${
              pathName === "/compago-university" ? "active" : ""
            }`}
            onClick={() => navigate("/compago-university")}
          >
            {t("COMPAGO UNIVERSITY")}
          </span>
        </div>
        <div className="buttonWrap">
          <div className="buttonWrap hide-mobile">
            <img
              src={flagMX}
              alt=""
              className="flagImg"
              style={{
                opacity: selectedLang === "es" ? "" : "0.4",
              }}
              onClick={() => updateLang("es")}
            />
            <img
              src={flagUS}
              alt=""
              className="flagImg"
              style={{
                opacity: selectedLang === "en" ? "" : "0.4",
              }}
              onClick={() => updateLang("en")}
            />

            {!showLogOut ? (
              <>
                <ThemeButton
                  color="secondary"
                  onClick={() => navigate("/login")}
                >
                  {t("Sign In")}
                </ThemeButton>
                <ThemeButton onClick={() => navigate("/register/step-1")}>
                  {t("Find Work")}
                </ThemeButton>
              </>
            ) : (
              <Button sx={{ color: "#fff" }} onClick={logout}>
                {t("Log out")}
              </Button>
            )}
          </div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            className="show-mobile"
            // sx={{ mr: 2, display: { sm: "none" } }}
          >
            <img
              src={flagMX}
              alt=""
              className="flagImg"
              style={{
                opacity: selectedLang === "es" ? "" : "0.4",
              }}
              onClick={() => updateLang("es")}
            />
            <img
              src={flagUS}
              alt=""
              className="flagImg"
              style={{
                opacity: selectedLang === "en" ? "" : "0.4",
              }}
              onClick={() => updateLang("en")}
            />

            {mobileOpen ? (
              <CloseIcon htmlColor="#fff" onClick={handleDrawerToggle} />
            ) : (
              <MenuIcon htmlColor="#fff" onClick={handleDrawerToggle} />
            )}
          </IconButton>
        </div>
      </Container>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        className="show-mobile"
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </div>
  );
};

export default GlobalHeader;
