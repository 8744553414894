import axios from 'axios'
import { Button, TextField } from '@mui/material'
import { useState } from 'react'
import { Footer } from './common'
import { HomePageNav } from './homepage/HomePageNav'
import { FileUploadButton } from './common'

import './AboutMe.scss'

export function AboutMe() {
  const [state, _setState] = useState({
    name: '',
    hometown: '',
    job_title: '',
    work_location: '',
    about_me: '',
    profile_pic: '',
    saving: false,
    status: 'INITIAL',
    error: ''
  })

  const setState = partialState => _setState({ ...state, ...partialState })

  const submit = async () => {
    try {
      setState({ saving: true })
      const formData = new FormData()
      formData.append('name', state.name)
      formData.append('hometown', state.hometown)
      formData.append('job_title', state.job_title)
      formData.append('work_location', state.work_location)
      formData.append('about_me', state.about_me)
      formData.append('profile_pic', state.profile_pic)
      await axios.post('/about-me', formData)
      setTimeout(() => {
        setState({ saving: false, status: 'SUCCESS' })
      }, 2000)
    } catch (err) {
      setState({ saving: false, status: 'ERROR', error: err.message })
    }
  }

  return (
    <>
      <HomePageNav />
      <div className="AboutMe">
        <div style={{ fontSize: 22, fontWeight: 600 }}>About Me</div>
        {state.status === 'SUCCESS' ? (
          <div>Thanks!</div>
        ) : (
          <>
            <TextField label="Name" value={state.name} onChange={e => setState({ name: e.target.value })} />
            <TextField
              label="Where are you from?"
              placeholder="Example: Austin, TX"
              value={state.hometown}
              onChange={e => setState({ hometown: e.target.value })}
            />
            <TextField
              label="Job Title"
              value={state.job_title}
              onChange={e => setState({ job_title: e.target.value })}
            />
            <TextField
              label="Work Location"
              value={state.work_location}
              onChange={e => setState({ work_location: e.target.value })}
            />
            <TextField
              label="About Me"
              value={state.about_me}
              onChange={e => setState({ about_me: e.target.value })}
              minRows={5}
              multiline
            />
            <FileUploadButton
              label="Upload Profile Pic"
              value={state.profile_pic}
              onChange={e => setState({ profile_pic: e.target.files[0] })}
            />
            {state.status === 'ERROR' ? <div>{state.error}</div> : null}
            <Button
              variant="contained"
              onClick={submit}
              disabled={
                state.saving ||
                !state.name ||
                !state.hometown ||
                !state.job_title ||
                !state.work_location ||
                !state.about_me ||
                !state.profile_pic
              }
              fullWidth
            >
              Submit
            </Button>
          </>
        )}
      </div>
      <Footer />
    </>
  )
}
