import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import core1 from "../../../../assets/about_page/core1.jpeg";
import core2 from "../../../../assets/about_page/core2.jpeg";
import core3 from "../../../../assets/about_page/core3.jpeg";
import core4 from "../../../../assets/about_page/core4.jpeg";
import core5 from "../../../../assets/about_page/core5.jpeg";
import core6 from "../../../../assets/about_page/core6.jpg";

import mission from "../../../../assets/about_page/mission_border_line.png";
import { t } from "../../../../i18n";

const SliderCard = ({ title, description, cardImage }) => {
  return (
    <Card className="card-element-1">
      <div className="card-custom relative">
        <CardMedia
          component="img"
          height="470"
          image={cardImage}
          alt="green iguana"
        />
        <CardContent className="card-custom-content">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="card-title"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="card-description"
          >
            {description}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};

export default function CoreValue() {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [btn, setBtn] = useState("prev");
  const [isMobile, setIsMobile] = useState(false);
  const swipeRef = useRef();

  useEffect(() => {
    if (swipeRef.current && swipeRef.current.swiper) {
      swipeRef.current.swiper.slideTo(sliderIndex);
    }
  }, [sliderIndex]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 999);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="coreValueMainWrap">
      <Container Container maxWidth="xl" className="coreValueContainer">
        {/* <TitleBorderLine /> */}
        <img src={mission} alt="" className="mission" />
        <div className="contentWrap">
          <div className="title">{t("Our Core Values")}</div>
        </div>
        <div className="slickWrap">
          <Swiper
            ref={swipeRef}
            spaceBetween={isMobile ? 50 : 40}
            slidesPerView={isMobile ? 1 : 3}
            centeredSlides={isMobile}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide className="slide-center">
              <SliderCard
                cardImage={core1}
                title={t("Integrity")}
                description={t(
                  `We conduct our business with honesty and fairness, building trust with our clients, partners, and the communities we serve.`
                )}
              />
            </SwiperSlide>
            <SwiperSlide className="slide-center">
              <SliderCard
                cardImage={core2}
                title={t("Quality")}
                description={t(
                  `We are dedicated to delivering exceptional quality in every project, ensuring durability, safety, and aesthetic excellence.`
                )}
              />
            </SwiperSlide>
            <SwiperSlide className="slide-center">
              <SliderCard
                cardImage={core6}
                title={t("Culture")}
                description={t(
                  `We help shape the culture of every jobsite we work on. Our great attitude and attention to the little things is contagious. Happy cultures create better end products.`
                )}
              />
            </SwiperSlide>
            <SwiperSlide className="slide-center">
              <SliderCard
                cardImage={core3}
                title={t("Innovation")}
                description={t(
                  `We embrace innovation, continuously incorporating new technologies and methods to improve efficiency, sustainability, and cost-effectiveness.`
                )}
              />
            </SwiperSlide>
            <SwiperSlide className="slide-center">
              <SliderCard
                cardImage={core4}
                title={t("Community")}
                description={t(
                  `We are dedicated to delivering exceptional quality in every project, ensuring durability, safety, and aesthetic excellence.`
                )}
              />
            </SwiperSlide>
            <SwiperSlide className="slide-center">
              <SliderCard
                cardImage={core5}
                title={t("Safety")}
                description={t(
                  `The safety of our employees, contractors, and the public is paramount in everything we do. We adhere to the highest safety standards, ensuring a safe work environment on all our sites.`
                )}
              />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="sliderbtns">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setBtn("prev");
              sliderIndex !== 0 && setSliderIndex((prev) => prev - 1);
            }}
          >
            <circle
              cx="25"
              cy="25"
              r="24.5"
              fill={btn === "prev" ? "#fa6d00" : "#0E1B26"}
            />
            <path
              d="M20.4187 25C20.4187 25.3141 20.5354 25.5834 20.7866 25.8167L27.7685 32.6549C27.9659 32.8524 28.2172 32.96 28.5133 32.96C29.1056 32.96 29.5812 32.4934 29.5812 31.8921C29.5812 31.596 29.4556 31.3357 29.2582 31.1293L22.9673 25L29.2582 18.8707C29.4556 18.6643 29.5812 18.3951 29.5812 18.108C29.5812 17.5067 29.1056 17.04 28.5133 17.04C28.2172 17.04 27.9659 17.1477 27.7685 17.3452L20.7866 24.1744C20.5354 24.4167 20.4187 24.6859 20.4187 25Z"
              fill="white"
            />
          </svg>

          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setBtn("next");

              setSliderIndex((prev) => (prev == 6 ? 0 : prev + 1));
            }}
          >
            <circle
              cx="25"
              cy="25"
              r="24.5"
              transform="matrix(-1 0 0 1 50 0)"
              fill={btn === "next" ? "#fa6d00" : "#0E1B26"}
            />
            <path
              d="M28.8635 25.4595L28.8634 25.4593L28.8731 25.4503C29.0271 25.3073 29.0813 25.1689 29.0813 25C29.0813 24.8323 29.0278 24.69 28.8663 24.5343L28.8663 24.5344L28.8637 24.5319L21.8819 17.7026L21.8819 17.7026L21.878 17.6987C21.7742 17.595 21.6501 17.54 21.4867 17.54C21.1678 17.54 20.9188 17.7856 20.9188 18.108C20.9188 18.2446 20.9783 18.3921 21.0978 18.5195L27.3816 24.6419L27.7491 25L27.3816 25.3582L21.0978 31.4806C20.9766 31.6095 20.9188 31.7487 20.9188 31.8921C20.9188 32.2145 21.1678 32.46 21.4867 32.46C21.6501 32.46 21.7742 32.4051 21.878 32.3014L21.878 32.3013L21.8817 32.2977L28.8635 25.4595Z"
              fill="white"
              stroke="white"
            />
          </svg>
        </div>
      </Container>
    </div>
  );
}
