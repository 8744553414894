import React from "react";
import AboutCompago from "./AboutCompago";
import Approach from "./Approach";
import CoreValue from "./CoreValue";
import JobSite from "./JobSite";
import JoinUs from "./JoinUs";
import OurMission from "./OurMission";

const About = () => {
  return (
    <>
      <AboutCompago />
      <OurMission />
      <CoreValue />
      <Approach />
      <JobSite />
      {/* <OurImpact /> */}
      <JoinUs />
      {/* <ContactUs /> */}
    </>
  );
};

export default About;
