import { Resource } from './base'

export class OrgResource extends Resource {
  constructor() {
    super('orgs')
    this.sendInvites = this._createEndpoint({ endpointName: 'send_invites', httpVerb: 'post', detail: true })
  }
}

export const orgs = new OrgResource()
