import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
// import { HowItWorksLine } from "../../../SVG/Borders";
// import bgImg from "../../../assets/homepage/scalableBG.png";
// import img1 from "../../../assets/homepage/solutions/img1.jpg";
// import img2 from "../../../assets/homepage/solutions/img2.png";
// import img3 from "../../../assets/homepage/solutions/img3.jpg";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import jobsite1 from "../../../../assets/about_page/jobsite-1.png";
import jobsite2 from "../../../../assets/about_page/jobsite2.png";
import mission from "../../../../assets/about_page/mission_border_line.png";
import vector1 from "../../../../assets/homepage/vector1.png";
import { t } from "../../../../i18n";

export const SimpleCard2 = ({ text, image }) => {
  return (
    <>
      <Card className="simple-card-job">
        <div className="simple-card-media-job">
          <CardMedia
            component="img"
            height="150"
            image={image}
            alt="green iguana"
            className="simple-card-img-job"
          />
        </div>
        <CardContent className="simple-card-content-job">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="simple-card-title-job"
          >
            {text}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

const JobSite = () => {
  return (
    <div
      className="jobsiteMainWrap"
      // style={{ backgroundImage: `url(${bgImg})` }}
    >
      <Container maxWidth="xl" className="jobContainer">
        <div className="contentWrap">
          <img src={mission} alt="" className="mission" />
          <div className="title">{t("Beyond The Jobsite")}</div>
          <div className="description">
            {t("How Compago's work helps to build communities")}
          </div>
        </div>
        <div className="job-card-1">
          <div className="job-card-left">
            <h3>{t("Compago Works to Help Communities")}</h3>
          </div>
          <div className="job-card-right">
            <img src={jobsite1} alt="" />
          </div>
        </div>
        <div className="job-card-2">
          <div className="job-card-left">
            <h3>{t("Compago Gives Back")}</h3>
          </div>
          <div className="job-card-right">
            <img src={jobsite2} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default JobSite;
