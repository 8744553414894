import React from "react";
import FooterNew from "../common/FooterNew";
import CompagoUniversitySection from "./CompagoUniversitySection";
import HowItWorks from "./HowItWorks";
import HowItWorksNew from "./HowItWorksNew";
import Professionals from "./Professionals";
import SuccessRate from "./SuccessRate";
import EmbraceSection from "./embraceSection";
import Scalable from "./scalable";

const Home = () => {
  return (
    <div className="mainWrap">
      <EmbraceSection />
      <Professionals />
      <CompagoUniversitySection />
      <HowItWorksNew />
      <SuccessRate />
      <HowItWorks />
      {/* <AboutUs /> */}
      <Scalable />
      <FooterNew />
    </div>
  );
};

export default Home;
