import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, {useState} from "react";
import { t } from "../../../i18n";
import ButtonCustom from "../../common/Button";
import new1 from "../../../assets/homepage/new1.png";
import new2 from "../../../assets/homepage/new2.png";
import new3 from "../../../assets/homepage/new3.png";
import vector1 from "../../../assets/about_page/mission_border_line.png";
import { useNavigate } from "react-router-dom";
import { GetQuote } from "../../homepage/GetQuote";
import BuildTeamButton from "../../common/Button/BuildTeamButton";
import FindWorkButton from "../../common/Button/FindWorkButton";

export const SimpleCard3 = ({ text, image, cardText }) => {
  return (
    <>
      <Card className="simple-card-work">
        <div className="simple-card-media-work">
          <CardMedia
            component="img"
            height="150"
            image={image}
            alt="green iguana"
            className="simple-card-img-work"
          />
        </div>
        <CardContent className="simple-card-content-work">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="simple-card-title-work"
          >
            {text}
          </Typography>
          <p className="card-text">{cardText}</p>
        </CardContent>
      </Card>
    </>
  );
};

const HowItWorksNew = () => {
  const navigate = useNavigate();
  const [showGetQuote, toggleGetQuote] = useState(false);
  const onGetQuoteClick = () => toggleGetQuote(true);
  
  return (
    <div className="howItWorksNew">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="how-it-work-heading-new">
              <img src={vector1} alt="" className="mission" />
              <Typography variant="h2" gutterBottom>
                {t("How It Works")}
              </Typography>
              <div className="how-it-works-new-sub-heading">
                <Typography variant="subtitle1" className="how-work-subtitle1">
                  {t(
                    `No need for interviews and tons of paperwork. Just let us know who you need, when and where and we will be there`
                  )}
                </Typography>

                <Typography variant="subtitle1" className="how-work-subtitle2">
                  {t(
                    `Our workers are seasoned pros ready to start immediately. Our team specializes in `
                  )}
                  <span className="how-it-works-new-bold">
                    {t(
                      `Residential & Commercial Construction, Work Camps, Oil Fields, and Disaster Relief`
                    )}
                  </span>
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
        {/* <div className="blue-highlight"></div> */}
        <div className="card-wrapper-work">
          <SimpleCard3
            image={new1}
            text={t("Describe the Team You Need")}
            cardText={"01"}
          />
          <SimpleCard3
            image={new2}
            text={t("We Deliver On Your Timeframe")}
            cardText={"02"}
          />
          <SimpleCard3
            image={new3}
            text={t("Add & Remove Talent As Needed Until The Job's Done")}
            cardText={"03"}
          />
        </div>
        <div className="how-it-works-new-btns">
          <BuildTeamButton />
          <FindWorkButton/>
        </div>
      </Container>
      {showGetQuote ? <GetQuote onClose={() => toggleGetQuote(false)} /> : null}
    </div>
  );
};

export default HowItWorksNew;
