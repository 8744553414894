// @flow
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { t } from '../../i18n'

const div = document.createElement('div')
document.body.appendChild(div)

type ConfirmOptions = {
  component: React.Component,
  title: String,
  body: String,
  cancelText: String,
  confirmText: String
}

export function confirm(options?: ConfirmOptions) {
  const defaultOptions = {
    title: t('Confirm'),
    body: t('Are you sure?'),
    cancelText: t('Cancel'),
    confirmText: t('Confirm')
  }

  let Component
  if (options) {
    Component = options.component || ConfirmDialog
    options = { ...defaultOptions, ...options }
  } else {
    Component = ConfirmDialog
    options = defaultOptions
  }

  return new Promise(resolve => {
    const done = value => {
      resolve(value)
      unmountComponentAtNode(div)
    }

    render(<Component {...options} onConfirmClick={() => done(true)} onCancelClick={() => done(false)} />, div)
  })
}

function ConfirmDialog({ title, body, cancelText, confirmText, size, onConfirmClick, onCancelClick }) {
  const buttons = (
    <>
      <Button onClick={onCancelClick}>{cancelText}</Button>
      <Button onClick={onConfirmClick}>{confirmText}</Button>
    </>
  )

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ minWidth: 250 }}>{body}</DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  )
}
