import './Note.scss'

import dayjs from 'dayjs'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { t } from '../../i18n'
import { api } from '../../api'
import { useForceUpdate } from '../../hooks'

export function Note({ note }) {
  const forceUpdate = useForceUpdate()
  const onCheckboxChange = async () => {
    const savedNote = await api.notes.toggleVisibility(note)
    note.is_private = savedNote.is_private
    forceUpdate()
  }

  return (
    <div className="Note">
      <div className="header">
        <div className="left">
          <div>
            {dayjs(note.created_at).fromNow()} by {note.author_name}
          </div>
        </div>
        <div className="right">
          {note.author === api.user.id ? (
            <FormGroup>
              <FormControlLabel
                label={t('Private')}
                control={<Checkbox size="small" checked={note.is_private} onChange={onCheckboxChange} />}
              />
            </FormGroup>
          ) : null}
        </div>
      </div>
      <div className="text" style={{ padding: '7px 0' }}>
        {note.text}
      </div>
    </div>
  )
}
