import { Resource } from './base'

export class MatchResource extends Resource {
  constructor() {
    super('matches')
    this.bulkCreate = this._createEndpoint({ endpointName: 'bulk_create' })
  }
}

export const matches = new MatchResource()
