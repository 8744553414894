import querystring from 'querystring'
import axios from 'axios'
import { toast, toastWarn } from '../events'

type ResourceOptions = {
  wrap: Boolean
}

type CreateEndpointOptions = {
  endpointName?: String,
  httpVerb?: 'get' | 'post' | 'put' | 'delete',
  detail?: Boolean,
  wrap?: Boolean,
  returnType?: 'data' | 'response'
}

export class Resource {
  constructor(name = '', options: ResourceOptions = {}) {
    const { wrap = true } = options
    this.name = name
    if (!this.list) this.list = this._createEndpoint({ httpVerb: 'get', detail: false, wrap })
    if (!this.get) this.get = this._createEndpoint({ httpVerb: 'get', detail: true, wrap })
    if (!this.create) this.create = this._createEndpoint({ httpVerb: 'post', detail: false, wrap })
    if (!this.update) this.update = this._createEndpoint({ httpVerb: 'patch', detail: true, wrap })
    if (!this.delete) this.delete = this._createEndpoint({ httpVerb: 'delete', detail: true, wrap })
  }

  async save(data) {
    return data.id ? this.update(data) : this.create(data)
  }

  async _wrapWithToastCalls(fn, preText = 'Saving...', postText = 'Saved') {
    try {
      toast(preText)
      const rv = await fn()
      toast(postText)
      return rv
    } catch (err) {
      this._handleError(err)
      throw err
    }
  }

  _createEndpoint(options: CreateEndpointOptions = {}) {
    let { endpointName, httpVerb = 'post', detail = false, wrap = true, returnType = 'data' } = options
    if (httpVerb === 'get') {
      wrap = false
    }
    let parts = ['', this.name]
    if (detail) parts.push(':id')
    if (endpointName) parts.push(endpointName)
    const urlTemplate = parts.join('/')

    function endpoint(data) {
      let url
      let axiosArgs
      if (httpVerb === 'get') {
        if (detail) {
          url = urlTemplate.replace(':id', data)
        } else {
          let params = ''
          if (typeof data === 'object') {
            params = '?' + querystring.stringify(data)
          }
          url = urlTemplate + params
        }
        axiosArgs = [url]
      } else {
        url = detail ? urlTemplate.replace(':id', data.id) : urlTemplate
        axiosArgs = [url, data]
      }

      const fn = async () => {
        const res = await axios[httpVerb](...axiosArgs)
        if (returnType === 'response') {
          return res
        }
        if (res.config.url === '/labels/es') {
          // hack for now, need to adjust the api side 
          return res.data
        } else if (httpVerb === 'get') {
          return res.data.results || res.data
        }
        return res.data
      }
      return wrap ? this._wrapWithToastCalls(fn) : fn()
    }

    return endpoint.bind(this)
  }

  _handleError(err) {
    const res = err?.response
    let msg = ''
    if (res?.data) {
      if (Array.isArray(res.data)) {
        msg = res.data.join('\n')
      } else if (typeof res.data === 'string') {
        msg = res.data
      } else if (typeof res.data === 'object') {
        let entries = []
        for (const [k, v] of Object.entries(res.data)) {
          entries.push(`${k}: ${v}`)
        }
        msg = entries.join('\n')
      } else {
        msg = 'An error occurred'
      }
    } else if (err?.message) {
      msg = err.message
    }
    toastWarn(msg)
  }
}
