import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import arrow from "../../../../assets/about_page/arrow.svg";
import mission from "../../../../assets/about_page/mission_border_line.png";
import vector1 from "../../../../assets/homepage/vector1.png";
import { t } from "../../../../i18n";

export default function Approach() {
  return (
    <div className="approachMainWrap">
      <Container maxWidth="xl">
        <Grid container alignItems={"center"}>
          <Grid item xs={12} md={8} lg={8} sm={8}>
            <img src={mission} alt="" />
            <div className="approach_content">
              <Typography variant="h1">{t("Our Approach")}</Typography>
              <p>
                {t(
                  `At Compago, we understand that every project is unique. Our approach is to work closely with clients, understanding their vision and needs, to deliver tailored solutions that meet their objectives. From the initial design to the final construction phase, we ensure transparency, communication, and excellence every step of the way.`
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4} sm={4} className="approach_img">
            <img src={arrow} alt="" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
