import { Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import vector1 from "../../../assets/homepage/vector1.png";
import vector2 from "../../../assets/homepage/vector2.png";
import { t } from "../../../i18n";
import ButtonCustom from "../Button";
import mainLogo from "./../../../assets/homepage/logo.png";
import BuildTeamButton from "../Button/BuildTeamButton";
import FindWorkButton from "../Button/FindWorkButton";

const FooterNew = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer">
        <Container maxWidth="xl">
          <Grid container spacing={2} className="gridContainer">
            <Grid md={12} item>
              <div className="mobile-logo text-center">
                <img className="mainLogo" src={mainLogo} alt="" />
              </div>
            </Grid>
            <Grid md={12} item>


              <div className="footer-menu">
                <ul>
                  <li onClick={() => navigate("/contact")}>
                    <a href="#">{t("Contact Us")}</a>
                    <BuildTeamButton />
                  </li>
                  <li onClick={() => navigate("/register/step-1")}>
                    <a href="#">{t("Career")}</a>
                    <FindWorkButton />
                  </li>
                </ul>


              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="footer-bottom">
        <Grid container spacing={2}>
          <Grid md={12} className="footer-menu-container">
            <div className="footer-menu">
              <ul>
                <li onClick={() => navigate("/privacy")}>
                  {t("Privacy Policy")}
                </li>
                <li className="link-text">
                  {t('Copyright') + ` ${new Date().getFullYear()} Compago.`}
                  &nbsp;&nbsp;
                  {t('All rights reserved.')}
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default FooterNew;
