import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { t } from '../../../i18n'

export function Done() {
  const navigate = useNavigate()
  const done = () => {
    navigate({
      pathname: '/'
    })
  }

  return (
    <Dialog
      fullWidth
      open={true}
      className="Done"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Sign Up')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color="text.secondary" align='center'>
              {t('Thank you for applying!')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="text.secondary" align='center'>
              {t('We are evaluating your profile and will be in touch with you soon.')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={4} />
          <Grid item xs={4} className="spread-buttons">
            <Button fullWidth size="large" color="primary" variant="contained" onClick={done}>
              {t('Close')}
            </Button>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
