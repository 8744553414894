import { Footer } from './common/Footer'
import { HomePageNav } from './homepage/HomePageNav'
import { Box, Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, toastWarn } from '../events'

export function AcceptInvitation() {
  const [pass, setPass] = useState('')
  const [pass2, setPass2] = useState('')
  const [token, setToken] = useState(null)
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search)

      if (!params.has('t')) {
        throw new Error('This link is invalid')
      }

      const token = JSON.parse(atob(params.get('t')))
      if (!(token.secret && token.email)) {
        throw new Error('This link is invalid')
      }
      setToken(token)
    } catch (err) {
      setError(err.message)
    }
  }, [])

  const save = async () => {
    try {
      setSaving(true)
      const res = await axios.post('/accept-invitation', { email: token.email, password: pass, secret: token.secret })
      setSaving(false)
      console.log(res)
      toast('Saved.')
      window.location = '/'
    } catch (err) {
      setSaving(false)
      toastWarn(err.message)
    }
  }

  const valid = pass === pass2 && pass.length >= 8

  return (
    <>
      <HomePageNav />
      <div className="AcceptInvitation">
        <Box
          sx={{ margin: '50px auto', padding: '30px', width: '400px', border: '1px solid #eee', borderRadius: '5px' }}
        >
          <div style={{ fontSize: 20, fontWeight: 600, paddingBottom: 30 }}>Accept Invitation</div>
          {error || (
            <>
              <TextField label="Email" value={token?.email} fullWidth disabled />
              <TextField
                type="password"
                label="Choose a password"
                helperText="8 characters minimum"
                value={pass}
                onChange={e => setPass(e.target.value)}
                sx={{ mt: 2 }}
                fullWidth
              />
              <TextField
                type="password"
                label="Confirm password"
                value={pass2}
                onChange={e => setPass2(e.target.value)}
                sx={{ mt: 2 }}
                fullWidth
              />
              <Box sx={{ pt: 2 }}>
                <Button variant="contained" onClick={save} disabled={saving || !valid} fullWidth>
                  Submit
                </Button>
              </Box>
            </>
          )}
        </Box>
      </div>
      <Footer />
    </>
  )
}
