import React, { Fragment } from 'react'
import "./style.css"
import {
    Dialog,
} from '@mui/material'

type Props = {
    open: Boolean,
    onClose: Function
}

const DocumentRender = (props: Props) => {
    const { open, onClose } = props
    // const htmlContent = ` <div className="pdf-box">
    //                 <div className="header-wrp">
    //                     <table style={{ width: "100%" }}>
    //                         <tbody>
    //                             <tr>
    //                                 <td>
    //                                     <div className="brand-logo">
    //                                         <img src="./assets/image/brand.png" alt="logo" />
    //                                     </div>
    //                                 </td>
    //                                 <td>
    //                                     <div className="text-box">
    //                                         <p>Phone : 123 123 4567</p>
    //                                         <p>Email : info@compago.com</p>
    //                                         <p>compagosolutions.com</p>
    //                                     </div>
    //                                 </td>
    //                             </tr>
    //                         </tbody>
    //                     </table>
    //                 </div>
    //                 <table style={{ borderCollapse: "collapse", width: "100%" }}>
    //                     <tbody>
    //                         <tr>
    //                             <td className="border-top"></td>
    //                         </tr>
    //                         <tr>
    //                             <td className="border"></td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //                 <table style={{ width: "100%", marginTop: "20px" }}>
    //                     <tbody>
    //                         <tr>
    //                             <td style={{ width: "30%", margin: "0 auto" }}>
    //                                 <div className="profile-image">
    //                                     <img src="./assets/image/profile01.webp" alt="" />
    //                                 </div>
    //                             </td>
    //                             <td style={{ width: "70%" }}>
    //                                 <div className="profile-text">
    //                                     <h5>John L.Doe</h5>
    //                                     <p>It is a long established fact that a reader will be distracted by the readable content of
    //                                         a page when looking at its layout. The point of using Lorem Ipsum is that it has a
    //                                         more-or-less normal distribution of letters, as opposed to using 'Content here, content
    //                                         here', making it look like readable English.</p>
    //                                 </div>
    //                             </td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //                 <div className="pdf-image-box">
    //                     <table style={{ width: "100%", marginTop: "50px" }}>
    //                         <tbody>
    //                             <tr>
    //                                 <td className="btn">PRIMARY-TRADE</td>
    //                                 <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
    //                             </tr>
    //                             <tr style={{ height: "30px" }}></tr>
    //                             <tr>
    //                                 <td className="btn">APPLYING FOR</td>
    //                                 <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
    //                             </tr>
    //                             <tr style={{ height: "30px" }}></tr>
    //                             <tr>
    //                                 <td className="btn">YEARS OF EXPERIENCE</td>
    //                                 <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
    //                             </tr>
    //                             <tr style={{ height: "30px" }}></tr>
    //                             <tr>
    //                                 <td className="btn">TRAVEL AVAILABILITY</td>
    //                                 <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
    //                             </tr>
    //                             <tr style={{ height: "30px" }}></tr>
    //                             <tr>
    //                                 <td className="btn">LANGUEAGE</td>
    //                                 <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
    //                             </tr>
    //                             <tr style={{ height: "30px" }}></tr>
    //                             <tr>
    //                                 <td className="btn">CURRENT ROLE</td>
    //                                 <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
    //                             </tr>
    //                         </tbody>
    //                     </table>
    //                 </div>
    //                 <table style={{ width: "100%" }}>
    //                     <tbody>
    //                         <tr>
    //                             <td>
    //                                 <div className="box-image">
    //                                     <p>A NEW WAY TO WORK</p>
    //                                 </div>
    //                             </td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             </div>`
    // const pdf = new jsPDF();
    // html2canvas(htmlContent).then(canvas => {
    //     const imgData = canvas.toDataURL('image/png');
    //     pdf.addImage(imgData, 'PNG', 0, 0);
    //     pdf.save('document.pdf');
    // });


    return (
        <Fragment>
            <Dialog open={open} onClose={onClose}>
                <div className="pdf-box">
                    <div className="header-wrp">
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="brand-logo">
                                            <img src="./assets/image/brand.png" alt="logo" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-box">
                                            <p>Phone : 123 123 4567</p>
                                            <p>Email : info@compago.com</p>
                                            <p>compagosolutions.com</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="border-top"></td>
                            </tr>
                            <tr>
                                <td className="border"></td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{ width: "100%", marginTop: "20px" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "30%", margin: "0 auto" }}>
                                    <div className="profile-image">
                                        <img src="./assets/image/profile01.webp" alt="" />
                                    </div>
                                </td>
                                <td style={{ width: "70%" }}>
                                    <div className="profile-text">
                                        <h5>John L.Doe</h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable content of
                                            a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                            more-or-less normal distribution of letters, as opposed to using 'Content here, content
                                            here', making it look like readable English.</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="pdf-image-box">
                        <table style={{ width: "100%", marginTop: "50px" }}>
                            <tbody>
                                <tr>
                                    <td className="btn">PRIMARY-TRADE</td>
                                    <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
                                </tr>
                                <tr style={{ height: "30px" }}></tr>
                                <tr>
                                    <td className="btn">APPLYING FOR</td>
                                    <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
                                </tr>
                                <tr style={{ height: "30px" }}></tr>
                                <tr>
                                    <td className="btn">YEARS OF EXPERIENCE</td>
                                    <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
                                </tr>
                                <tr style={{ height: "30px" }}></tr>
                                <tr>
                                    <td className="btn">TRAVEL AVAILABILITY</td>
                                    <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
                                </tr>
                                <tr style={{ height: "30px" }}></tr>
                                <tr>
                                    <td className="btn">LANGUEAGE</td>
                                    <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
                                </tr>
                                <tr style={{ height: "30px" }}></tr>
                                <tr>
                                    <td className="btn">CURRENT ROLE</td>
                                    <td style={{ textAlign: "center" }}>Lorem Ipsum</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="box-image">
                                        <p>A NEW WAY TO WORK</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Dialog>
        </Fragment>
    )
}


export default DocumentRender