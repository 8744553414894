import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { api } from '../api'
import { LOADING } from '../constants'

const INITIAL_STATE = {}

export function useQuery(resourceName: String, methodName: String, params: any) {
  const [state, setState] = useState({ data: LOADING, loading: false })
  const lastArgs = useRef(INITIAL_STATE)

  const fetch = useCallback(
    (force = false) => {
      if (
        force ||
        lastArgs.current === INITIAL_STATE ||
        !_.isEqual({ resourceName, methodName, params }, lastArgs.current)
      ) {
        setState(state => ({ ...state, loading: true }))
        const fn = async () => {
          try {
            const data = await api[resourceName][methodName](params)
            setState(state => ({ ...state, data, loading: false }))
          } catch {
            setState(state => ({ ...state, loading: false }))
          }
        }
        fn()
      }
      lastArgs.current = { resourceName, methodName, params }
    },
    [resourceName, methodName, params]
  )

  let result = useMemo(() => {
    return {
      data: state.data,
      loading: state.loading
    }
  }, [state])

  const refetch = useCallback(() => {
    fetch(true)
  }, [fetch])

  result.refetch = refetch

  useEffect(() => {
    fetch()
  }, [fetch])

  return result
}

export function useListQuery(resourceName: String, params: any) {
  return useQuery(resourceName, 'list', params)
}

export const useUserListQuery = (params: any) => useListQuery('users', params)
export const useMatchListQuery = (params: any) => useListQuery('matches', params)
export const useOrgListQuery = () => useListQuery('orgs')
export const useTestimonialListQuery = () => useListQuery('testimonials')
export const useClientListQuery = () => useListQuery('clients')
export const useTradeListQuery = () => useListQuery('trades')

export function useDetailQuery(resourceName: String, id: Number) {
  return useQuery(resourceName, 'get', id)
}

export const useUserDetailQuery = (id: Number) => useDetailQuery('users', id)
export const useOrgDetailQuery = (id: Number) => useDetailQuery('orgs', id)
