import './EmployerProfile.scss'

import { Button, TextField } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { t } from '../../../i18n'
import { UserContext } from '../../../context'
import { Logger } from '../../../logging'
import fields from './profileFields.json'
import { DataForm } from '../../common/DataForm'
import { api } from '../../../api'

const { log } = new Logger('app:ui:EmployerProfile')

export function EmployerProfile() {
  const [contextUser, setContextUser] = useContext(UserContext)

  // const [formFields, setFormFields] = useState([])
  const [user, setUser] = useState({ first_name: '', last_name: '', email: '', password: '' })

  useEffect(() => {
    // api.settings.get('employer_profile_form?type=json').then(value => setFormFields(value))
  }, [])

  useEffect(() => {
    setUser(_.cloneDeep(contextUser))
  }, [contextUser])

  const onUserChange = (prop, value) => {
    setUser({ ...user, [prop]: value })
  }

  const onSaveUserClick = async () => {
    log('[submit] updating user with email=%s', user.email)
    const savedUser = await api.employers.update({
      id: user.id,
      first_name: user.first_name.trim(),
      last_name: user.last_name.trim(),
      email: user.email.trim()
    })
    log('[submit] updated user %s', savedUser.id)
    setContextUser(savedUser)
  }

  const onSaveProfileClick = async (form: HTMLFormElement) => {
    var data = {}
    for (const el of form.elements) {
      data[el.name] = ['checkbox', 'radio'].includes(el.type) ? el.checked : el.value
    }
    log('[submit] updating profile %O', data)
  }

  const onSavePasswordClick = async () => {
    log('[submit] changing password')
    await api.me.changePassword(user.password)
    log('[submit] changed password')
    window.location.reload()
  }

  return (
    <div className="EmployerProfile">
      <div className="info profile-info">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Profile Info</div>
        <div style={{ paddingTop: 20, paddingBottom: 10, fontWeight: 600 }}>I'm looking for someone:</div>
        <DataForm fields={fields} onSubmit={onSaveProfileClick} />
      </div>
      <div className="info user-info">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Account Info</div>
        <TextField
          label={t('First name')}
          value={user.first_name || ''}
          onChange={e => onUserChange('first_name', e.target.value)}
        />
        <TextField
          label={t('Last name')}
          value={user.last_name || ''}
          onChange={e => onUserChange('last_name', e.target.value)}
        />
        <TextField label={t('Email')} value={user.email || ''} onChange={e => onUserChange('email', e.target.value)} />
        <Button
          variant="contained"
          onClick={onSaveUserClick}
          disabled={!(!!user.first_name.trim() && !!user.last_name.trim() && !!user.email.trim())}
          fullWidth
        >
          Save
        </Button>
      </div>
      <div className="info password-info">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Change Password</div>
        <TextField
          type="password"
          label={t('Password')}
          value={user.password || ''}
          onChange={e => onUserChange('password', e.target.value)}
          helperText={t('(6 characters minimum)')}
        />
        <TextField
          type="password"
          label={t('Confirm password')}
          value={user.password2 || ''}
          onChange={e => onUserChange('password2', e.target.value)}
        />
        <Button
          variant="contained"
          onClick={onSavePasswordClick}
          disabled={!(user.password?.length >= 6 && user.password === user.password2)}
          fullWidth
        >
          Save
        </Button>
      </div>
    </div>
  )
}
