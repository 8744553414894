import { Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { t } from "../../../../i18n";
import ButtonCustom from "../../../common/Button";
import { GetQuote } from "../../../homepage/GetQuote";
import { useNavigate } from "react-router-dom";
import BuildTeamButton from "../../../common/Button/BuildTeamButton";
import FindWorkButton from "../../../common/Button/FindWorkButton";

export default function AboutCompago() {
  const navigate = useNavigate();
  const [showGetQuote, toggleGetQuote] = useState(false);
  const onGetQuoteClick = () => toggleGetQuote(true);
  return (
    <div className="about-compago" style={{ position: "relative" }}>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          className="about_row"
        >
          <Grid item md={8} xs={12} lg={8} sm={12} className="about_content">
            <Typography variant="h1">
              {t("Who We Are")}
              <span className="bannerHeading">{t("What We Stand For")}</span>
              {t("More Than Just a")}
              <br />
              {t("Service Provider")}
            </Typography>
            <p>
              {t(
                `Compago specializes in supplying on-demand labor, master level tradesmen and managing construction projects across various industries. The culture we create through vetting and educating our team exceeds client expectations. We strive to provide the best possible candidates for every project.`
              )}
            </p>

            <div className="how-it-works-new-btns">
              <BuildTeamButton />
              <FindWorkButton />
            </div>
          </Grid>
          <Grid item md={4} xs={12} lg={4} sm={5}></Grid>
          {/* <div className="experties_us_bg">
            <img src={experties_right} alt="" />
          </div> */}
        </Grid>
      </Container>
      {showGetQuote ? <GetQuote onClose={() => toggleGetQuote(false)} /> : null}
    </div>
  );
}
