import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import vector1 from "../../../assets/about_page/mission_border_line.png";
import ImageOne from "../../../assets/homepage/image-1.jpg";
import ImageTwo from "../../../assets/homepage/image-2.JPG";
import imageThree from "../../../assets/homepage/image-3.jpg";
import { t } from "../../../i18n";
import ButtonCustom from "../../common/Button";
import SimpleCard from "../../common/CompagoCard/SimpleCard";
import { GetQuote } from "../../homepage/GetQuote";
import BuildTeamButton from "../../common/Button/BuildTeamButton";
import FindWorkButton from "../../common/Button/FindWorkButton";

const HowItWorks = () => {
  const navigate = useNavigate();
  const [showGetQuote, toggleGetQuote] = useState(false);
  const onGetQuoteClick = () => toggleGetQuote(true);
  return (
    <div className="how-it-work">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className="how-it-work-heading">
              <img src={vector1} alt="" className="how-work-vector" />
              <Typography variant="h2" gutterBottom>
                {t("Ready to get started?")}
              </Typography>
              <div className="how-it-work-sub-heading">
                <ul>
                  <li>
                    <span>
                      <Typography variant="subtitle1">
                        {t(
                          "Looking for more team members, skilled tradesmen or custom building solutions? Click Build Your Team."
                        )}
                      </Typography>
                    </span>
                  </li>
                  <li>
                    <span>
                      <Typography variant="subtitle1">
                        {t(
                          `Are you a hard worker looking for new opportunities? Click Find Work.`
                        )}
                      </Typography>
                    </span>
                  </li>
                </ul>
              </div>
            </Box>
            <div className="how-it-works-new-btns">
              <BuildTeamButton />
              <FindWorkButton/>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="relative">
            <div className="lightHighlight"></div>
            <div className="card-wrapper">
              <SimpleCard
                text={t("Residential & Commercial Construction")}
                image={ImageOne}
              />
            </div>
            <div className="card-wrapper">
              <SimpleCard text={t("Disaster Relief")} image={ImageTwo} />
              <SimpleCard
                text={t("Work Camps & Oil Field")}
                image={imageThree}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
      {showGetQuote ? <GetQuote onClose={() => toggleGetQuote(false)} /> : null}
    </div>
  );
};

export default HowItWorks;
