import './Testimonials.scss'

import { Avatar, Button, Card, CardContent, CircularProgress, Typography } from '@mui/material'
import { useTestimonialListQuery } from '../hooks'
import { LOADING, STATIC3_URL } from '../constants'
import { t } from '../i18n'
import { Carousel } from './common/Carousel'
import { useState } from 'react'

export function Testimonials() {
  const { data } = useTestimonialListQuery()

  if (data === LOADING) return <CircularProgress />

  return (
    <div className="Testimonials">
      <h2>{t('Testimonials')}</h2>
      <div className="testimonials">
        <Carousel>
          {data.map(t => (
            <TestimonialCard key={t.id} testimonial={t} />
          ))}
        </Carousel>
      </div>
    </div>
  )
}

function TestimonialCard({ testimonial }) {
  const [show, setShow] = useState(false)

  if (!testimonial) return null

  const avatarProps = {
    sx: { width: 80, height: 80 }
  }
  if (testimonial.image_url) {
    avatarProps.src = STATIC3_URL + testimonial.image_url
  } else {
    avatarProps.children = testimonial.title
      .split(/\s+/)
      .map(str => str[0])
      .join('')
  }

  return (
    <div className="TestimonialCard">
      <Avatar {...avatarProps} />
      <Card key={testimonial.id}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'left', marginTop: 5 }}>
            {testimonial.title}
          </Typography>
          <FiveStars />
          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left', marginTop: 2 }}>
            {testimonial.body.length > 200 && !show ? (
              <>
                {testimonial.body.substring(0, 200) + '...'}
                <Button sx={{ padding: 0, marginLeft: 1 }} onClick={() => setShow(true)}>
                  Show more
                </Button>
              </>
            ) : (
              testimonial.body
            )}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

function FiveStars() {
  const star = (
    <svg height="16" width="16" role="img" aria-label="star">
      <path
        d="M8 .391l2.446 5.045 5.555 0.767-4.043 3.885 0.987 5.521-4.944-2.644-4.945 2.462 0.987-5.519-4.043-3.885 5.555-0.526z"
        fill="#FFDC00"
      ></path>
    </svg>
  )

  return (
    <div className="FiveStars">
      {star}
      {star}
      {star}
      {star}
      {star}
    </div>
  )
}
