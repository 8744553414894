import { Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import mission from "../../../../assets/about_page/mission_border_line.png";
import { t } from "../../../../i18n";
import ButtonCustom from "../../../common/Button";
import { GetQuote } from "../../../homepage/GetQuote";
import BuildTeamButton from "../../../common/Button/BuildTeamButton";
import FindWorkButton from "../../../common/Button/FindWorkButton";

export default function JoinUs() {
  const navigate = useNavigate();
  const [showGetQuote, toggleGetQuote] = useState(false);
  const onGetQuoteClick = () => toggleGetQuote(true);

  return (
    <div className="joinUsWrap">
      <Container maxWidth="xl">
        <div container justifyContent={"center"} alignItems={"center"}>
          <img src={mission} alt="" className="mission" />
          <Grid item xs={12} md={8} lg={8} className="joinUs_content">
            <Typography variant="h1">{t(`Join Us`)}</Typography>
            <p>
              {t(
                `As we continue to grow and take on new challenges, we are always looking for talented individuals to join our team. If you share our passion for building a better future, we would love to hear from you.`
              )}
            </p>
          </Grid>
          {/* <Grid item xs={12} md={4} lg={4} className="joinUs_content">
            <div className="how-it-works-new-btns">
              <BuildTeamButton />
              <FindWorkButton />
            </div>
          </Grid> */}
        </div>
      </Container>
      {showGetQuote ? <GetQuote onClose={() => toggleGetQuote(false)} /> : null}
    </div>
  );
}
