import './EmployerSignup.scss'

import { useState } from 'react'
import { Button, TextField } from '@mui/material'
import { Logger } from '../../logging'
import { t } from '../../i18n'
import { isValidEmail } from '../../util/stringUtil'
import { api } from '../../api'
import GlobalHeader from '../common/HeaderNew'
import FooterNew from '../common/FooterNew'

const { log } = new Logger('app:ui:EmployerSignup')

export function EmployerSignup() {
  const [saving, setSaving] = useState(false)
  const [success, setSuccess] = useState(false)
  const [user, setUser] = useState({
    company: '',
    first_name: '',
    last_name: '',
    email: '',
    // password: '',
    // password2: ''
    signup_notes: ''
  })

  const onChange = (prop, value) => {
    setUser({ ...user, [prop]: value })
  }

  const submit = async () => {
    setSaving(true)
    log('[submit] creating user with email=%s', user.email)
    try {
      const savedUser = await api.employers.create({
        company: user.company.trim(),
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
        email: user.email.trim(),
        // password: user.password
        signup_notes: user.signup_notes.trim()
      })
      log('[submit] created user %s', savedUser.id)
      setSaving(false)
      setSuccess(true)
    } catch (err) {
      setSaving(false)
    }
  }

  return (
    <>
      <GlobalHeader />
      <div className="EmployerSignup">
        <div className="container">
          <h1>Ready to find great candidates? Sign up now.</h1>
          {success ? (
            <Thanks />
          ) : (
            <div className="form">
              <TextField
                label={t('Company')}
                value={user.company}
                onChange={e => onChange('company', e.target.value)}
              />
              <TextField
                label={t('First name')}
                value={user.first_name}
                onChange={e => onChange('first_name', e.target.value)}
              />
              <TextField
                label={t('Last name')}
                value={user.last_name}
                onChange={e => onChange('last_name', e.target.value)}
              />
              <TextField label={t('Email')} value={user.email} onChange={e => onChange('email', e.target.value)} />
              {/* <TextField
              type="password"
              label={t('Password')}
              value={user.password}
              onChange={e => onChange('password', e.target.value)}
              helperText={t('(6 characters minimum)')}
            />
            <TextField
              type="password"
              label={t('Confirm password')}
              value={user.password2}
              onChange={e => onChange('password2', e.target.value)}
            /> */}
              <div style={{ fontSize: '95%' }}>
                {t('Briefly tell us what you are looking for and we will contact you soon.')}
              </div>
              <TextField
                multiline
                minRows={3}
                placeholder={t('Message')}
                value={user.signup_notes}
                style={{ marginTop: 5 }}
                onChange={e => onChange('signup_notes', e.target.value)}
              />
              <Button
                variant="contained"
                onClick={submit}
                disabled={
                  saving ||
                  !(
                    !!user.first_name.trim() &&
                    !!user.last_name.trim() &&
                    !!user.email.trim() &&
                    isValidEmail(user.email) &&
                    !!user.signup_notes?.trim()
                  )
                }
                fullWidth
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
      <FooterNew />
    </>
  )
}

function Thanks() {
  return <div style={{ marginTop: 40 }}>{t("Thanks for your interest! We'll respond as soon as possible.")}</div>
}
