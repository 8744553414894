import React from 'react'
import GlobalHeader from '../../common/HeaderNew'
import FooterNew from "../../common/FooterNew";
import FindWork from './FindWork';
import Mission from './Mission';
import WhyCompago from './WhyCompago'
import Testimonials from './Testimonials';
export default function Career() {
    return (
        <>
            <GlobalHeader />
            <FindWork />
            <Mission />
            <WhyCompago />
            <Testimonials />
            <FooterNew />
        </>
    )
}
