/* eslint-disable jsx-a11y/alt-text */
import './Nav.scss'

import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { STATIC3_URL } from '../../constants'
import { t } from '../../i18n'
import logo from '../../assets/compago-logo.png'

export function Nav({ extraLinks }) {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const logout = async () => {
    await axios.post('/logout')
    navigate('/')
    window.location.reload()
  }

  return (
    <>
      <div className="Nav">
        <div className="nav-container desktop">
          <div className="left">
            <Link to="/">
              <img src={logo}></img>
            </Link>
          </div>
          <div className="right">
            {extraLinks}
            <Button sx={{ color: '#fff' }} onClick={logout}>
              {t('Log out')}
            </Button>
          </div>
        </div>
        <div className="mobile">
          <div className="left">
            <Link to="/">
              <img src={`${STATIC3_URL}/logo-1.png`}></img>
            </Link>
          </div>
          <div className="right">
            <IconButton onClick={() => setOpen(true)}>
              <MenuIcon sx={{ color: '#eee' }} />
            </IconButton>
          </div>
          <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <List>
              {/* <ListItem disablePadding>
                <ListItemButton component="a" href="/help">
                  <ListItemText primary={t('Help Center')} />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton onClick={logout}>
                  <ListItemText primary={t('Log out')} />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
        </div>
      </div>
      <div className="NavSpace" />
    </>
  )
}
