import { Button, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { t } from '../../../i18n'
import { useEmployeeSignupContext } from '../../../hooks'

export function Step2b() {
  const { user, setUser } = useEmployeeSignupContext()
  const navigate = useNavigate()

  const next = () => {
    navigate('/register/step-3b')
  }
  const back = () => {
    navigate(-1)
  }
  
  const onChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    _.set(tmp, prop, val)
    setUser(tmp)
  }

  return (
    <Dialog
      fullWidth
      open={true}
      className="step2b"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Sign Up')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container my={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('Who is applying?')}
            </Typography>
            <FormControl>
              <Typography variant="body1" color="text.secondary">
                <RadioGroup aria-labelledby="currently_employed">
                  <FormControlLabel
                    control={
                      <Radio
                        variant="body1"                      
                        checked={!user.has_crew}
                        onChange={() => onChange('has_crew', false)}
                      />
                    }
                    color="primart"
                    label={t('Just me')}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={user.has_crew}
                        onChange={() => onChange('has_crew', true)}
                      />
                    }
                    label={t('I have a crew')}
                  />
                </RadioGroup>
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={back}>
              {t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={next}>
              {t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
