import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { t } from '../../../../i18n'
import { useEmployeeSignupRoundTwoContext } from '../../../../hooks'

import './Step5.scss'

export function Step5() {
  const { user, setUser, submit } = useEmployeeSignupRoundTwoContext()

  const onChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    _.set(tmp, prop, val)
    setUser(tmp)
  }

  const onLangSpeakChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    if (prop === "speaks_some_english" && val) {
        tmp.speaks_english = false
    }
    if (prop === "speaks_english" && val) {
        tmp.speaks_some_english = false
    }
    if (prop === "speaks_spanish" && val) {
        tmp.speaks_some_spanish = false
    }
    if (prop === "speaks_some_spanish" && val) {
        tmp.speaks_spanish = false
    }
    tmp[prop] = val
    setUser(tmp)
  }

  const onLangComprehendChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    if (prop === "comprehends_english" && val) {
      tmp.comprehends_some_english = false
    }
    if (prop === "comprehends_some_english" && val) {
      tmp.comprehends_english = false
    }
    if (prop === "comprehends_spanish" && val) {
      tmp.comprehends_some_spanish = false
    }
    if (prop === "comprehends_some_spanish" && val) {
      tmp.comprehends_spanish = false
    }
    tmp[prop] = val
    setUser(tmp)
  }
  
  const navigate = useNavigate()
  
  const back = () => {
    navigate('/signup-round-two/step-4')
  };
  
  const next = async () => {
    await submit()
    navigate('/signup-round-two/done')
  };

  const disableFinish = !user?.zip_code ||
    !user?.potential_start_date ||
    (
      !user?.speaks_english &&
      !user?.speaks_spanish &&
      !user?.speaks_some_english &&
      !user?.speaks_some_spanish
    ) ||
    (
      !user?.comprehends_english &&
      !user?.comprehends_spanish &&
      !user?.comprehends_some_english &&  
        !user?.comprehends_some_spanish
    )

    return (
    <Dialog
      fullWidth
      open={true}
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Signup - Round Two')}
        </Typography>
      </DialogTitle>
      <DialogContent container spacing={2} mb={2}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Typography variant="body1">
             {t('What zip code do you live in?')}
            </Typography>
            <TextField
              inputProps={{ min: "0", max: "99999" }}
              type="number"
              id="zipcode"
              value={user?.zip_code}
              onChange={e => {
                  let newValue = e.target.value;
                  if (newValue > 999999) {
                    return
                  }
                onChange('zip_code', e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('Are you willing to travel?')}
            </Typography>
            <RadioGroup
              value={user?.is_willing_to_travel ? "yes" : "no"}
              name="radio-buttons-group"
              onChange={e => onChange('is_willing_to_travel', e.target.value === "yes")}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('When can you start?')}
            </Typography>
            <DatePicker
              id="start-date"
              value={user?.potential_start_date}
              onChange={newValue => onChange('potential_start_date', newValue ? newValue.toDate() : '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: 'auto' }}
                  onClick={(e) => {
                    params.inputProps.onClick(e); // Open the DatePicker on click
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('Which language(s) can you speak?')}
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.speaks_english}
                    onChange={e => onLangSpeakChange('speaks_english', e.target.checked)}
                  />
                }
                label={t('English')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.speaks_spanish}
                    onChange={e => onLangSpeakChange('speaks_spanish', e.target.checked)}
                  />
                }
                label={t('Spanish')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.speaks_some_english}
                    onChange={e => onLangSpeakChange('speaks_some_english', e.target.checked)}
                  />
                }
                label={t('Some English')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.speaks_some_spanish} 
                    onChange={e => onLangSpeakChange('speaks_some_spanish', e.target.checked)}
                  />
                }
                label={t('Some Spanish')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('Which language(s) can you comprehend?')}
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.comprehends_english}
                    onChange={e => onLangComprehendChange('comprehends_english', e.target.checked)}
                  />
                }
                label={t('English')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.comprehends_spanish}
                    onChange={e => onLangComprehendChange('comprehends_spanish', e.target.checked)}
                  />
                }
                label={t('Spanish')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.comprehends_some_english}
                    onChange={e => onLangComprehendChange('comprehends_some_english', e.target.checked)}
                  />
                }
                label={t('Some English')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.comprehends_some_spanish}
                    onChange={e => onLangComprehendChange('comprehends_some_spanish', e.target.checked)}
                  />
                }
                label={t('Some Spanish')}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
          <Button
              fullWidth size="large"
              color="primary"
              variant="contained"
              onClick={back}
              >
              {t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
          <Button
            disabled={disableFinish}
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={next}
          >
            {t('Finish')}
          </Button>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </DialogActions>
  </Dialog>
  )
}