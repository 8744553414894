import './Contact.scss'

import { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { Footer } from './common/Footer'
import { HomePageNav } from './homepage/HomePageNav'
import { t } from '../i18n'
import { Form } from './common/Form'
import { toastWarn } from '../events'
import { useScrollToTop } from '../hooks/useScrollToTop'

const fields = [
  ['Name', 'name', '', 'text'],
  ['Email', 'email', '', 'text'],
  ['Phone number', 'phone', '', 'text'],
  ['Message', 'message', '', 'text', { multiline: true, minRows: 5 }]
]

export function useContactUsForm() {
  const [saving, setSaving] = useState(false)
  const [done, setDone] = useState(false)
  const [state, setState] = useState(_.cloneDeep(fields))

  const onSubmit = async () => {
    setSaving(true)
    try {
      const res = await axios.post('/contact', {
        name: state[0][2],
        email: state[1][2],
        phone: state[2][2],
        message: state[3][2]
      })
      console.log(res)
      setSaving(false)
      setDone(true)
    } catch (err) {
      toastWarn(err.message)
      setSaving(false)
    }
  }

  return { state, setState, saving, done, onSubmit }
}

export function Contact(props) {
  useScrollToTop()
  const { state, setState, saving, done, onSubmit } = useContactUsForm(props.onClose)

  return (
    <>
      <HomePageNav />
      <div className="Contact">
        <div className="container">
          <h1>{t('Contact us')}</h1>
          <div className="main">
            {done ? (
              t("Thanks! We'll be in touch.")
            ) : (
              <Form
                fields={state}
                onChange={setState}
                validate={() => state[0][2].trim() && state[1][2].trim() && state[2][2].trim()}
                onSubmit={onSubmit}
                saving={saving}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
