import axios from 'axios'
import { Resource } from './base'

export class UserResource extends Resource {
  constructor() {
    super('users')
    this.orgAdmins = this._createEndpoint({ endpointName: 'org_admins', httpVerb: 'get' })
    this.createEmployee = this._createEndpoint({ endpointName: 'create_employee' })
    this.bulkUpdate = this._createEndpoint({ endpointName: 'bulk_update', httpVerb: 'put' })
    this.bulkDelete = this._createEndpoint({ endpointName: 'bulk_delete' })
    this.updateWorkHours = this._createEndpoint({ endpointName: 'work_hours', httpVerb: 'put', detail: true })
    this.changePassword = (...args) => this._wrapWithToastCalls(() => this._changePassword(...args))
  }

  async _changePassword(data) {
    const res = await axios.post(`/${this.name}/${data.id}/change_password`, data.password)
    return res.data
  }
}

export const users = new UserResource()
