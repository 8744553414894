import CheckIcon from "@mui/icons-material/Check";
import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import vector from "../../../assets/about_page/mission_border_line.png";
import vector1 from "../../../assets/homepage/vector1.png";
import { t } from "../../../i18n";

const SuccessRate = () => {
  return (
    <div className="success-rate">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="university-heading">
              {/* <TitleBorderLine /> */}
              <img src={vector} alt="" className="mission" />
              <Typography variant="h2" gutterBottom>
                {t("Compago’s High Success Rate")}
              </Typography>
              {/* <div className="university-sub-title">
                <Typography variant="h5">
                  {t("We train our team , That what make us different")}
                </Typography>
              </div> */}

              <div className="university-sub-heading">
                <Typography variant="subtitle1">
                  {t(
                    `Whether you need additional laborers, a higher-quality workforce, or an expert team to manage your project from start to finish, Compago is your one-stop shop to upgrade your projects.`
                  )}
                </Typography>
              </div>

              <div className="success-tick">
                <ul>
                  <li>
                    {<CheckIcon className="icon" />}{t("You get a massive pool of talent")}
                  </li>
                  <li>
                    {<CheckIcon className="icon" />}{t("We train our team to produce better results")}
                  </li>
                  <li>
                    {<CheckIcon className="icon" />}{t("Our success stories speak for themselves")}
                  </li>
                </ul>
              </div>
              {/* <Typography variant="h6" gutterBottom className="sub-heading">
                {t("Examples of Our Core Courses")}
              </Typography> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SuccessRate;
