import React from "react";
import { Container, Typography } from "@mui/material";
import { t } from "../../../../i18n";
import whyCompago from "../../../../assets/career_page/whyCompago.png";
import logo from "../../../../assets/career_page/logo.png";

const whyCompagoJSON = [
  {
    title: "Impactful Work:",
    description: `Contribute to projects that matter.From residential buildings
        to commercial complexes, your work will positively impact lives and communities.`,
  },
  {
    title: "Culture & Values:",
    description: `At the heart of our success is a culture rooted in teamwork, integrity, and excellence. We believe in doing the right thing, always.`,
  },
  {
    title: "Diversity & Inclusion:",
    description: `Join a diverse team where everyone belongs. We are committed to creating an inclusive environment where diverse perspectives are valued.`,
  },
  {
    title: "Learning & Growth:",
    description: `We invest in your growth with continuous learning opportunities, leadership development, and career advancement paths.`,
  },
];

export default function WhyCompago() {
  return (
    <div className="whyCompagoMainWrap">
      <img src={whyCompago} alt="" /> {/*forground image*/}
      <img src={logo} className="whyCompagoBgLayer" alt="" />{" "}
      {/*background image*/}
      <Container maxWidth="xl">
        <div className="whyCompago_content">
          <Typography variant="h1">{t("Why Compago?")}</Typography>
          {whyCompagoJSON
            ?.map((item, index) => ({
              ...item,
              index,
            }))
            .map((item) => (
              <div className="whyCompagoCol">
                <p>
                  <span>{t(item.title)}</span>
                  {t(item.description)}
                </p>
              </div>
            ))}
        </div>
      </Container>
    </div>
  );
}
