import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import vector1 from "../../../../assets/about_page/mission_border_line.png";
import bottom_vector from "../../../../assets/homepage/vector1.png";

import img1 from "../../../../assets/services_page/img_1.png";
import img2 from "../../../../assets/services_page/img_2.jpg";
import img3 from "../../../../assets/services_page/img_3.jpg";
import Icon1 from "../../../../assets/services_page/Icon1.png";
import Icon2 from "../../../../assets/services_page/Icon2.png";
import Icon3 from "../../../../assets/services_page/Icon3.png";

import { t } from "../../../../i18n";
import ServiceCard from "../../../common/ServiceCard";
const General = () => {
  return (
    <div className="general">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="general-heading">
              {/* <TitleBorderLine /> */}
              <img src={vector1} alt="" />
              <Typography variant="h2" gutterBottom>
                {t("Quality On-Demand Labor Services")}
              </Typography>
              <div className="general-sub-heading">
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>
                  {t(
                    "Our on-demand labor service provides robust support across various environments."
                  )}
                </Typography>
                {/* <Typography variant="subtitle1">
                  {t("environments.")}
                </Typography> */}
              </div>
            </Box>
          </Grid>
        </Grid>
        <div className="blue-highlight"></div>
        <Grid container spacing={3} className="relative margin-top-2">
          <Grid item xs={12} md={4}>
            <ServiceCard
              showUserImg2={Icon1}
              title={t("Disaster Relief:")}
              subTitle={t(`Quick-response Teams`)}
              cardImage={img1}
              content={t(
                "Rapid deployment in the aftermath of natural disasters, providing essential reconstruction and repairs to affected communities."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceCard
              showUserImg2={Icon2}
              title={t(`Work Camps & Oil Fields:`)}
              subTitle={t(`On-Demand Labor`)}
              cardImage={img3}
              content={t(
                "Rapid deployment in the aftermath of natural disasters, providing essential reconstruction and repairs to affected communities."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceCard
              showUserImg2={Icon3}
              title={t("Construction Job Sites:")}
              subTitle={t(`Dedicated Workers`)}
              cardImage={img2}
              content={t(
                `Comprehensive solutions for major construction projects, emphasizing quality and efficiency from start to finish.`
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default General;
