import { useEffect, useState } from 'react'
import { Avatar } from '@mui/material'
import { imageToDataUrl } from '../../util/imageUtil'
import { getFullImageUrl } from '../../util/stringUtil'

export function UserAvatar({ src, user, useDataUrl = true, width = 120, height = 120 }) {
  const [avatarDataUrl, setAvatarDataUrl] = useState(null)

  useEffect(() => {
    if (!src && user?.profile?.profile_pic && useDataUrl) {
      const src = getFullImageUrl(user.profile.profile_pic)
      imageToDataUrl(src).then(dataUrl => setAvatarDataUrl(dataUrl))
    }
  }, [src, user, useDataUrl])

  let avatarProps = { sx: { width, height, userSelect: 'initial' } }
  if (src) {
    avatarProps.src = src
  } else if (user?.profile?.profile_pic) {
    avatarProps.src = useDataUrl ? avatarDataUrl : getFullImageUrl(user.profile.profile_pic)
  } else if (user) {
    let text = ''
    if (user.first_name?.length && user.last_name?.length) {
      text = `${user.first_name[0]} ${user.last_name[0]}`
    } else if (user.full_name) {
      const tmp = user.full_name.split(' ')
      text = `${tmp[0][0]} ${tmp[1][0]}`
    }
    avatarProps.children = text
  }

  return <Avatar variant="rounded" {...avatarProps} />
}
