import React from "react";
import { Container, Typography } from "@mui/material";
import { t } from "../../../../i18n";
import targetImg from "../../../../assets/career_page/mission.svg";

export default function Mission() {
  return (
    <div className="missionMainWrap">
      <img src={targetImg} alt="" />
      <Container maxWidth="xl">
        <div className="mission_content">
          <Typography variant="h1">{t("Our Mission")}</Typography>
          <p>
            {t(
              `To revolutionize the construction industry through innovation, sustainability, and excellence. We are dedicated to creating impactful projects that enhance communities and stand the test of time.`
            )}
          </p>
        </div>
      </Container>
    </div>
  );
}
