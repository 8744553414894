import "./App.scss";

import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CircularProgress, IconButton, Snackbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { emitter } from "../events";
import { EmployeeSignup } from "./signup/employee/EmployeeSignup";
import { HomePage } from "./homepage/HomePage";
import { EmployeeDashboard } from "./dashboard/employee/EmployeeDashboard";
import { EmployerDashboard } from "./dashboard/employer/EmployerDashboard";
import { AdminDashboard } from "./dashboard/admin/AdminDashboard";
import { Login } from "./homepage/Login";
// import { EmployeeSearch } from './EmployeeSearch'
import { PostAJob } from "./PostAJob";
import { Help } from "./Help";
import { Logger } from "../logging";
import { Contact } from "./Contact";
import { ComingSoon } from "./ComingSoon";
import { Testimonials } from "./Testimonials";
import { UserContext } from "../context";
import { EmployerSignup } from "./signup/EmployerSignup";
import { api } from "../api";
import { AboutMe } from "./AboutMe";
import { TeamMembers } from "./TeamMembers";
import { Reservations } from "./Reservations";
import { AcceptInvitation } from "./AcceptInvitation";
import Home from "./home";
import DashboardLayout from "./Layouts/DashboardLayout";
import About from "./pages/about";
import Management from "./pages/management";
import Services from "./pages/services";
import CompagoUniversity from "./pages/compago-university";
import Career from "./pages/career";
import ContactUs from "./pages/contact";
import SwiperCore, { Autoplay } from "swiper";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { useScrollToTop } from '../hooks/useScrollToTop'

const { log } = new Logger("app:ui:App");

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.darkMode ? "#eee" : "#4B98CA",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Ubuntu",
      "Helvetica Neue",
    ].join(","),
  },
});

let _x = 0;

export function App() {
  useScrollToTop()
  // eslint-disable-next-line no-unused-vars
  const [[toast, open, toastType], setToast] = useState([null, false, null]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [spoof, setSpoof] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [x, setX] = useState(0);
  const location = useLocation();
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    emitter.on("toast", (msg) => setToast([msg, true, "info"]));
    emitter.on("toast.success", ([msg, type]) => setToast([msg, true, type]));
    emitter.on("toast.warn", ([msg, type]) => setToast([msg, true, type]));
    emitter.on("session.user", (user) => setUser(user));
    emitter.on("labels.changed", () => setX(_x++));

    const fn = async () => {
      const { spoof } = await api.init();
      setLoading(false);
      if (spoof) {
        setSpoof(true);
      }
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    log("%s%s", location.pathname, location.search);
  }, [location]);

  if (loading) {
    return (
      <div className="App loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="App">
      <UserContext.Provider value={[user, setUser]}>
        {spoof ? <SpoofBar user={user} /> : null}
        <UserRoutes />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={() => setToast([null, false, null])}
          message={toast}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setToast([null, false, null])}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </UserContext.Provider>
    </div>
  );
}

export function Providers(props) {
  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {props.children}
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}

const UserRoutes = () => {
  const map = {
    EMPLOYEE: EmployeeRoutes,
    EMPLOYER: EmployerRoutes,
    ADMIN: AdminRoutes,
  };
  const Component = map[api.user?.type] || VisitorRoutes;
  return <Component />;
};

const VisitorRoutes = () => (
  <Routes>
    <Route index element={<Home />} />
    <Route
      path="register/*"
      element={
        <DashboardLayout>
          <EmployeeSignup />
        </DashboardLayout>
      }
    />
    <Route
      path="login"
      element={
        <DashboardLayout>
          <Login />
        </DashboardLayout>
      }
    />
    <Route
      path="about"
      element={
        <DashboardLayout>
          <About />
        </DashboardLayout>
      }
    />
    <Route
      path="services"
      element={
        <DashboardLayout>
          <Services />
        </DashboardLayout>
      }
    />
    <Route
      path="compago-university"
      element={
        <DashboardLayout>
          <CompagoUniversity />
        </DashboardLayout>
      }
    />
    <Route
      path="contact"
      element={
        <DashboardLayout>
          <ContactUs />
        </DashboardLayout>
      }
    />
    <Route path="employer-signup" element={<EmployerSignup />} />
    <Route path="post-a-job" element={<PostAJob />} />
    <Route path="help" element={<Help />} />
    {/* <Route path="contact" element={<Contact />} /> */}
    <Route path="testimonials" element={<Testimonials />} />
    <Route path="careers" element={<Career />} />
    <Route path="privacy" element={<PrivacyPolicy />} />
    <Route path="about-me" element={<AboutMe />} />
    <Route path="reservations" element={<Reservations />} />
    <Route path="the-team" element={<TeamMembers />} />
    <Route path="accept-invitation" element={<AcceptInvitation />} />
    <Route path="*" element={<SaveUrlAndNavigate to="/login" />} />
  </Routes>
);

const SaveUrlAndNavigate = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const url =
      window.location.pathname + window.location.search + window.location.hash;
    navigate(`${to}?redirect=${encodeURIComponent(url)}`);
  }, [to, navigate]);
  return null;
};

const EmployeeRoutes = () => (
  <Routes>
    <Route path="about-me" element={<AboutMe />} />
    <Route path="reservations" element={<Reservations />} />
    <Route path="the-team" element={<TeamMembers />} />
    <Route
      path="/*"
      element={
        <DashboardLayout showLogOut={true}>
          <EmployeeDashboard />
        </DashboardLayout>
      }
    />
  </Routes>
);

const EmployerRoutes = () => (
  <Routes>
    <Route path="/*" element={<EmployerDashboard />} />
  </Routes>
);

const AdminRoutes = () => (
  <Routes>
    <Route path="about-me" element={<AboutMe />} />
    <Route path="reservations" element={<Reservations />} />
    <Route path="the-team" element={<TeamMembers />} />
    <Route path="/*" element={<AdminDashboard />} />
  </Routes>
);

const SpoofBar = ({ user }) => (
  <div className="SpoofBar">
    Viewing the app as {user.email}. Close this tab or reload the page to return
    to your admin dashboard.
  </div>
);
