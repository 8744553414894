export const getAppSettingDefaultValues = () => ({
  name: '',
  value: ''
})

export const getContactDefaultValues = () => ({
  name: '',
  email: '',
  phone: ''
})

export const getEmailNotificationDefaultValues = () => ({
  type: '',
  user: getUserDefaultValues(),
  sent_at: new Date(),
  from_email: '',
  to_email: '',
  cc: '',
  bcc: '',
  subject: '',
  body: ''
})

export const getEmployeeTradeDefaultValues = () => ({
  trade: getTradeDefaultValues(),
  hourly_rate: 0
})

export const getEmployerProfileDefaultValues = () => ({
  phone_number: '',
  data: {}
})

export const getLabelDefaultValues = () => ({
  lang: '',
  data: ''
})

export const getLogEntryDefaultValues = () => ({
  content: ''
})

export const getMatchDefaultValues = () => ({
  org: getOrgDefaultValues(),
  user: getUserDefaultValues(),
  label: ''
})

export const getNoteDefaultValues = () => ({
  text: '',
  author: getUserDefaultValues()
})

export const getOrgDefaultValues = () => ({
  name: ''
})

export const getTestimonialDefaultValues = () => ({
  title: '',
  body: '',
  image_url: ''
})

export const getTradeDefaultValues = () => ({
  name: ''
})

export const getUserDefaultValues = ({
  includeProfile = true,
  includeEmployerProfile = false,
  includeOrg = false
} = {}) => ({
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  type: '',
  status: '',
  password: '',
  password2: '',
  profile: includeProfile ? getUserProfileDefaultValues() : undefined,
  org: includeOrg ? getOrgDefaultValues() : undefined,
  has_crew: false,
})

export const getUserProfileDefaultValues = () => ({
  data: {
    phone_number: '',
    city_state: '',
    referred_by: '',
    trade: '',
    applying_for: '',
    years_exp: '',
    employee_type: '',
    can_travel: {},
    can_travel_note: '',
    start_date: new Date(),
    extra_skills: '',
    notes: '',
    language: {},
    language_note: '',
    previous_jobs: {},
    references: {},
    extra_info: '',
    currently_employed: false,
    current_employer: '',
    current_role: '',
    current_job_duration: '',
    previous_employer: '',
    previous_role: '',
    reason_for_leaving: '',
    unemployed_length: '',
    reason_for_unemployment_gap: '',
    current_pay: '',
    desired_pay: '',
    criminal_record: false,
    criminal_record_info: ''
  },
  profile_pic: '',
  profile_vid: '',
  resume: '',
  background_check: '',
  trade_info: {}
})

export const getWorkHoursDefaultValues = () => ({
  start_time: '',
  end_time: '',
  mo: false,
  tu: false,
  we: false,
  th: false,
  fr: false,
  sa: false,
  su: false
})


export const getUserDefaultValuesRound2 = () => ({
  speaks_english: false,
  speaks_spanish: false,
  speaks_some_english: false,
  speaks_some_spanish: false,
  comprehends_english: false,
  comprehends_spanish: false,
  comprehends_some_english: false,
  comprehends_some_spanish: false,
  is_willing_to_travel: "yes",
  potential_start_date: null,
  zip_code: "",
  profile: {
    profile_vid: "",
    example_pic_1: "",
    example_pic_2: "",
    example_pic_3: "",
    example_pic_4: "",
    example_pic_5: "",
    data: {
      trade: {},
      other_experience: "",
      

    }
  }
})