import _ from 'lodash'
import type { User } from '../types'
import { validateUserProfile } from './userProfile'

export function validateUser(user: User) {
  const rv: User = _.cloneDeep(user)
  delete rv.password2
  delete rv.created_at
  delete rv.updated_at
  if (user.profile) {
    rv.profile = validateUserProfile(user.profile)
  }
  return rv
}
