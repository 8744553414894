import './Carousel.scss'

import { useEffect, useLayoutEffect, useRef, useState } from 'react'

let _index = 0
let _timer = null

export function Carousel(props) {
  const { children } = props

  const dotsArray = window.innerWidth > 940 ? children.slice(0, children.length - 2) : children.slice()

  const ref = useRef()
  const [index, setIndex] = useState(_index)

  useEffect(() => {
    _timer = setInterval(() => {
      _index++
      if (_index > dotsArray.length - 1) {
        _index = 0
      }
      setIndex(_index)
    }, 5000)
    return () => {
      clearInterval(_timer)
    }
  }, [dotsArray.length])

  useLayoutEffect(() => {
    const node: HTMLElement = ref.current
    const child: HTMLElement = node.children[index]
    node.scrollTo({ left: child.offsetLeft, behavior: 'smooth' })
  }, [index])

  return (
    <div className="Carousel">
      <div className="scroller" ref={ref}>
        {children}
      </div>
      <div className="dots">
        {dotsArray.map((d, i) => (
          <div
            key={i}
            className={`dot ${i === index ? 'active' : ''}`}
            onClick={() => {
              setIndex(i)
              clearInterval(_timer)
            }}
          />
        ))}
      </div>
    </div>
  )
}
