import { createContext } from 'react'
import _ from 'lodash'
import type { User, Org } from './types'

export const UserContext = createContext()
export const UserDetailBaseUrlContext = createContext()

type EmployeeSignupContextDefaultValue = {
  user: User,
  setUser: Function,
  saving: Boolean,
  setSaving: Function,
  submit: Function,
  activate: Function
}

const employeeSignupContextDefaultValue: EmployeeSignupContextDefaultValue = {
  user: null,
  setUser: _.noop,
  saving: false,
  setSaving: _.noop,
  submit: _.noop,
  activate: _.noop
}

export const EmployeeSignupContext = createContext(employeeSignupContextDefaultValue)

type EmployeeSignupRoundTwoContextDefaultValue = {
  user: User,
  setUser: Function,
  saving: Boolean,
  setSaving: Function,
  submit: Function,
  complete: Function
}

const employeeSignupRoundTwoContextDefaultValue: EmployeeSignupRoundTwoContextDefaultValue = {
  user: null,
  setUser: _.noop,
  saving: false,
  setSaving: _.noop,
  submit: _.noop,
  complete: _.noop
}

export const EmployeeSignupRoundTwoContext = createContext(employeeSignupRoundTwoContextDefaultValue)

type EmployeeSignupRoundThreeContextDefaultValue = {
  user: User,
  setUser: Function,
  saving: Boolean,
  setSaving: Function,
  submit: Function,
  complete: Function
}

const employeeSignupRoundThreeContextDefaultValue: EmployeeSignupRoundThreeContextDefaultValue = {
  user: null,
  setUser: _.noop,
  saving: false,
  setSaving: _.noop,
  submit: _.noop,
  complete: _.noop
}

export const EmployeeSignupRoundThreeContext = createContext(employeeSignupRoundThreeContextDefaultValue)

type OrgContextDefaultValue = {
  org: Org,
  refetch: Function
}

const orgContextDefaultValue: OrgContextDefaultValue = {
  org: null,
  refetch: _.noop
}

export const OrgContext = createContext(orgContextDefaultValue)
