import React, { useEffect, useRef, useState } from "react";
import { Container } from "@mui/material";
import { t } from "../../../../i18n";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import img1 from "../../../../assets/career_page/ts_1.jpg";
import img3 from "../../../../assets/career_page/quote.svg";

const SliderCard = ({ image, title, description }) => {
  return (
    <div className="testimonialCardWrap">
      <img src={image} alt="" className="cardImg" />
      <div className="content_wrap">
        <img src={img3} className="sliderImg" alt="" />
        <div className="content">
          <div className="description">{description}</div>
          <div className="user_info">
            <p className="heading">Ashley Rivera</p>
            <p>{t(`Project Manager`)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function JoinTeam() {
  const [sliderIndex, setSliderIndex] = useState(0);
  const swipeRef = useRef();

  useEffect(() => {
    if (swipeRef.current && swipeRef.current.swiper) {
      swipeRef.current.swiper.slideTo(sliderIndex);
    }
  }, [sliderIndex]);

  useEffect(() => {
    const handleSlideChange = () => {
      if (swipeRef.current && swipeRef.current.swiper) {
        setSliderIndex(swipeRef.current.swiper.activeIndex);
      }
    };

    if (swipeRef.current && swipeRef.current.swiper) {
      swipeRef.current.swiper.on("slideChange", handleSlideChange);
    }

    return () => {
      if (swipeRef.current && swipeRef.current.swiper) {
        swipeRef.current.swiper.off("slideChange", handleSlideChange);
      }
    };
  }, []);

  return (
    <div className="testimonialMainWrap">
      <div className="sliderbtns">
        <div className="slider_dot_wrap">
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              onClick={() => setSliderIndex(index)}
              className={`slider_dot ${sliderIndex === index && "active"}`}
            ></div>
          ))}
        </div>
      </div>
      <Container Container maxWidth="xl" className="testimoialContainer">
        <div className="slickWrap">
          <Swiper
            ref={swipeRef}
            spaceBetween={50}
            slidesPerView={1}
            centeredSlides={false}
            pagination={{
              dynamicBullets: true,
            }}
          >
            {Array.from({ length: 3 }).map((_, index) => (
              <SwiperSlide>
                <SliderCard
                  image={img1}
                  title={t("Position 1")}
                  description={t(
                    `When I first joined Compago, I was immediately struck by the company’s dedication to not just building, but building right. Here, it’s not just about meeting deadlines; it’s about how we impact the community and the environment around us. Every project I’ve worked on has challenged me in new ways, pushing me to innovate and think outside the box.`
                  )}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Container>
    </div>
  );
}
