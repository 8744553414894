import axios from 'axios'
import { emitter } from '../events'
import { i18n } from '../i18n'
import { Resource } from './base'
import { employees } from './EmployeeResource'
import { matches } from './MatchResource'
import { me } from './MeResource'
import { notes } from './NoteResource'
import { userProfiles } from './UserProfileResource'
import { users } from './UserResource'
import { orgs } from './OrgResource'
import { exportUsers } from './ExportUsersResource'
import { LocalStorageKey } from '../constants'

export const api = {
  user: null,

  async init() {
    let url = '/session'
    let spoof = false
    try {
      const params = new URLSearchParams(window.location.search)
      if (params.has('viewAs') && params.has('id')) {
        url += '?' + params.toString()
        spoof = true
      }
    } catch {}

    const res = await axios.get(url)
    const { user, csrf } = res.data
    if (csrf) {
      this.setCsrf(csrf)
    } else {
      throw new Error('Error connecting to server')
    }

    if (user) {
      this.setUser(user)
      console.log(`user=${user.email}`)

      if (spoof) {
        this.setSpoofUser(user)
      }
    } else {
      console.log('user=null')
    }

    try {
      await i18n.init()
    } catch (err) {
      console.error(err)
    }

    return { user, spoof }
  },

  setCsrf(csrf) {
    axios.defaults.headers.common['X-CSRFToken'] = csrf
  },

  setUser(user) {
    this.user = user
    localStorage.setItem(LocalStorageKey.EmployeeSignup.USER, JSON.stringify(user))
    emitter.emit('session.user', user)
  },

  setSpoofUser(user) {
    axios.defaults.headers.common['X-SpoofId'] = user.id
  },

  employees,
  matches,
  me,
  notes,
  userProfiles,
  users,
  orgs,
  exportUsers,

  employers: new Resource('employers'),
  trades: new Resource('trades'),
  workHours: new Resource('work_hours'),
  labels: new Resource('labels'),
  settings: new Resource('settings'),
  logEntries: new Resource('logs', { wrap: false }),
  testimonials: new Resource('testimonials'),
  candidates: new Resource('candidates'),
  generateCsv: new Resource('generate_csv'),
  contactUs: new Resource('contact-us')
}
