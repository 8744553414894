import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const debug = require('debug')('app:hooks')

type DataType = 'string' | 'number' | 'boolean' | 'date' | 'json'

export function useQueryParam(key: String, type: DataType = 'string', defaultValue: any = undefined) {
  const navigate = useNavigate()
  const location = useLocation()

  const setValue = useCallback(
    newValue => {
      const params = new URLSearchParams(window.location.search)
      if (newValue == null || newValue === '') {
        params.delete(key)
      } else {
        params.set(key, newValue?.toJSON ? newValue.toJSON() : newValue)
      }
      navigate({
        pathname: location.pathname,
        search: params.toString()
      })
    },
    [key, location.pathname, navigate]
  )

  const value = useMemo(() => {
    const params = new URLSearchParams(location.search)
    if (!params.has(key)) {
      if (defaultValue != null) {
        setTimeout(() => {
          setValue(defaultValue)
        }, 0)
      }
      return defaultValue
    }

    const value = params.get(key)

    let parsedValue
    switch (type) {
      case 'boolean':
        parsedValue = value.toLowerCase() === 'true'
        break
      case 'number':
        parsedValue = Number(value)
        break
      case 'date':
        parsedValue = new Date(value)
        break
      case 'json':
        try {
          parsedValue = JSON.parse(value)
        } catch (err) {
          console.error(err)
          parsedValue = defaultValue
        }
        break
      default:
        parsedValue = value
    }

    debug('[useQueryParam] parsedValue=', parsedValue)
    return parsedValue
  }, [setValue, location.search, key, type, defaultValue])

  return [value, setValue]
}
