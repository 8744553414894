import { Container, Typography } from "@mui/material";
import React from "react";
import { t } from "../../../i18n";
import FormCard from "./Form";

const Contact = () => {
  return (
    <>
      <div className="contactMainWrap">
        <Container maxWidth="xl">
          <div className="contact_content">
            <Typography variant="h1">{t("Contact Us")}</Typography>
            <Typography variant="h5">
              {t("Let's Build Something Amazing Together")}
            </Typography>
            <p>
              {t(
                `Whether you have a question about our services, need assistance with a construction project, or just want to learn more about how we can help you build your future, we're here to help. Reach out to us using the method that works best for you, and our team will get back to you as soon as possible.`
              )}
            </p>
          </div>
        </Container>
      </div>
      <div className="form_card_bg_main">
        <Container maxWidth="xl">
          <FormCard />
        </Container>
      </div>
    </>
  );
};

export default Contact;
