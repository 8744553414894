import { Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Banner from "../../../assets/homepage/bannerSectionRight.png";
import BGVideo from "../../../assets/homepage/BGVideo.mp4";
import ButtonCustom from "../../common/Button";
import GlobalHeader from "../../common/HeaderNew";
import { GetQuote } from "../../homepage/GetQuote";
import { t } from "../../../i18n";
import { useNavigate } from "react-router-dom";
import FindWorkButton from "../../common/Button/FindWorkButton";
import BuildTeamButton from "../../common/Button/BuildTeamButton";

const EmbraceSection = () => {
  const navigate = useNavigate();
  const [showGetQuote, toggleGetQuote] = useState(false);
  const onGetQuoteClick = () => toggleGetQuote(true);
  return (
    <div className="embraceMainWrap">
      <GlobalHeader isHomePage={false} />
      <div className="bannerSection">
        <div className="video-container">
          <div>
            <Container maxWidth="xl" className="relative">
              <div className="logoText">{t("COMPAGO")}</div>
            </Container>
          </div>
          <video autoPlay muted loop playsInline className="myVideo">
            <source src={BGVideo} type="video/mp4" />
          </video>
          <div className="gradient-overlay" />
        </div>
        <Container maxWidth="xl" className="relative">
          <div className="bannerContent">
            <Grid container spacing={2}>
              <Grid item lg={7} md={8} sm={12}>
                <div className="bannerCol">
                  <Typography variant="h2">
                    {t("Embrace a")}
                    <span className="bannerHeading">{t("Revolutionary")}</span>
                    {t("Approach to Work")}
                  </Typography>
                  <p>
                    {t(
                      `We provide trained on-demand labor, skilled construction tradesmen and manage your most ambitious projects`
                    )}
                  </p>
                  <ul>
                    <li>
                      {" "}
                      <span>{t("Quality On-Demand Labor")}</span>
                    </li>
                    <li>
                      <span>{t("Skilled Construction Tradesmen")}</span>
                    </li>
                    <li>
                      <span>{t("Custom Building Solutions")}</span>
                    </li>
                  </ul>
                  <div className="bannerButton">
                    <span className="get_start_heading">
                      {t(`What do you need?`)}
                    </span>
                    <div className="how-it-works-new-btns">
                      <BuildTeamButton/>
                      <FindWorkButton/>
                    </div>
                  </div>
                  <div className="lightYellowHighlight"></div>
                </div>
              </Grid>
              <Grid item lg={5} md={4} sm={12} className="bannerImg">
                <div className="bannerHeadingImg">
                  <div className="rightYellowHighlight lightYellowHighlight"></div>
                  <img src={Banner} alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      {showGetQuote ? <GetQuote onClose={() => toggleGetQuote(false)} /> : null}
    </div>
  );
};

export default EmbraceSection;
