export const PROD_BASE_URL = 'https://compagosolutions.com'
export const STATIC3_URL = `${PROD_BASE_URL}/static3`
export const INTRO_VIDEO_EN_URL = `${STATIC3_URL}/intro-en-4.mp4`
export const INTRO_VIDEO_POSTER_EN_URL = `${STATIC3_URL}/intro-en-4-poster.png`
export const INTRO_VIDEO_ES_URL = `${STATIC3_URL}/intro-es-3.mp4`
export const INTRO_VIDEO_POSTER_ES_URL = `${STATIC3_URL}/intro-es-3-poster.png`
export const LOADING = Symbol('LOADING')

export const ONE_MB = 1024 * 1024
export const ONE_GB = ONE_MB * 1024

export const LocalStorageKey = {
  EmployeeSignup: {
    USER: 'EmployeeSignup.User',
    WATCHED_VIDEO: 'EmployeeSignup.WatchedVideo',
    USER_ROUND2: 'EmployeeSignup.User_round2',
    USER_ID: 'uid'
  }
}

export const UserType = {
  EMPLOYEE: 'Employee',
  EMPLOYER: 'Employer',
  ADMIN: 'Admin'
}

export const UserStatus = {
  PENDING: 'Pending',
  ACTIVE: 'Active'
}

export const SignupStatus = {
  COMPLETED_ROUND_1: 'Completed Round 1',
  COMPLETED_ROUND_2: 'Completed Round 2',
  COMPLETED_ROUND_3: 'Completed Round 3',
  APPROVED_FOR_ROUND_2: 'Approved For Round 2',
  APPROVED_FOR_ROUND_3: 'Approved For Round 3',
  REJECTED: 'Rejected'
}

export const mobileBreakpoint = 940