import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import CardOne from "./../../../assets/homepage/Card-1.png";

const CompagoCard = ({ title, cardImage, content }) => {
  return (
    <Card className="card-element-prof">
      {/* <div className="card-layer">
        <div className="card-layer-content">
          <div className="content">
            <div className="icon-wrapper">
              <img src={UsersIcon} alt="" />
            </div>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="card-title"
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="card-description"
            >
              {content}
            </Typography>
          </div>
        </div>
      </div> */}
      <div className="card-custom relative">
        <CardMedia
          component="img"
          height="470"
          image={cardImage || CardOne}
          alt="green iguana"
        />
        <CardContent className="card-custom-content-center">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="card-title-center"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="card-description"
          >
            {content}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};

export default CompagoCard;
