import { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { getUserDefaultValues } from '../../../data'
import { EmployeeSignupContext } from '../../../context'
import type { User } from '../../../types'
import { validateUser } from '../../../forms'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step2b } from './Step2b'
import { Step3b } from './Step3b'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Done } from './Done'
import { LocalStorageKey } from '../../../constants'
import { api } from '../../../api'
import { toast } from '../../../events'
import FooterNew from '../../common/FooterNew'

import './EmployeeSignup.scss'

let _user: User = localStorage.getItem(LocalStorageKey.EmployeeSignup.USER)
if (_user) {
  _user = JSON.parse(_user)
} else {
  _user = getUserDefaultValues()
}

export function EmployeeSignup() {
  const location = useLocation()

  const [user, setUser] = useState(_.cloneDeep(_user))
  const [saving, setSaving] = useState(false)
  const [didActivate, setDidActivate] = useState(false)
  const navigate = useNavigate()
  const submit = useCallback(async () => {
    try {
      setSaving(true)
      const userInput = validateUser(user)
      if (!location.pathname.includes('/step-3')) {
        delete user.profile.profile_pic
      }
      if (!location.pathname.includes('/step-5')) {
        //delete user.profile.resume
      }
      const savedUser: User = await api.employees.create(userInput)
      setUser({
        ...savedUser,
        password: undefined,
        password2: undefined
      })
      navigate('/register/submission-complete')
      toast("Saved")
    } catch (err) {
      // todo: need to fix this when email is already in use
      if (err.response.data.username[0] === 'A user with that username already exists.') {
        toast("A user with that username already exists.")
        navigate(-2)
      }
    } finally {
      setSaving(false)
    }
  }, [user, location])

  const activate = useCallback(async () => {
    try {
      const savedUser: User = await api.employees.activate()
      setUser(savedUser)
      setDidActivate(true)
    } catch (err) {
      // no op
    }
  }, [])

  const employeeSignupContextValue = useMemo(() => {
    return { user, setUser, saving, setSaving, submit, activate }
  }, [user, setUser, saving, setSaving, submit, activate])

  useEffect(() => {
    if (didActivate) {
      localStorage.removeItem(LocalStorageKey.EmployeeSignup.USER)
    } else {
      localStorage.setItem(LocalStorageKey.EmployeeSignup.USER, JSON.stringify(user))
    }
  }, [user, didActivate])

  return (
    <EmployeeSignupContext.Provider value={employeeSignupContextValue}>
      <div className="EmployeeSignup">
        <div className="content">
          <Routes>
            <Route index element={<Navigate to="step-1" />} />
            <Route path="step-1" element={<Step1 />} />
            <Route path="step-2" element={<Step2 />} />
            <Route path="step-2b" element={<Step2b />} />
            <Route path="step-3b" element={<Step3b />} />
            <Route path="step-3" element={<Step3 />} />
            <Route path="step-4" element={<Step4 />} />
            <Route path="submission-complete" element={<Done />} />
          </Routes>
        </div>
      </div>
      <FooterNew />
    </EmployeeSignupContext.Provider>
  )
}
