import React, { useState } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import contact_us_bg_layer from "../../../../assets/contact_us_page/contact_us_bg_layer.png";
import email_img from "../../../../assets/contact_us_page/email.png";
import insta_img from "../../../../assets/contact_us_page/instagram.png";
import phone_img from "../../../../assets/contact_us_page/phone.png";
import twitter_img from "../../../../assets/contact_us_page/twitter.png";
import { t } from "../../../../i18n";
import ButtonCustom from "../../../common/Button";
import InputField from "../../../common/InputField";
import { api } from '../../../../api';
import { toastSuccess, toastWarn } from '../../../../events';

export default function FormCard() {
  const [phoneNumber, setPhoneNumber] = useState('');

  const formatPhoneNumber = (value) => {
      // Remove all non-digit characters
      value = value.replace(/\D/g, '');

      // Format the number as (123) 456-7890
      if (value.length <= 3) {
          return value;
      } else if (value.length <= 6) {
          return `(${value.slice(0, 3)}) ${value.slice(3)}`;
      } else {
          return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
      }
  };

  const handleKeyUp = (event) => {
      const formattedPhoneNumber = formatPhoneNumber(event.target.value);
      setFormData(prevState => ({
        ...prevState,
        "phone": formattedPhoneNumber
      }));
  };

  const changeBordreColor = {
    "& .MuiInputBase-root": {
      "&:before": {
        borderBottomColor: "#85a1b9", // Set border color to blue
      },
    },
  };

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setPhoneNumber(e.target.value);
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async (formData) => {
    try {
      const response = await api.contactUs.create(formData);
      console.log("Response:", response);
      if (response.id) {
        toastSuccess('Message sent successfully!');
        setIsSuccess(true);
      } else {
        toastWarn('Failed to send message');
      }
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      toastWarn('Failed to send message');
    }
  };
  
  return (
    <div className="form_main">
      <div className="formMainWrap">
        <Grid container justifyContent={"center"}>
          <Grid item sm={12} xs={12} lg={6} className="form_card_left">
            {!isSuccess && (
              <div className="contact_wrap">
                <p className="contact_info">{t(`Contact Information`)}</p>
                <div className="email_wrap">
                  <img src={email_img} alt="" />
                  <span>info@compagosolutions.com</span>
                </div>
                <div className="email_wrap">
                  <img src={phone_img} alt="" />
                  <span>(512)-948-0571</span>
                </div>
              </div>
            )}
            <img src={contact_us_bg_layer} className="left_side_layer" alt="" />
            {/* {!isSuccess && (
              <div className="bottom_content">
                <Typography variant="h6">{t(`Connect With Us`)}</Typography>
                <div className="social_icons">
                  <img src={twitter_img} alt="" />
                  <img src={insta_img} alt="" />
                </div>
              </div> 
            )} */}
            {isSuccess && (
              <div className="centered_content">
                <div className="success_message">
                  <Typography variant="h6">{t(`Thank you! We will be in touch.`)}</Typography>
                </div>
              </div>
            )}
          </Grid>
          <Grid item sm={12} xs={12} lg={6} className="form_card_right">
            {!isSuccess && (
              <>
              <Grid container className="form_input" >
                <Grid item sm={12} xs={12} lg={12} md={12}>
                <Typography variant="h6">{t(`Get in Touch`)}</Typography>
              </Grid>
              <Grid item sm={12} xs={12} lg={6} md={6}>
                <InputField
                  label={t(`First Name`)}
                  name={"first_name"}
                  type="text"
                  variant="standard"
                  sx={changeBordreColor}
                  className="form__field form__field__name"
                  onChange={handleInputChange}
                  value={formData.first_name}
                />
              </Grid>
              <Grid item sm={12} xs={12} lg={6} md={6}>
                <InputField
                  label={t(`Last Name`)}
                  name={"last_name"}
                  type="text"
                  variant="standard"
                  fullWidth={true}
                  sx={changeBordreColor}
                  className="form__field form__field__name"
                  onChange={handleInputChange}
                  value={formData.last_name}
                />
              </Grid>
              <Grid item sm={12} xs={12} lg={12} md={12}>
                <InputField
                  label={t(`Email`)}
                  name={"email"}
                  type="text"
                  variant="standard"
                  sx={changeBordreColor}
                  className="form__field form__field__email"
                  onChange={handleInputChange}
                  value={formData.email}
                />
              </Grid>
              <Grid item sm={12} xs={12} lg={12} md={12}>
                <InputField
                label={t(`Phone`)}
                  name={"phone"}
                  type="text"
                  variant="standard"
                  sx={changeBordreColor}
                  className="form__field form__field__phone"
                  onKeyUp={handleKeyUp}
                  maxLength="14"
                  onChange={handleInputChange}
                  value={formData.phone}
                />
              </Grid>
              <Grid item sm={12} xs={12} lg={12} md={12}>
                <TextField
                  label={t(`Message`)}
                  multiline
                  rows={4}
                  name={"message"}
                  type="text"
                  variant="standard"
                  sx={changeBordreColor}
                  className="form__field__message"
                  onChange={handleInputChange}
                  value={formData.message}
                />
              </Grid>
              </Grid>
              <div className="send_message">
              <ButtonCustom
                variant="contained"
                className="custom-btn"
                onClick={() => handleSubmit(formData)} // Ensure formData is collected from form inputs
              >
                {t("Send Message")}
                </ButtonCustom>
              </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}