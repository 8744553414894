import './Step1.scss'

import { t } from '../../../../i18n'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export function Step1() {
  const p1 = t('You made it to round 2!!')
  const p2 = t('We think we have a good chance of finding an amazing job for you.')
  // eslint-disable-next-line no-unused-vars

  const p3 = t(
    'At Compago we build lasting relationships with our partners (you) and our clients. We have amazing jobs for amazing team members.'
  )
  const p4 = t(
    'These next few items will strengthen your profile. Our clients like to see smiling faces and attention to detail, please take your time and show them who you are.'
  )
  const navigate = useNavigate()

  const next = async () => {
    navigate('/signup-round-two/step-2')
  };
  return (
    <Dialog
      fullWidth
      open={true}
      className="step1"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Signup - Round Two')}
        </Typography>
      </DialogTitle>

      <DialogContent container my={2} spacing={2}>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p4}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button
              fullWidth size="large"
              color="primary"
              variant="contained"
              disabled
            >
              {t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              onClick={next}
            >
              {t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>

    </Dialog>
  )
}
