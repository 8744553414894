import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import debug from "debug";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { App, Providers } from "./ui/App";
import { initLocalStorage } from "./localStorage";
import { initLogging } from "./logging";
import "./styles/style.scss";

if (localStorage.darkMode) {
  document.body.classList.add("dark-mode");
}

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:2221/api'
  axios.defaults.withCredentials = true
} else {
  axios.defaults.baseURL = "/api";
}

window._debug = debug;
dayjs.extend(relativeTime);
initLogging();
initLocalStorage();

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById("root")
);
