import React from "react";
import Experties from "./Experties";
import General from "./General";
import Custom from "./Custom";
import Building from "./Building";
import CustomSolutions from "./CustomSolutions";

const Services = () => {
  return (
    <>
      <Experties />
      <General />
      <Custom />
      <CustomSolutions />
      {/* <Building /> */}
    </>
  );
};

export default Services;
