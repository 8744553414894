import './Users.scss'

import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Link, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Tooltip
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import UpIcon from '@mui/icons-material/ArrowUpward'
import DownIcon from '@mui/icons-material/ArrowDownward'
import PeopleIcon from '@mui/icons-material/People'
import DeleteIcon from '@mui/icons-material/Delete'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import dayjs from 'dayjs'
import { useUserListQuery, useOrgListQuery, useForceUpdate } from '../../../hooks'
import { LOADING, SignupStatus, UserStatus, UserType } from '../../../constants'
import { getFullName } from '../../../util/stringUtil'
import { UserDetail } from '../../user'
import { toastWarn } from '../../../events'
import { confirm } from '../../common'
import { api } from '../../../api'

const EMPTY = '<Empty>'

let sortedGroupNames
try {
  sortedGroupNames = JSON.parse(localStorage.getItem('sortedGroupNames'))
} catch {
  sortedGroupNames = null
}

export function Users() {
  const navigate = useNavigate()
  const { data: users, refetch } = useUserListQuery()

  return (
    <div className="Users">
      <UserList users={users} refetch={refetch} />
      <Routes>
        <Route path=":userId" element={<UserDetail onSave={refetch} onClose={() => navigate('/users')} />} />
      </Routes>
    </div>
  )
}

export function UserList({ users, refetch }) {
  const [selectedUserIds, setSelectedUserIds] = useState([])
  const [showMatchDialog, setShowMatchDialog] = useState(false)
  const [showGroupDialog, setShowGroupDialog] = useState(false)
  const [sort, setSort] = useState(null)
  const forceUpdate = useForceUpdate()

  let groupNames
  if (Array.isArray(users)) {
    groupNames = [...new Set(users.map(u => ({ ...u, admin_label: u.admin_label || EMPTY })).map(u => u.admin_label))]
  }

  useEffect(() => {
    if (groupNames && (!sortedGroupNames || groupNames.some(groupName => !sortedGroupNames.includes(groupName)))) {
      sortedGroupNames = groupNames
      localStorage.setItem('sortedGroupNames', JSON.stringify(sortedGroupNames))
      forceUpdate()
    }
  }, [groupNames, forceUpdate])

  const onCheckboxChange = u => {
    let tmp
    if (selectedUserIds.includes(u.id)) {
      tmp = selectedUserIds.filter(id => id !== u.id)
    } else {
      tmp = [...selectedUserIds, u.id]
    }
    setSelectedUserIds(tmp)
  }

  const onMatchDialogClose = (didSave = false) => {
    setShowMatchDialog(false)
    if (didSave) {
      setSelectedUserIds([])
    }
  }

  const onGroupDialogClose = (didSave = false, newGroup = null) => {
    setShowGroupDialog(false)
    if (didSave) {
      setSelectedUserIds([])
      refetch()
    }
    if (newGroup) {
      sortedGroupNames = [newGroup, ...sortedGroupNames]
      localStorage.setItem('sortedGroupNames', JSON.stringify(sortedGroupNames))
      forceUpdate()
    }
  }

  const onSortChange = field => {
    if (sort === field) {
      setSort('-' + field)
    } else if (sort === '-' + field) {
      setSort(null)
    } else {
      setSort(field)
    }
  }

  const onDeleteClick = async () => {
    const body =
      selectedUserIds.length === 1
        ? 'Are you sure you want to delete this user?'
        : `Are you sure you want to delete these ${selectedUserIds.length} users?`

    if (await confirm({ body })) {
      const res = await api.users.bulkDelete({ user_ids: selectedUserIds })
      console.log(res)
      setSelectedUserIds([])
      refetch()
    }
  }

  const handleExport = async () => {
    await api.exportUsers._downloadUsersData()
  }

  if (users === LOADING || !sortedGroupNames) {
    return <CircularProgress />
  }

  const move = (groupName: String, i: Number) => {
    if (!sortedGroupNames) {
      sortedGroupNames = groupNames
    }
    const index = sortedGroupNames.indexOf(groupName)
    sortedGroupNames[index] = sortedGroupNames[index + i]
    sortedGroupNames[index + i] = groupName
    localStorage.setItem('sortedGroupNames', JSON.stringify(sortedGroupNames))
    forceUpdate()
  }

  let groupsUI = []
  for (const [i, groupName] of sortedGroupNames.entries()) {
    const groupUsers = users.filter(u => (groupName === EMPTY ? u.admin_label == null : u.admin_label === groupName))
    if (groupUsers.length === 0) continue
    groupsUI.push(
      <UserGroup
        key={i}
        label={groupName}
        users={groupUsers}
        isFirst={i === 0}
        isLast={i === groupNames.length}
        move={move}
        sort={sort}
        onSortChange={onSortChange}
        selectedUserIds={selectedUserIds}
        onCheckboxChange={onCheckboxChange}
      />
    )
  }

  return (
    <div className="UserList">
      <div className="actions">
        {selectedUserIds.length ? (
          <>
            <Button size="small" variant="outlined" onClick={() => setSelectedUserIds([])}>
              <ClearIcon />
              Clear selected
            </Button>
            <Button size="small" variant="outlined" onClick={() => setShowGroupDialog(true)}>
              <LabelOutlinedIcon />
              Change group
            </Button>
            <Button size="small" variant="outlined" onClick={() => setShowMatchDialog(true)}>
              <PeopleIcon />
              Add to client matches
            </Button>
            <Button size="small" color="warning" variant="outlined" onClick={onDeleteClick}>
              <DeleteIcon />
              Delete selected
            </Button>
          </>
        ) : null}
        <Button
          size="small"
          variant="outlined"
          onClick={handleExport}
        // className='export-btn'
        >
          <SystemUpdateAltIcon />
          Export
        </Button>
      </div>
      <div className="main-content">{groupsUI}</div>
      {showMatchDialog ? (
        <MatchDialog users={users} selectedUserIds={selectedUserIds} onClose={onMatchDialogClose} />
      ) : null}
      {showGroupDialog ? (
        <GroupDialog groups={groupNames} users={users} selectedUserIds={selectedUserIds} onClose={onGroupDialogClose} />
      ) : null}
      <Outlet />
    </div>
  )
}

function UserGroup({ label, users, isFirst, isLast, move, sort, onSortChange, selectedUserIds, onCheckboxChange }) {
  let sortedUsers
  if (sort) {
    let field, dir
    if (sort.startsWith('-')) {
      field = [sort.substring(1)]
      dir = ['desc']
    } else {
      field = [sort]
      dir = ['asc']
    }
    sortedUsers = _.orderBy(users, field, dir)
  } else {
    sortedUsers = users
  }

  return (
    <div className="UserGroup">
      <div className="group-label">
        <span style={{ paddingRight: 10 }}>{label}</span>
        {isFirst ? null : (
          <Tooltip title="Move this group up">
            <IconButton style={{ color: '#ccc' }} onClick={() => move(label, -1)}>
              <UpIcon />
            </IconButton>
          </Tooltip>
        )}
        {isLast ? null : (
          <Tooltip title="Move this group down">
            <IconButton style={{ color: '#ccc' }} onClick={() => move(label, 1)}>
              <DownIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="header label-1">
        <div></div>
        
        <div onClick={() => onSortChange('first_name')}>
          First Name {sort === 'first_name' ? '↓' : sort === '-first_name' ? '↑' : null}
        </div>
        <div onClick={() => onSortChange('last_name')}>
          Last Name {sort === 'last_name' ? '↓' : sort === '-last_name' ? '↑' : null}
        </div>
        <div onClick={() => onSortChange('email')}>Email {sort === 'email' ? '↓' : sort === '-email' ? '↑' : null}</div>
        <div onClick={() => onSortChange('type')}>Type {sort === 'type' ? '↓' : sort === '-type' ? '↑' : null}</div>
        <div onClick={() => onSortChange('status')}>
          Status {sort === 'status' ? '↓' : sort === '-status' ? '↑' : null}
        </div>
        <div onClick={() => onSortChange('bigcity')}>
          Nearest Largest City {sort === 'bigcity' ? '↓' : sort === '-bigcity' ? '↑' : null}
        </div>

        <div onClick={() => onSortChange('languages')}>
          Languages {sort === 'languages' ? '↓' : sort === '-languages' ? '↑' : null}
        </div>
        <div onClick={() => onSortChange('trades')}>
          Trades{sort === 'trades' ? '↓' : sort === '-trades' ? '↑' : null}
        </div>

        <div onClick={() => onSortChange('profile')}>
          Signup Status {sort === 'profile' ? '↓' : sort === '-profile' ? '↑' : null}
        </div>
        <div onClick={() => onSortChange('last_login')}>
          Last Login {sort === 'last_login' ? '↓' : sort === '-last_login' ? '↑' : null}
        </div>
        <div onClick={() => onSortChange('date_joined')}>
          Date Joined {sort === 'date_joined' ? '↓' : sort === '-date_joined' ? '↑' : null}
        </div>
      </div>
      {sortedUsers.map(u => (
        <UserListItem
          key={u.id}
          user={u}
          selectedUserIds={selectedUserIds}
          checked={selectedUserIds.includes(u.id)}
          onCheckboxChange={() => onCheckboxChange(u)}
        />
      ))}
    </div>
  )
}

export function UserListItem({ user, selectedUserIds, checked, onCheckboxChange }) {
  return (
    <Link
      to={'/users/' + user.id}
      className="UserListItem"
      onClick={e => {
        if (selectedUserIds.length) {
          e.stopPropagation()
          e.preventDefault()
          onCheckboxChange()
        }
      }}
    >
      <div onClick={e => e.stopPropagation()}>
        {user.type === 'EMPLOYEE' ? <Checkbox checked={checked} onChange={onCheckboxChange} /> : null}
      </div>
      <div>{user.first_name}</div>
      {/* <div>{user.bigcity}</div> */}

      <div>{user.last_name}</div>
      <div>{user.email}</div>
      <div>{UserType[user.type] || user.type}</div>
      <div>{UserStatus[user.status] || user.status}</div>
      <div>{user.bigcity}</div>
      <div>{user?.speaks_english ? "Speaks English" + (user?.speaks_spanish || user?.speaks_some_english || user?.speaks_some_spanish || user?.comprehends_english || user?.comprehends_spanish || user?.comprehends_some_english || user?.comprehends_some_spanish ? ", " : "") : ""}{user?.speaks_spanish ? "Speaks Spanish" + (user?.speaks_some_english || user?.speaks_some_spanish || user?.comprehends_english || user?.comprehends_spanish || user?.comprehends_some_english || user?.comprehends_some_spanish ? ", " : "") : ""}{user?.speaks_some_english ? "Speaks Some English" + (user?.speaks_some_spanish || user?.comprehends_english || user?.comprehends_spanish || user?.comprehends_some_english || user?.comprehends_some_spanish ? "," : "") : ""}{user?.speaks_some_spanish ? "Speaks Some Spanish" + (user?.comprehends_english || user?.comprehends_spanish || user?.comprehends_some_english || user?.comprehends_some_spanish ? ", " : "") : ""}{user?.comprehends_english ? "Comprehends English" + (user?.comprehends_spanish || user?.comprehends_some_english || user?.comprehends_some_spanish ? ", " : "") : ""}{user?.comprehends_spanish ? "Comprehends Spanish" + (user?.comprehends_some_english || user?.comprehends_some_spanish ? ", " : "") : ""}{user?.comprehends_some_english ? "Comprehends Some English" + (user?.comprehends_some_spanish ? ", " : "") : ""}{user?.comprehends_some_spanish ? "Comprehends Some Spanish" : ""}</div>
      <div>{user.employee_trades?.join(', ')}</div>
      <div>{SignupStatus[user.profile?.signup_status] || user.profile?.signup_status}</div>
      <div>{user.last_login ? dayjs(user.last_login).format('M/D/YYYY') : '-'}</div>
      <div>{user.date_joined ? dayjs(user.date_joined).format('M/D/YYYY') : '-'}</div>
    </Link>
  )
}

export function MatchDialog({ users, selectedUserIds, onClose }) {
  const [orgId, setOrgId] = useState('')
  const { data: orgs } = useOrgListQuery()

  const save = async () => {
    await api.matches.bulkCreate({ org_id: orgId, user_ids: selectedUserIds })
    onClose(true)
  }

  const selUsers = users.filter(u => selectedUserIds.includes(u.id))

  return (
    <Dialog onClose={() => onClose(false)} open>
      <DialogTitle>Add matches to client's list</DialogTitle>
      <DialogContent style={{ paddingTop: 5 }}>
        <TextField
          id="select-client"
          select
          label="Select client"
          value={orgId}
          onChange={e => setOrgId(e.target.value)}
        >
          {orgs?.length ? (
            orgs.map(org => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={null}>Loading...</MenuItem>
          )}
        </TextField>
        <div style={{ paddingTop: 20, whiteSpace: 'pre-line' }}>
          {selUsers.map(u => '- ' + getFullName(u)).join('\n')}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button onClick={save} disabled={!orgId}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function GroupDialog({ groups, users, selectedUserIds, onClose }) {
  const [group, setGroup] = useState('')
  const [name, setName] = useState('')
  const [showNewGroupInput, setShowNewGroupInput] = useState(false)

  const save = async () => {
    let label
    if (showNewGroupInput) {
      if (groups.includes(name.trim())) {
        toastWarn('That name is in use')
        return
      }
      label = name.trim()
    } else {
      label = group
    }
    await api.users.bulkUpdate({ user_ids: selectedUserIds, data: { admin_label: label } })
    if (showNewGroupInput) {
      onClose(true, label)
    } else {
      onClose(true)
    }
  }

  return (
    <Dialog onClose={() => onClose(false)} open>
      <DialogTitle>Move {selectedUserIds.length} users</DialogTitle>
      <DialogContent style={{ paddingTop: 5, width: 350 }}>
        {showNewGroupInput ? null : (
          <TextField
            id="select-group"
            select
            label="Select Group"
            value={group}
            onChange={e => setGroup(e.target.value)}
            autoFocus
          >
            {groups
              .filter(g => g !== EMPTY)
              .sort()
              .map((g, i) => (
                <MenuItem key={i} value={g}>
                  {g}
                </MenuItem>
              ))}
          </TextField>
        )}
        {showNewGroupInput ? (
          <TextField
            id="new-group"
            label="Group Name"
            value={name}
            onChange={e => setName(e.target.value)}
            style={{ marginTop: 15 }}
          />
        ) : (
          <Button size="small" variant="outlined" onClick={() => setShowNewGroupInput(true)} style={{ marginTop: 15 }}>
            <AddIcon />
            New Group
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button onClick={save} disabled={showNewGroupInput ? !name.trim() : !group}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
