import './FileUploadButton.scss'

import { useRef } from 'react'
import bytes from 'bytes'
import { Button, ButtonProps } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { t } from '../../i18n'
import { ONE_MB } from '../../constants'
import { toast } from '../../events'

type Props = {
  value: File,
  label: String,
  onChange: Function,
  maxSize: Number,
  accept: String,
  buttonProps: ButtonProps
}

export function FileUploadButton(props: Props) {
  const { value, label, maxSize = ONE_MB * 25, accept = 'image/*', buttonProps } = props
  const fileInput = useRef(null)

  const onChange = (e: Event) => {
    const files: FileList = e.target.files
    if (files.length === 1) {
      const file = files[0]
      if (file.size > maxSize) {
        toast(t(`File size must be no more than ${bytes(maxSize)}`))
        return
      }
    }
    props.onChange(e)
  }

  return (
    <div className="FileUploadButton">
      <input ref={fileInput} type="file" onChange={onChange} accept={accept} />
      <Button
        variant="contained"
        startIcon={<FileUploadIcon />}
        onClick={() => fileInput.current.click()}
        {...buttonProps}
      >
        {label || t('Choose file...')}
      </Button>
      <span className="filename">
        {value?.name ? value.name : typeof value === 'string' ? value.split('/').pop() : ''}
      </span>
    </div>
  )
}
