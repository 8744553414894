import { Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { t } from '../../../i18n'
import { useEmployeeSignupContext } from '../../../hooks'

export function Step4({ singleStepMode }) {
  const { user, setUser, submit } = useEmployeeSignupContext()
  const navigate = useNavigate()

  const onChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    _.set(tmp, prop, val)
    setUser(tmp)
  }

  const next = async () => {
    await submit()
  }
  const back = () => {
    if (singleStepMode) {
      navigate('/profile')
    } else {
      navigate(-1)
    }
  }
  
  const isValid = (user: User) => {
    let valid = false
    try {
      valid = user.password.trim().length >= 6 && user.password.trim() === user.password2.trim()
    } catch {
      //
    }
    return valid
  }
  
  return (
    <Dialog
      fullWidth
      open={true}
      className="step4"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Sign Up')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container my={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="text.secondary">
              {t('To create your profile we need you to create a password.  Valid passwords must be at least 8 characters long, contain a number and at least one capital letter.')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container my={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('Password')}
            </Typography>
            <TextField
              autoFocus
              type="password"
              value={user.password}
              onChange={e => onChange('password', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('Password (confirm)')}
            </Typography>
            <TextField
              type="password"
              value={user.password2}
              onChange={e => onChange('password2', e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
        <Grid item xs={1} />
          <Grid item xs={4}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={back}>
              {singleStepMode ? t('Cancel') : t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button disabled={!isValid(user)} fullWidth size="large" color="primary" variant="contained" onClick={next}>
              {singleStepMode ? t('Save') : t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
