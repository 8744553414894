import FooterNew from './common/FooterNew'
import GlobalHeader from './common/HeaderNew'

import './PrivacyPolicy.scss'

export function PrivacyPolicy() {
  return (
    <>
      <GlobalHeader />
      <div className="PrivacyPolicy">
        <h1>Compago's Privacy Policy</h1>
        <h3>
          This Privacy Policy describes, in a comprehensive manner, how Compago uses data you provide to us.&nbsp;&nbsp;Effective Date:&nbsp;&nbsp;May 5th, 2024
        </h3>
        
        <p>
          Compago values the trust our users, customers, and clients place in us when they give us access to their Personal Data. This Privacy Policy describes how we work to maintain that trust and protect that information.
          Our Privacy Policy details how we collect, use, and disclose the Personal and Non-Personal Data we collect from and about you when you access or use our websites, and interactions with us on the phone or in person, or, where permitted by applicable law and in line with this Privacy Policy, that we obtain from publicly available sources or third-party sources.
          Our Sites (the "Sites") covered by this Privacy Policy include the affiliated online and/or mobile websites, applications, services, and software of:
          <ul>
            <li>compagostaffing.com</li>
            <li>compagouniversity.com</li>
          </ul>
        </p>
        <p>
          Information We Collect We collect personal information from job seekers, including, but not limited to:
          <ul>
            <li>Name</li>
            <li>Contact Information</li>
            <li>Employment History</li>
            <li>Videos and Images of the Candidate</li>
          </ul>
        </p>          
        <p>
          Other relevant information provided by the job seeker through our website or during the application process.
          How We Collect Information We collect personal information through:
          <ul>
            <li>Our website, when job seekers submit their applications or contact us</li>
            <li>Interviews conducted by our employees with job seekers</li>
          </ul>
        </p>
        <p>
          How We Use and Store Information We use the collected information to:
          <ul>
            <li>Assess job seekers' qualifications and suitability for employment opportunities</li>
            <li>Communicate with job seekers regarding their applications and potential job opportunities</li>
            <li>Share relevant information with prospective employers</li>
          </ul>          
        </p>
        <p>
          We store the collected information in a secure database, accessible only to authorized employees.
        </p>
        <h3>Information Sharing</h3>
        <p>
          We may share job seekers' personal information, including videos, with prospective employers to facilitate the hiring process. We will only share information with employers who have a legitimate interest in the job seeker's application.
        </p>
        <h3>Data Retention</h3>
        <p>
           We will retain job seekers' personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>
        <h3>Security</h3>
        <p>
          We take reasonable measures to protect the personal information we collect from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
        <h3>Your Rights</h3>
        <p>
          You have the right to access, update, or delete your personal information. If you wish to exercise these rights or have any questions or concerns about our privacy practices, please contact us at info@compagosolutions.com.
          Changes to This Privacy Policy We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of the policy.
          Consent By submitting your personal information to us, you consent to the collection, use, storage, and sharing of your information as described in this Privacy Policy.
        </p>
        <h3>Important Definitions</h3>
        <ul>
          <li>Job Seeker: An individual searching for employment openings, services, or information related to their personal employment or job search</li>
          <li>Employer: Individuals and/or organizations seeking information related to hiring or human resources, seeking Job Seekers, or seeking to make available information regarding employment openings, on their behalf or other's behalf, including but not limited to agencies purchasing for multiple parties and employment agencies</li>
          <li>Personal Data: Any information that relates to an identified or identifiable individual and can include information about how you engage with our Services, such as device information or IP address</li>
          <li>Special Category Data: Any information that relates to an identified or identifiable individual and reveals racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, genetic data, biometric data, data concerning health, sex, or sexual orientation.</li>
          <li>Non-Personal Data: Any information that does not relate to an identified/identifiable specific individual, e.g. data relating solely to a business</li>
          <li>Your Information: Personal and Non-Personal Data that pertains to you</li>
          <li>Controller: The entity which decides how and why your data is processed</li>
          <li>Processor: An entity which processes data in a particular way, at the direction of a controller</li>
          <li>Candidate Profile: All application and contact information stored on your Compago job seeker account, including resume information, assessment results, and answers to screener questions</li>
          <li>Resume: A resume or a resume that you upload yourself</li>
        </ul>
      </div>
      <FooterNew />
    </>
  )
}
