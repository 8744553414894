import { useState, useEffect } from 'react'
import { CircularProgress, Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { t } from '../../../i18n'
import { useEmployeeSignupContext, useTradeListQuery } from '../../../hooks'
import { LOADING } from '../../../constants'

export function Step3b({ singleStepMode }) {
  const _t = t // very weird that I have to do this

  const [enableOther, setEnableOther] = useState(false)
  const [otherText, setOtherText] = useState('')
  const [otherTradeId, setOtherTradeId] = useState(undefined)
  const navigate = useNavigate()

  const { data: trades } = useTradeListQuery()
  const { user, setUser, submit } = useEmployeeSignupContext()

  const next = async () => {
    if (singleStepMode) {
      await submit().then(() => {
        navigate('/profile')
      })
    } else {
      navigate('/register/step-2')
    }
  }
  const back = () => {
    if (singleStepMode) {
      navigate('/profile')
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (trades !== LOADING) {
      const other = displayTrades.find(t => t.name === 'Other')
      const otherText = user?.profile?.trade_info ? user.profile.trade_info[other?.id] : ''
      setOtherTradeId(other?.id)
      if (otherText !== undefined) {
        setEnableOther(other)
        setOtherText(
          _.get(
            user,
            `profile.trade_info[${other.id}]`,
            ''
          )
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trades])

  if (trades === LOADING) {
    return <CircularProgress />
  }

  const displayTrades = trades
    .filter(t => t.display_in_signup === 1 || t.display_in_signup === 3 || t.code === 'OTHER')

  // just need other to be last
  const sortedDisplayTrades = [
    ...displayTrades.filter((t) => t.id !== otherTradeId),
    ...displayTrades.filter((t) => t.id === otherTradeId)
  ]

  const onChangeOtherText = otherText => {
    setOtherText(otherText)
    const u = _.cloneDeep(user)
    const tradeInfo = u.profile.trade_info || {}
    tradeInfo[otherTradeId] = otherText
    _.set(u, 'profile.trade_info', tradeInfo)
    setUser(u)
  }

  const onChange = (tradeId, checked) => {
    const u = _.cloneDeep(user)
    const tradeInfo = u.profile.trade_info || {}
    if (checked) {
      tradeInfo[tradeId] = ''
    } else {
      delete tradeInfo[tradeId]
    }
    _.set(u, 'profile.trade_info', tradeInfo)
    setUser(u)
  }

  return (
    <Dialog
      fullWidth
      open={true}
      className="step3b"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Sign Up')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container mb={2} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('Primary Trade')}
            </Typography>
          </Grid>
          {sortedDisplayTrades.map(t =>
            <Grid item xs={12} key={t.id}>
              <Typography variant="body1" color="text.secondary">
                {t.name !== 'Other' && (
                  <TradeCheckbox
                    tradeName={t.name}
                    tradeId={t.id}
                    selectedTrades={user?.profile?.trade_info}
                    onChange={onChange}
                  />
                )}
                {t.name === 'Other' && (
                  <>
                    <Grid item xs={12}>
                      <TradeCheckbox
                        tradeName={t.name}
                        tradeId={t.id}
                        selectedTrades={user?.profile?.trade_info}
                        onChange={(id, checked) => {
                          setEnableOther(!enableOther);
                          if (!checked) {
                            onChangeOtherText('')
                          }
                          onChange(id, checked)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <TextField
                        id="other"
                        disabled={!enableOther}
                        placeholder={_t('(Optional)')}
                        value={otherText}
                        onChange={e => onChangeOtherText(e.target.value)}
                      />
                    </Grid>
                  </>
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} my={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={back}>
              {singleStepMode ? t('Cancel') : t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={next}>
              {singleStepMode ? t('Save') : t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const TradeCheckbox = ({ tradeName, tradeId, selectedTrades, onChange }) => {
  const [checked, setChecked] = useState(selectedTrades && selectedTrades[tradeId] !== undefined)
  const change = () => {
    setChecked(!checked)
    onChange(tradeId, !checked)
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={change}
        />
      }
      label={tradeName}
    />
  )
}