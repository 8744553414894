import './Step3.scss'

import _ from 'lodash'
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { t } from '../../../../i18n'
import { useEmployeeSignupRoundTwoContext, useTradeListQuery } from '../../../../hooks'
import { useEffect, useState } from 'react'
import { api } from '../../../../api'
import { useNavigate } from 'react-router-dom'
import { LOADING, LocalStorageKey } from '../../../../constants'

export function Step3() {
  const { user, setUser } = useEmployeeSignupRoundTwoContext()
  const [enableOther, setEnableOther] = useState(false)
  const [otherText, setOtherText] = useState('')
  const [otherTradeId, setOtherTradeId] = useState(undefined)

  const navigate = useNavigate()
  const { data: trades } = useTradeListQuery()

  const tradeInfo = user?.profile?.trade_info || {};
  
  let p1 = t('Please tell us what skills you have worked before')
  const p2 = t(
    'Any other experience that we need to know about :'
  )
  const p3 = t(
    "Example: I'm a welder, I have experience in structural and finish welding (Stair railings, hand rails). I have worked on mid level quality homes as well as high end projects including a 10 million custom home in Austin."
  )

  useEffect(() => {
    if (trades !== LOADING) {
      const other = displayTrades.find(t => t.name === 'Other')
      const otherText = user.profile.trade_info ? user.profile.trade_info[other?.id] : ''
      setOtherTradeId(other?.id)
      if (otherText !== undefined) {
        setEnableOther(other)
        setOtherText(
          _.get(
            user,
            `profile.trade_info[${other.id}]`,
            ''
          )
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trades])

  if (trades === LOADING) {
    return <CircularProgress />
  }

  const displayTrades = trades
    .filter(t => t.display_in_signup === 2 || t.display_in_signup === 3 || t.code === 'OTHER')
  
  const back = () => {
    navigate('/signup-round-two/step-2')
  };

  const next = async () => {
    navigate('/signup-round-two/step-4')
  };

  const onChangeOtherText = otherText => {
    setOtherText(otherText)
    const u = _.cloneDeep(user)
    const tradeInfo = u?.profile?.trade_info || {}
    tradeInfo[otherTradeId] = otherText
    _.set(u, 'profile.trade_info', tradeInfo)
    setUser(u)
  }

  const onChange = (tradeId, checked) => {
    const u = _.cloneDeep(user)
    if (tradeId === otherTradeId && checked) {
      setEnableOther(true)
    } else if (tradeId === otherTradeId && !checked) {
      setEnableOther(false)
    }
    const tradeInfo = u?.profile?.trade_info || {}
    if (checked) {
      tradeInfo[tradeId] = ''
    } else {
      delete tradeInfo[tradeId]
    }
    _.set(u, 'profile.trade_info', tradeInfo)
    localStorage.setItem(LocalStorageKey.EmployeeSignup.USER, JSON.stringify(u))
    setUser(u)
  }

  const sortedDisplayTrades = [
    ...trades.filter((t) => t.id !== otherTradeId),
    ...trades.filter((t) => t.id === otherTradeId)
  ]

  return (
    <Dialog
      fullWidth
      open={true}
      className="step3"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Signup - Round Two')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p1}
            </Typography>
          </Grid>
          {sortedDisplayTrades?.map((t, i) => (
            <Grid item xs={12}>
              <Typography variant="body1" key={t.id}>
                {t.name !== 'Other' && (
                  <TradeCheckbox
                    tradeName={t.name}
                    tradeId={t.id}
                    selectedTrades={user.profile.trade_info}
                    onChange={onChange}
                  />
                )}
                {t.name === 'Other' && (
                  <TradeCheckbox
                    tradeName={t.name}
                    tradeId={t.id}
                    selectedTrades={user.profile.trade_info}
                    onChange={(id, checked) => {
                      if (!checked) {
                        onChangeOtherText('')
                      }
                      onChange(id, checked)
                    }}
                  />
                )}
              </Typography>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography variant="body1">
              {p2}
            </Typography>
            <TextField
              disabled={!enableOther}
              id="round_two_skills"
              placeholder={p3}
              value={otherText}
              onChange={e => onChangeOtherText(e.target.value)}
              minRows={4}
              multiline
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button
              fullWidth size="large"
              color="primary"
              variant="contained"
              onClick={back}
            >
              {t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button
              disabled={!Object.keys(tradeInfo).length > 0}
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              onClick={next}
            >
              {t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}


const TradeCheckbox = ({ tradeName, tradeId, selectedTrades, onChange }) => {
  const [checked, setChecked] = useState(selectedTrades && selectedTrades[tradeId] !== undefined)
  const change = () => {
    setChecked(!checked)
    onChange(tradeId, !checked)
  }
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={change}
        />
      }
      label={tradeName}
    />
  )
}