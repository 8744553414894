import { Container, Typography } from "@mui/material";
import React, { useState } from "react";
import compago from "../../../../assets/homepage/compagoLogo.png";
import { t } from "../../../../i18n";

import { GetQuote } from "../../../homepage/GetQuote";
import BuildTeamButton from "../../../common/Button/BuildTeamButton";
import FindWorkButton from "../../../common/Button/FindWorkButton";
import mainVideo from "../../../../assets/compago_university_page/CompagoUShowreelUpdated.mp4";

const CompagoUniversity = () => {
  const [showGetQuote, toggleGetQuote] = useState(false);
  return (
      <div className="universityMainWrap">
        <Container maxWidth="xl" className="university_container">
          <div className="university_container_contain">
            <div className="university_content">
              <img src={compago} alt="" className="compagoLogo" />
              <div className="university-sub-title">
                <Typography variant="h5">
                  {t("We train our team , That what makes us different")}
                </Typography>
              </div>
              <p>
                {t(
                  `Our training is the cornerstone of what makes us different. Designed to strengthen our culture of positivity, teamwork and leadership, our program offers courses that equip our team with the tools and knowledge needed to excel in any work environment.`
                )}
              </p>

              <div className="how-it-works-new-btns">
                <BuildTeamButton />
                <FindWorkButton />
              </div>
            </div>
            <div className="right-img">
            <video className="welcome_video" controls autoPlay loop muted onError={(e) => console.error("Error loading video:", e)}>
              <source src={mainVideo} type="video/mp4;"/>
              Your browser does not support the video tag.
            </video>
            </div>
          </div>
        </Container>
      {showGetQuote ? <GetQuote onClose={() => toggleGetQuote(false)} /> : null}
    </div>
  );
};

export default CompagoUniversity;
