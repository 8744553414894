/* eslint-disable jsx-a11y/alt-text */
import './HomePageNav.scss'

import { useState } from 'react'
import { Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom'
import { t } from '../../i18n'
import logo from '../../assets/logo_transparent_background_blue.png'

export function HomePageNav({ onGetQuoteClick }) {
  const [open, setOpen] = useState(false)
  const lang = localStorage.getItem('lang')

  const onFlagClick = () => {
    if (lang === 'es') {
      localStorage.setItem('lang', 'en')
    } else {
      localStorage.setItem('lang', 'es')
    }
    window.location.reload()
  }

  return (
    <>
      <div className="HomePageNav">
        <div className="homepage-container desktop">
          <div className="left">
            <Link className="logo" to="/">
              <img src={logo} />
            </Link>
          </div>
          <div className="right">
            <ListItemButton component="a" href="/login">
              <ListItemText primary={t('Sign In')} />
            </ListItemButton>
            <IconButton onClick={onFlagClick}>
              <span title={t('toggle between English and Spanish')} className="flag">{lang === 'es' ? '🇺🇸' : '🇲🇽'}</span>
            </IconButton>
            <div className="action-container">
              <Button className="button" title={t('Find talent')} onClick={onGetQuoteClick}>{t('Find talent')}</Button>
              <a className="button" href="/register" title={t('Find work')}>{t('Find work')}</a>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="left">
            <Link className="logo" to="/">
              <img src={logo} />
            </Link>
          </div>
          <div className="right">
            <IconButton onClick={() => setOpen(true)}>
              <MenuIcon sx={{ color: '#eee' }} />
            </IconButton>
          </div>
          <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
            <List>
              {/* <ListItem disablePadding>
                <ListItemButton component="a" href="/post-a-job">
                  <ListItemText primary={t('Post a job')} />
                </ListItemButton>
              </ListItem> */}
              {/* <ListItem disablePadding>
                <ListItemButton component="a" href="/find-candidates">
                  <ListItemText primary={t('Find candidates')} />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton component="a" href="/help">
                  <ListItemText primary={t('Help Center')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="/login">
                  <ListItemText primary={t('Sign In')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={onFlagClick}>
                  <ListItemText primary={lang === 'es' ? '🇺🇸' : '🇲🇽'} />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
        </div>
      </div>
      <div className="HomePageNavBox" />
    </>
  )
}
