import './EmployeeSignupRoundTwo.scss'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { EmployeeSignupRoundTwoContext } from '../../../../context'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Done } from './Done'
import { LocalStorageKey } from '../../../../constants'
import { api } from '../../../../api'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Step5 } from './Step5'
import FooterNew from '../../../common/FooterNew'
import type { User } from '../../../../types'
import { getUserDefaultValues } from '../../../../data'

export function EmployeeSignupRoundTwo() {
  let _userId = localStorage.getItem(LocalStorageKey.EmployeeSignup.USER_ID)
  let _user = useMemo(() => {
    let _user: User = localStorage.getItem(LocalStorageKey.EmployeeSignup.USER)
    if (_user) {
      return JSON.parse(_user)
    } else {
      return getUserDefaultValues()
    }
  }, [])

  const [user, setUser] = useState(
    _.cloneDeep({
      ..._user,
      id: _userId
    })
  )
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()
  const submit = useCallback(async () => {
    try {
      const response = await api.employees.update(user)
      setUser(response)
      const savedUser = await api.employees.completeSignupRoundTwo(user)
      navigate('/signup-round-two/done')
      api.init()
    } catch (err) {
      console.error(err, "error")
      setSaving(false)
    }
  }, [user])

  const employeeSignupContextValue = useMemo(() => {
    return { user, setUser, saving, setSaving, submit }
  }, [user, setUser, saving, setSaving, submit])

  /* useEffect(() => {
    localStorage.setItem(LocalStorageKey.EmployeeSignup.USER, JSON.stringify(user))
  }, [user]) */

  return (
    <EmployeeSignupRoundTwoContext.Provider value={employeeSignupContextValue}>
      <div className="EmployeeSignup">
        <div className="content">
          <Routes>
            <Route index element={<Navigate to="step-1" />} />
            <Route path="step-1" element={<Step1 />} />
            <Route path="step-2" element={<Step2 />} />
            <Route path="step-3" element={<Step3 />} />
            <Route path="step-4" element={<Step4 />} />
            <Route path="step-5" element={<Step5 />} />
            <Route path="done" element={<Done />} />
          </Routes>
        </div>
      </div>
      <FooterNew />
    </EmployeeSignupRoundTwoContext.Provider>

  )
}
