import axios from 'axios'
import { toIsoDate } from '../util/dateUtil'
import { Resource } from './base'

export class UserProfileResource extends Resource {
  constructor() {
    super('user_profiles')
    this.addNote = this._createEndpoint({ endpointName: 'note', detail: true })
    this.addBackgroundCheck = (...args) => this._wrapWithToastCalls(() => this._addBackgroundCheck(...args))

    this._dateFields = ['start_date', 'current_job_start_date', 'previous_job_start_date', 'previous_job_end_date']
    this._jsonFields = ['can_travel', 'language', 'previous_jobs', 'references']
    this._fileFields = [
      'profile_pic',
      'profile_vid',
      'resume',
      'background_check',
      'example_pic_1',
      'example_pic_2',
      'example_pic_3',
      'example_pic_4',
      'example_pic_5'
    ]
  }

  async update(profile: UserProfile) {
        const formData = new FormData()
    for (const fieldName of this._fileFields) {
      const value = profile[fieldName]
      if (value != null && typeof value !== 'string') {
        formData.append(fieldName, value)
      }
    }

    let data = {}
    for (const [k, v] of Object.entries(profile.data)) {
      if (this._dateFields.includes(k)) {
        if (v) {
          data[k] = toIsoDate(v)
        }
      } else {
        data[k] = v
      }
    }
    formData.append('profile', JSON.stringify(profile))
    // formData.append('data', JSON.stringify(data))
    const res = await axios.put(`/${this.name}/${profile.id}`, formData)
    return res.data
  }

  async _addBackgroundCheck(id, file) {
    const formData = new FormData()
    formData.append('file', file)
    const res = await axios.post(`/${this.name}/${id}/background_check`, formData)
    return res.data
  }
}

export const userProfiles = new UserProfileResource()
