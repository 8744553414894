import './DataForm.scss'

import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  TextField
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { t } from '../../i18n'

export function DataForm(props) {
  const [show, setShow] = useState(false)

  const onSubmit = (e: Event) => {
    e.preventDefault()
    props.onSubmit(e.target)
  }

  const renderField = (field, i) => {
    let { name, value, checked, label, type, options } = field

    let fieldProps = { name }
    if (type === 'currency') {
      fieldProps.InputProps = { startAdornment: <InputAdornment position="start">$</InputAdornment> }
    } else if (type === 'password') {
      fieldProps.type = show ? 'text' : 'password'
      fieldProps.InputProps = {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShow(!show)}
              onMouseDown={e => e.preventDefault()}
              edge="end"
            >
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }
    }

    let ui
    if (type === 'checkbox') {
      ui = <FormControlLabel name={name} label={label} control={<Checkbox defaultChecked={checked} />} />
    } else if (type === 'checkboxgroup') {
      ui = (
        <FormControl>
          <FormLabel>{label}</FormLabel>
          {options.map(([value, label]) => (
            <FormGroup key={value}>
              <FormControlLabel
                name={name + '.' + value}
                label={label}
                control={<Checkbox defaultChecked={checked} />}
              />
            </FormGroup>
          ))}
        </FormControl>
      )
    } else if (type === 'radio') {
      ui = <FormControlLabel name={name} label={label} control={<Radio defaultChecked={checked} />} />
    } else {
      ui = <TextField label={t(label)} {...fieldProps} defaultValue={value} />
    }

    return (
      <div className="field" key={i}>
        {ui}
      </div>
    )
  }

  return (
    <form className="DataForm" onSubmit={onSubmit}>
      {props.fields.map(renderField)}
      <Button type="submit" variant="contained" fullWidth>
        Save
      </Button>
    </form>
  )
}
