import axios from 'axios'
import { Resource } from './base'
import { userProfiles } from './UserProfileResource'
import { users } from './UserResource'

export class EmployeeResource extends Resource {
  constructor() {
    super('employees')
    this.approveForRoundTwo = this._createEndpoint({ endpointName: 'approve_for_round_2', detail: true })
    this.reject = this._createEndpoint({ endpointName: 'reject', detail: true })
    this.updateWorkHours = this._createEndpoint({ endpointName: 'work_hours', httpVerb: 'put', detail: true })
    this.activate = this._createEndpoint({ endpointName: 'activate', wrap: false })
    this.completeSignupRoundTwo = this._createEndpoint({
      endpointName: 'complete_signup_round_two',
      detail: true,
      wrap: false
    })
    this.completeSignupRoundThree = this._createEndpoint({
      endpointName: 'complete_signup_round_three',
      detail: true,
      wrap: false
    })
  }

  async create(user: User) {
    const formData = new FormData()
    const { profile } = user
    delete user.profile
    formData.append('user', JSON.stringify(user))
    formData.append('profile', JSON.stringify(profile))
    if (profile.profile_pic) formData.append('profile_pic', profile.profile_pic)
    if (profile.profile_vid) formData.append('profile_vid', profile.profile_vid)
    if (profile.resume) formData.append('resume', profile.resume)
    if (profile.background_check) formData.append('background_check', profile.background_check)

    const res = await axios.post(`/${this.name}`, formData)
    return res.data
  }

  async update(user) {
    const profile = { ...user.profile };
    delete user.profile
    const savedUser = await users.update(user)
    const savedProfile = await userProfiles.update(profile)
    savedUser.profile = savedProfile
    return savedUser
  }

  async generatePdf(user) {
    const iframe = document.createElement('iframe')
    iframe.src = `${axios.defaults.baseURL}/${this.name}/${user.id}/generate_pdf`
    await axios.get(`/${this.name}/${user.id}/generate_pdf`, user)
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
  }
}

export const employees = new EmployeeResource()
