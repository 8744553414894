import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import vector1 from "../../../../assets/about_page/mission_border_line.png";
import img1 from "../../../../assets/services_page/custom-card-1.png";
import img2 from "../../../../assets/services_page/custom-card-2.png";
import img3 from "../../../../assets/services_page/custom-card-3.png";
import Icon1 from "../../../../assets/services_page/custom-icon-1.png";
import Icon2 from "../../../../assets/services_page/custom-icon-2.png";
import Icon3 from "../../../../assets/services_page/custom-icon-3.png";

import { t } from "../../../../i18n";
import ServiceCard from "../../../common/ServiceCard";
const CustomSolutions = () => {
  return (
    <div className="custom-solution">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="custom-solution-heading">
              <img src={vector1} alt="" />
              <Typography variant="h2" gutterBottom>
                {t("Custom Building Solutions")}
              </Typography>
              <div className="custom-solution-sub-heading">
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>
                  {t(
                    "We manage your project from start to finish. Our high standards ensure high quality. Let us guide your next project to success."
                  )}
                </Typography>
                {/* <Typography variant="subtitle1">
                  {t("environments.")}
                </Typography> */}
              </div>
            </Box>
          </Grid>
        </Grid>
        <div className="blue-highlight"></div>
        <Grid container spacing={3} className="relative margin-top-2">
          <Grid item xs={12} md={4}>
            <ServiceCard
              showUserImg2={Icon1}
              title={t("Commercial Construction")}
              cardImage={img1}
              content={t(
                "New builds, remodels and restorations completed by experienced teams. We focus on sustainable and resilient community construction."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceCard
              showUserImg2={Icon2}
              title={t(`Residential Construction`)}
              cardImage={img2}
              content={t(
                "Custom luxury homes built to meet client’s unique specifications. Unparalleled craftsmanship and attention to detail."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ServiceCard
              showUserImg2={Icon3}
              title={t("General Construction")}
              cardImage={img3}
              content={t(
                `Diverse construction projects executed with a focus on lasting quality and client satisfaction.`
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CustomSolutions;
