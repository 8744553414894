import './Users.scss'

import { CircularProgress, Dialog, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { useQuery } from '../../../hooks'
import { LOADING } from '../../../constants'

export function ChangeViewDialog({ onClose }) {
  const { data: users } = useQuery('users', 'orgAdmins')

  const onClick = user => {
    window.open(window.location.origin + `?viewAs=employer&id=${user.id}`, '_blank')
  }

  return (
    <Dialog open onClose={onClose}>
      <div style={{ padding: 14, fontSize: 20 }}>Select client</div>
      <List>
        {users === LOADING ? (
          <CircularProgress style={{ margin: 14 }} />
        ) : (
          users.map(user => (
            <ListItem key={user.id} disablePadding>
              <ListItemButton onClick={() => onClick(user)}>
                <ListItemText>{user.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>
    </Dialog>
  )
}
