import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import mission from "../../../../assets/about_page/mission_border_line.png";
import {
  default as img1_grey,
  default as img1_white,
} from "../../../../assets/compago_university_page/img_1.png";
import {
  default as img2_grey,
  default as img2_white,
} from "../../../../assets/compago_university_page/img_2.png";
import {
  default as img3_grey,
  default as img3_white,
} from "../../../../assets/compago_university_page/img_3.png";
import {
  default as img4_grey,
  default as img4_white,
} from "../../../../assets/compago_university_page/img_4.png";
import { t } from "../../../../i18n";
import { Link } from 'react-router-dom'
import ButtonCustom from "../../../common/Button";

const UniversitySection = () => {
  return (
    <div className="universitySection">
      <Container maxWidth="xl" className="university_container">
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} md={8} textAlign={"center"}>
            <Box className="universitySection-heading">
              <img src={mission} alt="" className="mission" />
              <Typography variant="h2" gutterBottom>
                {t("Foundation Courses")}
              </Typography>
              <div className="universitySection-sub-heading">
                <Typography variant="subtitle1">
                  {t(
                    `Every new team member is required to take these courses. This comprehensive curriculum is structured around key areas critical to personal and professional development, providing you with a more competent workforce.`
                  )}
                </Typography>
              </div>
              <Typography variant="h4" gutterBottom>
                {t("Example Courses")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="margin-top-2">
          <Grid item xs={12} md={6} className="margin-bottom-1">
            <UniversitySectionCard
              // title={t("Kickstart your journey")}
              // imgGrey={img1_grey}
              // imgWhite={img1_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239564-kickstarting-your-journey-with-compago"
              title={t("Jobsite Safety")}
              imgGrey={img4_grey}
              imgWhite={img4_white}
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <UniversitySectionCard
              title={t("Welcome to Compago, embracing our culture")}
              imgGrey={img2_grey}
              imgWhite={img2_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239033-compago-university-elevating-our-team"
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <UniversitySectionCard
              title={t("How to excel in any role")}
              imgGrey={img3_grey}
              imgWhite={img3_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239617-how-to-excel-in-any-role-at-compago-and-beyond"
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <UniversitySectionCard
              title={t("Extreme Ownership and Responsibilities")}
              imgGrey={img1_grey}
              imgWhite={img1_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239674-embracing-extreme-ownership-in-everyday-work"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default UniversitySection;

export const UniversitySectionCard = ({ title, imgGrey, imgWhite, accessLink }) => (
  <Card className="universitySection-card">
    <Box className="universitySection-icon-element">
      <span className="imgGrey">
        {" "}
        <img src={imgGrey} alt="" />
      </span>
      <span className="imgWhite_overlay"></span>
    </Box>
    <Box className="universitySection-content-element">
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Link to={accessLink} target="_blank">
        <ButtonCustom variant="contained" className="custom-btn-outline">
          {t("View Course")}
        </ButtonCustom>
      </Link>
    </Box>
  </Card>
);
