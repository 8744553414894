import util from 'util'
import debug from 'debug'
import { emitter } from './events'
import { api } from './api'

let uid
let browserLogging = true

export async function initLogging() {
  const { log } = new Logger('app:logging')
  uid = localStorage.getItem('uid')
  if (!uid) {
    uid = `anon_${Date.now()}`
    localStorage.setItem('uid', uid)
  }

  log('browserInfo=%s', getBrowserInfo())

  const updateUid = u => {
    if (!u) return
    // eslint-disable-next-line eqeqeq
    if (uid == u.id) return
    log('old uid=%s new uid=%s', uid, u.id)
    uid = u.id
    localStorage.setItem('uid', u.id)
  }

  emitter.on('session.user', updateUid)
  emitter.on('EmployeeSignup.user.created', updateUid)
  emitter.on('EmployeeSignup.user.activated', updateUid)

  try {
    browserLogging = await api.settings.get('browser_logging?type=boolean')
  } catch (err) {
    browserLogging = true
  }
}

function getBrowserInfo() {
  const keys = [
    'userAgent',
    'appCodeName',
    'appName',
    'appVersion',
    'cookieEnabled',
    'deviceMemory',
    'language',
    'maxTouchPoints',
    'platform',
    'product',
    'productSub',
    'vendor'
  ]

  const jsonKeys = ['userAgentData', 'languages']

  let rv = []

  rv.push(`windowSize=${window.innerWidth}x${window.innerHeight}`)

  for (const k of keys) {
    try {
      rv.push(k + '=' + window.navigator[k])
    } catch {
      // no op
    }
  }

  for (const k of jsonKeys) {
    try {
      rv.push(k + '=' + JSON.stringify(window.navigator[k]))
    } catch {
      // no op
    }
  }

  return rv.join('\n')
}

export class Logger {
  nsp: String

  constructor(nsp: String) {
    this.nsp = nsp
    this.log = this.log.bind(this)
    this._debug = debug(nsp)
  }

  log(...args) {
    this._debug(...args)
    const arg0 = `[${uid}] [${this.nsp}] ${args[0]}`
    args[0] = arg0
    const line = util.format(...args)
    this._post(line)
  }

  async _post(content) {
    if (!browserLogging) return
    try {
      await api.logEntries.create({ content })
    } catch (err) {
      // ignore
    }
  }
}
