import { Container } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import vector1 from "../../../assets/about_page/mission_border_line.png";
import img1 from "../../../assets/homepage/solutions/img1.jpg";
import img2 from "../../../assets/homepage/solutions/img2.png";
import img3 from "../../../assets/homepage/solutions/img3.jpg";
import { t } from "../../../i18n";

const SliderCard = ({ image, title, description }) => {
  return (
    <div className="cardWrap">
      <img src={image} alt="" className="cardImg" />
      <div className="content">
        <div className="title" style={{ marginBottom: "10px" }}>
          {title}
        </div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
};

const Scalable = () => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const swipeRef = useRef();

  useEffect(() => {
    if (swipeRef.current && swipeRef.current.swiper) {
      swipeRef.current.swiper.slideTo(sliderIndex);
    }
  }, [sliderIndex]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 567);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="scalableMainWrap"
      // style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="blue-highlight"></div>
      <Container maxWidth="xl" className="scalableContainer">
        <div className="contentWrap">
          <img src={vector1} alt="" className="mission" />

          <div className="title">{t("Scalable Solutions")}</div>
          <div className="description">
            {t("Compago helps your business grow.")} <br />
            <br />
            {t(
              `Let our team help you take on more work. We go a step further by training and investing in all our team members. From entry-level laborers to seasoned professionals. We ensure that you receive the highest-performing individuals.`
            )}
          </div>
        </div>
        <div className="slickWrap">
          <SliderCard
            image={img1}
            title={t("Qualified Help When and Where You Need It")}
            description={t(
              "We have teams across many US zip codes, just let us know who you need."
            )}
          />

          <SliderCard
            image={img2}
            title={t("Train Higher Performance Teams")}
            description={t(
              `From training new laborers to enhancing seasoned veterans, Our Compago University makes your project more efficient and profitable.`
            )}
          />

          <SliderCard
            image={img3}
            title={t(`Let Us Manage Your Project`)}
            description={t(
              `Want to entrust your project to the pros? Ask about our turnkey solutions. We handle everything from start to finish.`
            )}
          />
        </div>
      </Container>
    </div>
  );
};

export default Scalable;
