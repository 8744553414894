import './CompagoWorks.scss'

import { Component } from 'react'
import { STATIC3_URL, mobileBreakpoint } from '../../constants'

export class CompagoWorks extends Component {
  render() {
    return (
      <div className="CompagoWorks">
        { window.innerWidth > mobileBreakpoint && (
          <div className="desktop">
            <div className={"video-container"}>
              <video autoPlay muted loop playsInline="" className="hero-video">
                <source src={`${STATIC3_URL}/compago-work-subtitled.mp4`} type="video/mp4" />
                Your browser does not support the video tag
              </video>
            </div>
          </div>
      )}
      { window.innerWidth <= mobileBreakpoint && (
        <div className="mobile">
          <div className={"video-container"}>
            <video autoPlay muted loop playsInline className="hero-video">
              <source src={`${STATIC3_URL}/compago-work-subtitled-mobile.mp4`} type="video/mp4" />
              Your browser does not support the video tag
            </video>
          </div>
        </div>
      )}
      </div>
    )
  }
}
