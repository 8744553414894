import axios from 'axios'
import { Resource } from './base'

export class ExportUsersResource extends Resource {
  constructor() {
    super('export_users')
    this.bulkExport = this._createEndpoint({ endpointName: 'generate_csv' })
  }

  async _downloadUsersData() {
    const res = await axios.get(`/${this.name}/generate_csv`)

    if (res.data?.csv_path) {
      const csvRes = await axios.get(res.data.csv_path, { responseType: 'blob' })
      const url = window.URL.createObjectURL(new Blob([csvRes.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'users_data.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()      
    }
  }
}

export const exportUsers = new ExportUsersResource()
