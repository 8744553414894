import Emitter from 'events'

class AppEmitter extends Emitter {
  emit(eventName, ...args) {
    console.log('[AppEmitter.emit] eventName=' + eventName + ' args=', args)
    return super.emit(eventName, ...args)
  }
}

export const emitter = new AppEmitter()

export const toast = msg => emitter.emit('toast', msg)
export const toastSuccess = msg => emitter.emit('toast.success', [msg, 'success'])
export const toastWarn = msg => emitter.emit('toast.warn', [msg, 'warning'])
