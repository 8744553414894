export function imageToDataUrl(src: String): Promise<string> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      canvas.width = img.naturalWidth
      canvas.height = img.naturalHeight
      ctx.drawImage(img, 0, 0)
      const dataUrl = canvas.toDataURL()
      resolve(dataUrl)
    }

    img.onerror = err => {
      reject(err)
    }

    img.src = src
  })
}
