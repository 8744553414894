import './Done.scss'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { t } from '../../../../i18n'
import { useNavigate } from 'react-router-dom'
import noddinggif from "../../../../assets/nodding_man.gif"
import axios from 'axios'
export function Done() {
  const navigate = useNavigate()
  const done = async () => {
    await axios.post('/logout')
    navigate('/')
    window.location.reload()
  }
  return (
    <Dialog
      fullWidth
      open={true}
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Signup - Round Two')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color="text.secondary" align='center'>
              {t('Great job!')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="text.secondary" align='center'>
              {t('Your information has been saved. We will reach out soon.')}
            </Typography>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={4} />
          <Grid item xs={4} className="spread-buttons">
            <Button fullWidth size="large" color="primary" variant="contained" onClick={done}>
              {t('Close')}
            </Button>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
