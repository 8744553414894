import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import missonline from "../../../assets/about_page/mission_border_line.png";
import CardImg1 from "../../../assets/homepage/Card-1.jpg";
import vector1 from "../../../assets/homepage/vector1.png";
import { t } from "../../../i18n";
import CompagoCard from "../../common/CompagoCard";
import CardImg2 from "./../../../assets/homepage/Card-2.png";
import CardImg3 from "./../../../assets/homepage/Card-3.jpg";

const Professionals = () => {
  return (
    <div className="professional">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="professional-heading">
              <img src={missonline} alt="" className="mission" />
              <Typography variant="h2" gutterBottom>
                {t("More Efficient, Higher Quality Teams")}
              </Typography>
              <div className="professional-sub-heading">
                <Typography variant="subtitle1">
                  {t(
                    `Compago's team of veteran construction professionals knows the end result of a project is only as good as the people who perform the work. We take the time to vet our high-quality team and then educate them before they start work on your jobsite.`
                  )}
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
        <div className="blue-highlight"></div>
        <Grid container spacing={3} className="relative margin-top-2">
          <Grid item xs={12} md={4}>
            <CompagoCard
              title={t("We Create a Better Culture")}
              cardImage={CardImg1}
              content={t(
                `At Compago, we help create the culture. It's the little things—sharing a smile, stopping to help another worker,  taking the time to pick up trash on the job site.`
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CompagoCard
              title={t(`Thousands of Workers Placed`)}
              cardImage={CardImg2}
              content={t(
                "We invest in the success of our team members. We value long-term relationships, which creates willing workers with great attitudes."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CompagoCard
              title={t("Higher Quality Less Liability")}
              cardImage={CardImg3}
              content={t(
                "If the team member we send isn’t the right fit, let us know and we will replace them immediately."
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Professionals;
