import "./EmployeeDashboard.scss";

import { Navigate, Route, Routes } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { LinkTabs, LinkTab } from "../../common";
import { t } from "../../../i18n";
import { Profile } from "./Profile";
import { api } from "../../../api";
import { EmployeeSignupRoundTwo } from "../../signup/employee/round-two/EmployeeSignupRoundTwo";

export function EmployeeDashboard() {
  return (
    <>
      <Dashboard />
    </>
  );
}

function Dashboard() {
  if (!api.user) {
    return <CircularProgress />;
  }

  return (
    <div className="EmployeeDashboard">
      <LinkTabs>
        <LinkTab href="/home" value="/home" label="Home" />
        <LinkTab href="/profile" value="/profile" label="Edit Profile" />
      </LinkTabs>
      <Routes>
        <Route index element={<Navigate to="home" />} />
        <Route path="home/*" element={<HomeTab />} />
        <Route path="profile/*" element={<Profile />} />
        <Route path="/signup-round-two/*" element={<EmployeeSignupRoundTwo />} />
        <Route path="*" element={<Navigate to="home" />} />
      </Routes>
    </div>
  );
}

function HomeTab() {
  const user = api.user;
  const signupStatus = user?.profile?.signup_status;
  if (signupStatus === "APPROVED_FOR_ROUND_2") {
    return (
      <Routes>
        <Route index element={<Navigate to="/signup-round-two" />} />
        {/* <Route path="/signup-round-two/*" element={<EmployeeSignupRoundTwo />} /> */}
      </Routes>
    );
  }

  // if (signupStatus === 'APPROVED_FOR_ROUND_3') {
  //   return (
  //     <Routes>
  //       <Route index element={<Navigate to="signup-round-three" />} />
  //       <Route path="signup-round-three/*" element={<EmployeeSignupRoundThree />} />
  //     </Routes>
  //   )
  // }

  const msgs = {
    COMPLETED_ROUND_1: t(
      "Congrats on completing Round One! We'll send you an email when we're ready for you to start Round Two."
    ),
  };
  const msg =
    msgs[user.profile.signup_status] || t("You don't have any to-dos");

  return (
    <div className="HomeTab">
      <h2>Hi {user.first_name}</h2>
      <div className="signup-status">
        <div className="left">
          <CheckIcon style={{ color: "#00D100" }} />
        </div>
        <div className="right">{msg}</div>
      </div>
    </div>
  );
}
