import dayjs from 'dayjs'
import _ from 'lodash'
import type { UserProfile } from '../types'
import { toIsoDate } from '../util/dateUtil'

const imgFields = [
  'profile_pic',
  'profile_vid',
  'resume',
  'background_check',
  'example_pic_1',
  'example_pic_2',
  'example_pic_3',
  'example_pic_4',
  'example_pic_5'
]

export function validateUserProfile(userProfile: UserProfile) {
  const rv: UserProfile = _.cloneDeep(userProfile)
  for (const imgField of imgFields) {
    rv[imgField] = userProfile[imgField]
  }

  for (const imgField of imgFields) {
    if (stringish(rv[imgField])) delete rv[imgField]
  }

  if (rv.data) {
    if (dayjs(rv.data.current_job_start_date).isValid())
      rv.data.current_job_start_date = toIsoDate(rv.data.current_job_start_date)
    if (dayjs(rv.data.previous_job_start_date).isValid())
      rv.data.previous_job_start_date = toIsoDate(rv.data.previous_job_start_date)
    if (dayjs(rv.data.previous_job_end_date).isValid())
      rv.data.previous_job_end_date = toIsoDate(rv.data.previous_job_end_date)
  }

  return rv
}

function stringish(value) {
  return !value || typeof value === 'string'
}
