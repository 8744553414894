import React from "react";
import GroundWork from "./GroundWork";
import University from "./University";
import UniversitySection from "./UniversitySection";

const CompagoUniversity = () => {
  return (
    <>
      <University />
      {/* <Welcome /> */}
      <UniversitySection />
      <GroundWork />
    </>
  );
};

export default CompagoUniversity;
