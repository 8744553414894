import './Help.scss'

import { Footer } from './common/Footer'
import { HomePageNav } from './homepage/HomePageNav'
import { t } from '../i18n'

export function Help() {
  return (
    <>
      <HomePageNav />
      <div className="Help">
        <div className="container">
          <h1>{t('Help')}</h1>
          <div className="main">{t('Coming soon')}</div>
        </div>
      </div>
      <Footer />
    </>
  )
}
