import { api } from './api'
import { emitter } from './events'

export const i18n = {
  async init() {
    emitter.on('language.changed', this.onLanguageChanged)
    const lang = localStorage.getItem('lang')
    let labels
    if (lang === 'es') {
      labels = await api.labels.get(lang)
      emitter.emit('labels.changed')
    }
    this.labels = labels
    if (this.labels) {
      this.lowercaseLabels = {}
      for (const [k, v] of Object.entries(this.labels)) {
        this.lowercaseLabels[k.toLowerCase()] = v
      }
    }
  },

  onLanguageChanged(lang) {
    localStorage.setItem('lang', lang)
    window.location.reload()
  },

  t(k) {
    return this.labels?.[k] || this.lowercaseLabels?.[k.toLowerCase()] || k
  }
}

export const t = i18n.t.bind(i18n)
