/* eslint-disable jsx-a11y/alt-text */
import './Footer.scss'

import { HomePageFooter } from '../homepage/HomePageFooter'
import logo from '../../assets/logo_transparent_background_blue.png'

export function Footer() {
  return <HomePageFooter />
}

export function Footer2() {
  return (
    <div className="Footer2">
      <div className="homepage-container">
        <div className="row-1">
          <div className="logo">
            =XYZ<img src={logo} />
          </div>
        </div>
      </div>
    </div>
  )
}
