import { Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { t } from '../../../i18n'
import { useEmployeeSignupContext } from '../../../hooks'

export function Step1() {
  const { user, setUser } = useEmployeeSignupContext()
  const { referred_by } = user?.profile?.data || {}
  const navigate = useNavigate()
  
  const continueGenLaborTrack = () => {
    navigate('/register/step-2')
  };
  
  const skilledLaborTrack = () => {
    navigate('/register/step-2b')
  };

  const onChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    _.set(tmp, prop, val)
    setUser(tmp)
  }

  return (
    <Dialog
      fullWidth
      open={true}
      className="step1"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Looking for Work')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container my={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              {t('Did someone refer you?')}
            </Typography>
            <TextField
              autoFocus
              id="referredBy"
              placeholder={t('(Optional)')}
              value={referred_by}
              onChange={e => onChange('profile.data.referred_by', e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={2}/>
          <Grid item xs={8}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={continueGenLaborTrack}>
              {t('Next')}
            </Button>
          </Grid>
          {/* <Grid item xs={2}/>
          <Grid item xs={2}/>
          <Grid item xs={8}>
            <Button fullWidth size="large" color="primary" variant="contained" onClick={skilledLaborTrack}>
              {t('Skilled Trade with Experience')}
            </Button>
          </Grid> */}
          <Grid item xs={2}/>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
