import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import headLine from "../../../assets/about_page/mission_border_line.png";
import compago from "../../../assets/homepage/compagoLogo.png";
import img1_grey from "../../../assets/homepage/courses/img1-grey.png";
import img1_white from "../../../assets/homepage/courses/img1-white.png";
import img2_grey from "../../../assets/homepage/courses/img2-grey.png";
import img2_white from "../../../assets/homepage/courses/img2-white.png";
import img3_grey from "../../../assets/homepage/courses/img3-grey.png";
import img3_white from "../../../assets/homepage/courses/img3-white.png";
import img4_grey from "../../../assets/homepage/courses/img4-grey.png";
import img4_white from "../../../assets/homepage/courses/img4-white.png";
import vector2 from "../../../assets/homepage/vector2.png";
import { t } from "../../../i18n";
import ButtonCustom from "../../common/Button";
import { Link } from 'react-router-dom'

const CompagoUniversitySection = () => {
  return (
    <div className="university">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="university-heading">
              <img src={headLine} alt="" className="mission" />
              <Typography variant="h2" gutterBottom>
                <img src={compago} alt="" className="compagoLogo" />
              </Typography>
              <div className="university-sub-title">
                <Typography variant="h5">
                  {t("We train our team , That what makes us different")}
                </Typography>
              </div>

              <div className="university-sub-heading">
                <Typography variant="subtitle1">
                  {t(
                    `We provide our team members the training to perform far beyond the industry standard. This translates into workers with great attitudes and saves our partners money.`
                  )}
                </Typography>
                <Typography variant="subtitle1">
                  {t(
                    `Everyone on our Compago team goes through our digital university to learn to be an effective team member, help create the culture and maintain a great attitude day after day.`
                  )}
                </Typography>
              </div>
              <Typography variant="h6" gutterBottom className="sub-heading">
                {t("Examples of Our Core Courses")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="margin-top-2">
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <UniversityCard
              title={t("Staying Safe On Job Sites")}
              imgGrey={img2_grey}
              imgWhite={img2_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239703-smartphone-etiquette-on-the-jobsite-balancing-connectivity-and-productivity"
              btn={t("View Course")}
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            <UniversityCard
              title={t("Cultivating A Vibrant Work Culture")}
              imgGrey={img1_grey}
              imgWhite={img1_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/52470504-welcome-to-compago-cultivating-a-vibrant-work-culture"
              btn={t("View Course")}
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <UniversityCard
              title={t("How To Excel In Any Role")}
              imgGrey={img3_grey}
              imgWhite={img3_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239617-how-to-excel-in-any-role-at-compago-and-beyond"
              btn={t("View Course")}
            />
          </Grid>
          <Grid item xs={12} md={6} className="margin-bottom-1">
            {" "}
            <UniversityCard
              title={t("Extreme Ownership Of Responsibility")}
              imgGrey={img4_grey}
              imgWhite={img4_white}
              accessLink="https://compagosolutions.thinkific.com/courses/take/compago-english-course/lessons/53239674-embracing-extreme-ownership-in-everyday-work"
              btn={t("View Course")}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CompagoUniversitySection;

export const UniversityCard = ({ title, imgGrey, imgWhite, accessLink, btn }) => (
  <Card className="university-card">
    <Box className="university-icon-element">
      <span className="imgGrey">
        {" "}
        <img src={imgGrey} alt="" />
      </span>
      <span className="imgWhite">
        <img src={imgWhite} alt="" />
      </span>
    </Box>
    <Box className="university-content-element">
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {btn && (
        <Link to={accessLink} target="_blank"><ButtonCustom variant="contained" className="custom-btn-outline">
          {btn}
        </ButtonCustom></Link>
      )}
    </Box>
  </Card>
);
