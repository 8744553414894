import React from "react";

const MUIInput = ({
  label,
  id,
  name,
  value,
  onChange,
  error,
  helperText,
  fullWidth,
  multiline,
  type,
  rows = 1,
  ...rest
}) => {
  return (
    <div class="form__group">
      <input
        placeholder={label}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        {...rest}
      />

      <label htmlFor={name} className="form__label">
        {label}
      </label>
    </div>
  );
};

export default MUIInput;
