import './HomePageHero.scss'

import { Component } from 'react'
import _ from 'lodash'
import { Button } from '@mui/material'
import classNames from 'classnames'
import { t } from '../../i18n'
import { Link } from 'react-router-dom'
import { STATIC3_URL } from '../../constants'

const mobileBreakpoint = 940

export class HomePageHero extends Component {
  targetScrollTop = null
  state = {
    sticky: false
  }
  
  componentDidMount() {
    this.btn = document.getElementById('heroApplyNow')
    document.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll = e => {
    //this.onScrollDebounced(e.target.scrollTop)
  }

  onScrollDebounced = _.debounce(scrollTop => {
    const { sticky } = this.state
    const { bottom } = this.btn.getBoundingClientRect()
    if (bottom < 0 && !sticky) {
      this.setState({ sticky: true })
    } else if (bottom >= 0 && sticky) {
      this.setState({ sticky: false })
    }
  }, 50)

  render() {
    const { noImage, onGetQuoteClick } = this.props
    const { sticky } = this.state

    return (
      <div className={classNames('HomePageHero', noImage)}>
        { window.innerWidth > mobileBreakpoint && (
          <div className="main desktop">
          <section id={"hero-section"}>
            <div className={"video-container"}>
              <video autoPlay muted loop playsInline="" className="hero-video">
                <source src={`${STATIC3_URL}/compago-work.mp4`} type="video/mp4" />
                Your browser does not support the video tag
              </video>
            </div>
            <div className={"overlay"}></div>
            <div className={"section-text"}>
              <h1>{t('A NEW WAY TO WORK')}</h1>
              <div className={"specifics"}>
                <p>{t('Compago connects your organization with a network of high quality tradesmen to meet your specific needs.')}</p>
                <div className={"next-steps"}>
                  <Button className="button" title={t('Find talent')} onClick={onGetQuoteClick}>{t('Find talent')}</Button>
                  <a className={"button"} href="/register" title={t('Find work')}>{t('Find work')}</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      { window.innerWidth <= mobileBreakpoint && (
        <div className="main mobile">
          <section id={"hero-section"}>
            <div className={"section-text"}>
              <h1>{t('A NEW WAY TO WORK')}</h1>
              <div className={"specifics"}>
                <p>{t('Compago connects your organization with a network of high quality tradesmen to meet your specific needs.')}</p>
                <div className={"next-steps"}>
                  <Button className="button" title={t('Find talent')} onClick={onGetQuoteClick}>{t('Find talent')}</Button>
                  <Button className="button" title={t('Find work')} onClick={() => {window.location.href = "/register";}}>{t('Find work')}</Button>
                </div>
              </div>
            </div>
          </section>
          <div className={"video-container"}>
              <video autoPlay muted loop playsInline className="hero-video">
                <source src={`${STATIC3_URL}/compago-works-right-text-converted-mobile.mp4`} type="video/mp4" />
                Your browser does not support the video tag
              </video>
            </div>
        </div>
      )}
        {sticky ? (
          <Button
            id="heroApplyNowSticky"
            component={Link}
            size="large"
            variant="contained"
            color="primary"
            to="/register"
            fullWidth
          >
            {t('APPLY NOW')}
          </Button>
        ) : null}
      </div>
    )
  }
}
