import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import UsersIcon from "./../../../assets/services_page/service_layer.svg";
import { useNavigate } from "react-router-dom";
import BuildTeamButton from "../Button/BuildTeamButton";

const ServiceCard = ({ title, subTitle, showUserImg2, content, cardImage }) => {
  const navigate = useNavigate();
  return (
    <Card className="card-element">
      <div className="card-layer">
        <div className="card-layer-content">
          <div className="content">
            <div
              className={`icon-wrapper icon-wrapper_v2 ${
                !!showUserImg2 ? "add_btm_padding" : ""
              }`}
            >
              <img
                src={!!showUserImg2 ? showUserImg2 : UsersIcon}
                alt=""
              />
            </div>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="card-title card-title_v2"
            >
              {title}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="card-title "
            >
              {subTitle}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="card-description card-description_v2"
            >
              {content}
            </Typography>
            <BuildTeamButton/>
          </div>
        </div>
      </div>
      <div className="card-custom relative">
        <CardMedia
          component="img"
          height="470"
          image={cardImage}
          alt="green iguana"
        />
        <CardContent className="card-custom-content card-custom-content_v2">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="card-title card-title_v2"
          >
            {title}
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="card-title"
          >
            {subTitle}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="card-description"
          >
            {/* {content} */}
          </Typography>
          <CardActions className="padding-0">
          </CardActions>
        </CardContent>
      </div>
    </Card>
  );
};

export default ServiceCard;
