import axios from 'axios'
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useState } from 'react'
import { Footer } from './common'
import { HomePageNav } from './homepage/HomePageNav'

import './Reservations.scss'

export function Reservations() {
  const [state, _setState] = useState({
    name: '',
    arrival_date: null,
    departure_date: null,
    amenities: {},
    extra: '',
    saving: false,
    status: 'INITIAL',
    error: ''
  })

  const setState = partialState => _setState({ ...state, ...partialState })

  const submit = async () => {
    try {
      setState({ saving: true })
      const data = {
        name: state.name,
        arrival_date: state.arrival_date?.format('YYYY-MM-DD'),
        departure_date: state.departure_date?.format('YYYY-MM-DD'),
        amenities: state.amenities,
        extra: state.extra
      }
      await axios.post('/reservations', data)
      setTimeout(() => {
        setState({ saving: false, status: 'SUCCESS' })
      }, 2000)
    } catch (err) {
      setState({ saving: false, status: 'ERROR', error: err.message })
    }
  }

  return (
    <>
      <HomePageNav />
      <div className="Reservations">
        <div style={{ fontSize: 22, fontWeight: 600 }}>Reservations</div>
        {state.status === 'SUCCESS' ? (
          <div>Thanks!</div>
        ) : (
          <>
            <TextField label="Name" value={state.name} onChange={e => setState({ name: e.target.value })} />
            <DatePicker
              label="Date arriving"
              value={state.arrival_date}
              onChange={value => setState({ arrival_date: value })}
              renderInput={params => <TextField {...params} />}
            />
            <DatePicker
              label="Date departing"
              value={state.departure_date}
              onChange={value => setState({ departure_date: value })}
              renderInput={params => <TextField {...params} />}
            />
            <div className="amenities">
              <FormControl>
                <FormLabel>Amenities</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    label="Fan"
                    control={
                      <Checkbox
                        size="small"
                        checked={!!state.amenities?.fan}
                        onChange={e =>
                          setState({ ...state, amenities: { ...state.amenities, fan: !state.amenities?.fan } })
                        }
                      />
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    label="Nightlight"
                    control={
                      <Checkbox
                        size="small"
                        checked={!!state.amenities?.nightlight}
                        onChange={e =>
                          setState({
                            ...state,
                            amenities: { ...state.amenities, nightlight: !state.amenities?.nightlight }
                          })
                        }
                      />
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    label="Blackout curtains"
                    control={
                      <Checkbox
                        size="small"
                        checked={!!state.amenities?.blackout_curtains}
                        onChange={e =>
                          setState({
                            ...state,
                            amenities: { ...state.amenities, blackout_curtains: !state.amenities?.blackout_curtains }
                          })
                        }
                      />
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    label="Side table "
                    control={
                      <Checkbox
                        size="small"
                        checked={!!state.amenities?.side_table}
                        onChange={e =>
                          setState({
                            ...state,
                            amenities: { ...state.amenities, side_table: !state.amenities?.side_table }
                          })
                        }
                      />
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    label="Snacks "
                    control={
                      <Checkbox
                        size="small"
                        checked={!!state.amenities?.snacks}
                        onChange={e =>
                          setState({ ...state, amenities: { ...state.amenities, snacks: !state.amenities?.snacks } })
                        }
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>

            <TextField
              label="Anything else we can provide? We will do our best to accommodate"
              value={state.extra}
              onChange={e => setState({ ...state, extra: e.target.value })}
              multiline
              minRows={4}
            />

            {state.status === 'ERROR' ? <div>{state.error}</div> : null}
            <Button
              variant="contained"
              onClick={submit}
              disabled={state.saving || !state.name || !state.arrival_date || !state.departure_date}
              fullWidth
            >
              Submit
            </Button>
          </>
        )}
      </div>
      <Footer />
    </>
  )
}
