import './LinkTabs.scss'

import { Tab, Tabs } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function LinkTabs({ children }) {
  const values = React.Children.map(children, child => child.props.value)
  const { pathname } = useLocation()
  const currentTab = values.find(v => pathname.includes(v))
  return (
    <Tabs className="LinkTabs" value={currentTab}>
      {children}
    </Tabs>
  )
}

export function LinkTab(props) {
  const navigate = useNavigate()
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault()
        navigate(props.href)
      }}
      {...props}
    />
  )
}
