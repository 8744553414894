import './EmployerDashboard.scss'

import { Navigate, Route, Routes } from 'react-router-dom'
import { Nav, LinkTabs, LinkTab } from '../../common'
import { EmployerProfile } from './EmployerProfile'
import { MatchesPage } from './Matches'
import { Candidates } from './Candidates'
import { UserDetailBaseUrlContext } from '../../../context'
import { TeamMembers } from '../../TeamMembers'

export function EmployerDashboard() {
  return (
    <div className="EmployerDashboard">
      <UserDetailBaseUrlContext.Provider value="/candidates">
        <Nav />
        <LinkTabs>
          <LinkTab href="/matches" value="/matches" label="Matches" />
          <LinkTab href="/the-team" value="/the-team" label="The Team" />
          {/* <LinkTab href="/profile" value="/profile" label="Profile" /> */}
        </LinkTabs>
        <div className="tab-space" />
        <Routes>
          <Route index element={<Navigate to="matches" />} />
          <Route path="matches/*" element={<MatchesPage />} />
          <Route path="profile/*" element={<EmployerProfile />} />
          <Route path="candidates/*" element={<Candidates />} />
          <Route path="the-team/*" element={<TeamMembers listOnly />} />
          <Route path="*" element={<Navigate to="matches" />} />
        </Routes>
      </UserDetailBaseUrlContext.Provider>
    </div>
  )
}
