import './AdminDashboard.scss'

import { Navigate, Route, Routes } from 'react-router-dom'
import { Button } from '@mui/material'
import { LinkTab, LinkTabs, Nav } from '../../common'
import { t } from '../../../i18n'
import { Users } from './Users'
import { useState } from 'react'
import { ChangeViewDialog } from './ChangeViewDialog'
import { Clients } from './Clients'

export function AdminDashboard() {
  const [open, setOpen] = useState(false)

  const extraLinks = (
    <>
      <Button sx={{ color: '#fff' }} onClick={() => setOpen(true)}>
        {t('Spoof Client')}
      </Button>
      <Button sx={{ color: '#fff' }} onClick={() => (window.location = '/admin/')}>
        {t('Django Admin')}
      </Button>
    </>
  )

  return (
    <div className="AdminDashboard">
      <Nav extraLinks={extraLinks} />
      <LinkTabs>
        <LinkTab href="/users" value="/users" label="Users" />
        <LinkTab href="/clients" value="/clients" label="Clients" />
      </LinkTabs>
      <Routes>
        <Route index element={<Navigate to="users" />} />
        <Route path="users/*" element={<Users />} />
        <Route path="clients/*" element={<Clients />} />
        <Route path="*" element={<Navigate to="users" />} />
      </Routes>
      {open ? <ChangeViewDialog onClose={() => setOpen(false)} /> : null}
    </div>
  )
}
