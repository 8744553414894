import React from "react";
import GlobalHeader from "../common/HeaderNew";
import FooterNew from "../common/FooterNew";

const DashboardLayout = ({ children, showLogOut }) => {
  return (
    <div>
      <GlobalHeader showLogOut={showLogOut} />
      {children}
      <FooterNew />
    </div>
  );
};

export default DashboardLayout;
