import './UserDetail.scss'

import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Tab,
  TextField,
  Toolbar,
  Typography
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { LOADING, SignupStatus } from '../../constants'
import { useForceUpdate, useTradeListQuery, useUserDetailQuery } from '../../hooks'
import { confirm, UserAvatar } from '../common'
import { getFullImageUrl } from '../../util/stringUtil'
import { t } from '../../i18n'
import type { User } from '../../types'
import { api } from '../../api'
import { Note } from '../note/Note'
import DocumentRender from './DocumentRender'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

type Props = {
  onClose?: Function,
  onSave?: Function
}

export function UserDetail(props: Props) {
  const navigate = useNavigate()
  const { userId } = useParams()
  const { data: user, refetch } = useUserDetailQuery(userId)
  const { data: trades } = useTradeListQuery()
  // eslint-disable-next-line no-unused-vars
  const forceUpdate = useForceUpdate()
  const [noteText, setNoteText] = useState('')
  const [isPrivate, setPrivate] = useState(false)
  const [tab, setTab] = useState('1')
  const [open, setOpen] = useState(false)


  const onSave = () => {
    refetch()
    if (props.onSave) {
      props.onSave()
    }
  }

  const onClose = () => {
    if (props.onClose) {
      props.onClose()
    } else {
      navigate('/matches')
    }
  }

  const onAddNoteClick = async () => {
    const data = await api.userProfiles.addNote({ id: user.profile.id, text: noteText, is_private: isPrivate })
    console.log('[onAddNoteClick] data=%o', data)
    user.profile.notes.unshift(data)
    setNoteText('')
    setPrivate(false)
  }

  // const onBackgroundCheckChange = async file => {
  //   const updatedProfile = await api.userProfiles.addBackgroundCheck(user.profile.id, file)
  //   console.log('[onBackgroundCheckChange] data=%o', updatedProfile)
  //   user.profile = updatedProfile
  //   forceUpdate()
  // }

  if (user === LOADING || trades === LOADING || !user?.profile) {
    return <CircularProgress />
  }

  let { profile, email, employee_trades, speaks_english, speaks_spanish, speaks_some_english, speaks_some_spanish, comprehends_english, comprehends_spanish, comprehends_some_english, comprehends_some_spanish } = user

  let {
    data,
    // profile_pic,
    profile_vid,
    resume,
    background_check
    // example_pic_1,
    // example_pic_2,
    // example_pic_3,
    // example_pic_4,
    // example_pic_5,
  } = profile

  if (!data) {
    data = {}
  }

  let {
    // referred_by,
    // trade,
    // applying_for,
    start_date,
    // years_exp,
    can_travel,
    language,

    phone_number,

    // currently_employed,
    // current_employer,
    // current_role,
    // current_job_duration,
    // previous_employer,
    // previous_role,
    // reason_for_leaving,
    // unemployed_length,
    // reason_for_unemployment_gap,

    // current_pay,
    // desired_pay,
    // criminal_record,
    // criminal_record_info,

    allTrades
    // round_two_skills
  } = data

  const fields = [
    ['referred_by', 'Referred By'],
    ['employee_trades', 'Trade'],
    ['applying_for', 'Applying For'],
    ['start_date', 'Start Date'],
    ['years_exp', 'Years Exp'],
    ['can_travel', 'Can Travel'],
    ['language', 'Language'],

    ['phone_number', 'Phone Number'],

    ['currently_employed', 'Currently Employed'],
    ['current_employer', 'Current Employer'],
    ['current_role', 'Current Role'],
    ['current_job_duration', 'Current Job Duration'],
    ['previous_employer', 'Previous Employer'],
    ['previous_role', 'Previous Role'],
    ['reason_for_leaving', 'Reason For Leaving'],
    ['unemployed_length', 'Unemployed Length'],
    ['reason_for_unemployment_gap', 'Reason For Unemployment Gap'],

    ['current_pay', 'Current Pay'],
    ['desired_pay', 'Desired Pay'],
    ['criminal_record', 'Criminal Record'],
    ['criminal_record_info', 'Criminal Record Info'],

    ['allTrades', 'All Trades'],
    ['round_two_skills', 'Round Two Skills']
  ]

  const renderField = field => {
    const [key, label] = field
    let value = ''
    switch (key) {
      case 'start_date':
        if (start_date) {
          value = dayjs(start_date).format('M/D/YYYY')
        }
        break
      case 'can_travel':
        if (can_travel) {
          const travelLabels = {
            NO: t('No I need to stay close to home'),
            SOME_TRAVEL_OK: t('Some travel is ok'),
            CAN_TRAVEL: t("I'm willing to travel for the right opportunity as long as I have time off to go home"),
            LIVE_ON_SITE: t("I'm willing to live on site permanantly")
          }
          const tmp = []
          for (const [k, v] of Object.entries(can_travel)) {
            if (v) {
              tmp.push(travelLabels[k])
            }
          }
          if (tmp.length) {
            value = '- ' + tmp.join('\n- ')
          }
        }
        break
      case 'language':
        if (speaks_english || speaks_spanish || speaks_some_english || speaks_some_spanish || comprehends_english || comprehends_spanish || comprehends_some_english || comprehends_some_spanish) {
          const tmp = []
          if (speaks_english) {
            tmp.push("Speaks English")
          }
          if (speaks_spanish) {
            tmp.push("Speaks Spanish")
          }
          if (speaks_some_english) {
            tmp.push("Speaks Some English")
          }
          if (speaks_some_spanish) {
            tmp.push("Speaks Some Spanish")
          }
          if (comprehends_english) {
            tmp.push("Comprehends English")
          }
          if (comprehends_spanish) {
            tmp.push("Comprehends Spanish")
          }
          if (comprehends_some_english) {
            tmp.push("Comprehends Some English")
          }
          if (comprehends_some_spanish) {
            tmp.push("Comprehends Some Spanish")
          }
          if (tmp.length) {
            value = '- ' + tmp.join('\n- ')
          }
        }
        break
      case 'employee_trades':
        if (employee_trades) {
          const tmp = []
          for (const [k, v] of Object.entries(employee_trades)) {
            if (v) {
              tmp.push(v.trade.name)
            }
          }
          if (tmp.length) {
            value = '- ' + tmp.join('\n- ')
          }
        }
        break
      default:
        value = data[key]
    }

    if (label === "All Trades") {
      return (
        <div key={key} className={key}>
          <span>{label}: </span>
          <span>{Object.keys(value || {}).join(', ')}</span>
        </div>
      )
      } else {
      return (
        <div key={key} className={key}>
          <span>{label}: </span>
          <span>{value}</span>
        </div>
      )
    }
  }

  const handlePopup = () => {
    setOpen(!open)
    // const previewSrc = `http://localhost:3000/bundle/pdfBundle.js`;
    // const script = document.createElement('script');
    // script.src = `${previewSrc}?v=${new Date().getTime()}`;
    // script.defer = 'defer';
    // document.body.appendChild(script);
  }

  const labels = fields.map(renderField);

  return (
    <Dialog fullScreen open className="UserDetail" onClose={onClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {user.full_name}
          </Typography>
          <div className="right">
            {api.user.type === 'ADMIN' && <UpdateUserSignupStatusButton user={user} onSave={onSave} />}
            {api.user.type === 'ADMIN' && <GeneratePdfButton user={user} />}
          </div>
        </Toolbar>
      </AppBar>
      <div className="userprofile">
        <div className="left">
          <div className="avatar-container">
            <UserAvatar user={user} useDataUrl={false} width={170} height={170} />
          </div>
          <div className="user-name">{user.full_name || `${user.first_name} ${user.last_name}`}</div>
          <div style={{ textAlign: 'center' }}>{email}</div>
          <div style={{ textAlign: 'center' }}>{phone_number}</div>
        </div>
        <div className="right">
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(e, tab) => setTab(tab)} aria-label="lab API tabs example">
                <Tab label="Basic Info" value="1" />
                <Tab label="Files" value="2" />
                <Tab label="Notes" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="labelvalues">{labels}</div>
            </TabPanel>
            <TabPanel value="2">
              <div className="files">
                <div className="labelvalues">
                  <div>
                    <span>Intro video: </span>
                    <span>
                      {typeof profile_vid === 'string' && profile_vid.toLowerCase().endsWith('.mp4') ? (
                        <video src={profile_vid} controls />
                      ) : profile_vid ? (
                        <a href={profile_vid} target="_blank" rel="noreferrer">
                          View in new tab
                        </a>
                      ) : null}
                    </span>
                  </div>
                  <div>
                    <span>Resume: </span>
                    {resume ? (
                      <a href={getFullImageUrl(resume)} target="_blank" rel="noreferrer noopener">
                        View in new tab
                      </a>
                    ) : null}
                  </div>
                  <div>
                    <span>Background check: </span>
                    <div className="bcheck">
                      {background_check ? (
                        <a href={getFullImageUrl(background_check)} target="_blank" rel="noreferrer noopener">
                          View in new tab
                        </a>
                      ) : null}
                      {/* <span style={{ display: 'inline-block', marginLeft: 30 }}>
                        <FileUploadButton
                          onChange={e => onBackgroundCheckChange(e.target.files[0])}
                          buttonProps={{ size: 'small', variant: 'text' }}
                        />
                      </span> */}
                    </div>
                  </div>

                  {[
                    ['example_pic_1', 'Work photo 1'],
                    ['example_pic_2', 'Work photo 2'],
                    ['example_pic_3', 'Work photo 3'],
                    ['example_pic_4', 'Work photo 4'],
                    ['example_pic_5', 'Work photo 5']
                  ].map(([field, label]) => (
                    <div key={field}>
                      <span>{label}: </span>
                      {user.profile?.[field] ? (
                        <a href={getFullImageUrl(user.profile[field])} target="_blank" rel="noreferrer noopener">
                          <img src={getFullImageUrl(user.profile[field])} alt={field} />
                        </a>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className="notes">
                {user.profile?.notes?.map((note, i) => (
                  <Note key={i} note={note} />
                ))}
                <TextField
                  multiline
                  minRows={3}
                  label="Add note"
                  value={noteText}
                  onChange={e => setNoteText(e.target.value)}
                />
                <FormGroup>
                  <FormControlLabel
                    label={t('Private')}
                    control={<Checkbox checked={isPrivate} onChange={e => setPrivate(!isPrivate)} />}
                  />
                </FormGroup>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={onAddNoteClick}
                  disabled={!noteText.trim()}
                  style={{ marginTop: 10 }}
                >
                  Add Note
                </Button>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
      {open && <DocumentRender open={open} onClose={handlePopup} user={user} />}
    </Dialog>

  )
}

function GeneratePdfButton(props) {

  const onClick = () => {
    api.employees.generatePdf(props.user)
  }

  return (
    // <Button sx={{ color: '#fff' }} onClick={() => handleOpen()}>
    //   {t('Generate PDF')}
    // </Button>
    <Button sx={{ color: '#fff' }} onClick={() => { onClick() }}>
      {t('Generate PDF')}
    </Button>
  )
}

function UpdateUserSignupStatusButton(props) {
  const user: User = props.user

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const onAcceptClick = async () => {
    const body = t(
      'Are you sure? This will send an email to the user letting them know they can submit the next round of info.'
    )
    if (!(await confirm({ body }))) {
      return
    }

    try {
      await api.employees.approveForRoundTwo(user)
      setAnchorEl(null)
      props.onSave()
    } catch (err) {
      //
    }
  }

  const onRejectClick = async () => {
    const body = t('Are you sure? This will send an email to the user letting them know they have been turned down.')
    if (!(await confirm({ body }))) {
      return
    }

    try {
      await api.employees.reject(user)
      setAnchorEl(null)
      props.onSave()
    } catch (err) {
      //
    }
  }

  let label
  if (user.profile?.signup_status in SignupStatus) {
    label = t(SignupStatus[user.profile.signup_status])
  } else {
    label = t('Unknown')
  }

  return (
    <div>
      <Button
        id="UpdateUserSignupStatusButton"
        aria-controls={open ? 'UpdateUserSignupStatusButton_Menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={e => setAnchorEl(e.currentTarget)}
        sx={{ color: '#fff' }}
      >
        {t('Signup Status')}: {label}
      </Button>
      <Menu id="UpdateUserSignupStatusButton_Menu" anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onAcceptClick}>
          <ListItemIcon>
            <CheckIcon fontSize="small" color="success" />
          </ListItemIcon>
          <ListItemText>Move to next round</ListItemText>
        </MenuItem>
        <MenuItem onClick={onRejectClick}>
          <ListItemIcon>
            <HighlightOffIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Reject</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}
