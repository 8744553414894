import React from "react";
import { Container, Typography } from "@mui/material";
import { t } from "../../../../i18n";
import ButtonCustom from "../../../common/Button";
import { useNavigate } from "react-router-dom";

export default function FindWork() {
    const navigate = useNavigate();
    return (
        <div className="findWorkMainWrap">
            <Container maxWidth="xl">
                <div className="findWork_content">
                    <Typography variant="h1">
                        {t("Building Tomorrow")}
                        <span className="findWork_heading">{t("Together")}</span>
                    </Typography>
                    <p>
                        {t(
                            `Welcome to Compago, where we don't just build structures; we build futures.
                            Our mission to innovate and lead in the construction industry is made possible by our talented team.Here, you’ll find more than a job. You’ll find a community committed to making a difference, pushing boundaries, and shaping the world for the better.`
                        )}
                    </p>
                    <ButtonCustom
                        variant="contained"
                        className="custom-btn"
                        onClick={() => navigate("/register")}
                    >
                        {t("Find Work")}
                    </ButtonCustom>
                </div>
            </Container>
        </div>
    );
}
