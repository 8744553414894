// eslint-disable-next-line
const re =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isValidEmail(str: String) {
  return re.test(str);
}

export function isValidPhoneNumber(str: String) {
  if (typeof str !== "string") {
    str = String(str);
  }
  str = str.trim();
  if (/\(\d{3}\) \d{3}-\d{4}/.test(str)) {
    return true;
  }
  if (/\d{3}-\d{3}-\d{4}/.test(str)) {
    return true;
  }
  if (/[+]\d{10}/.test(str)) {
    return true;
  }
  if (/[+]\d{11}/.test(str)) {
    return true;
  }
  if (Number.isInteger(Number(str)) && str.length === 10) {
    return true;
  }
}

export function isEmptyString(str: String) {
  try {
    return str != null && String(str).trim().length === 0;
  } catch {
    return true;
  }
}

export function getFullName(user) {
  if (!user) return "";
  const f = user.first_name;
  const l = user.last_name;
  const e = user.email;
  if (f && l) return `${f} ${l}`;
  if (f) return f;
  return e;
}

export function getFirstNameAndLastInitial(user) {
  if (!user) return "";
  const f = user.first_name;
  const l = user.last_name;
  if (f && l) return `${f} ${l[0]}`;
  if (f) return f;
  return "";
}

export function formatPhoneNumber(str: String) {
  let original = str;
  try {
    str = str.replace(/\D/g, "");
    if (str.length === 11 && str[0] === "1") {
      str = str.substring(1);
    }
    if (str.length !== 10) {
      return original;
    }
    const areacode = str.substring(0, 3);
    const part1 = str.substring(3, 6);
    const part2 = str.substring(6);
    return `(${areacode}) ${part1}-${part2}`;
  } catch {
    return original;
  }
}

export function getFullImageUrl(url: string) {
  if (!url) return "";

  if (process.env.NODE_ENV === "development" && !url.startsWith("http")) {
    return "http://localhost:8000" + url;
  }

  if (!url.includes("/uploads/")) {
    url = "/uploads/" + url;
  }

  return url;
}
