import './Candidates.scss'

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { UserDetail } from '../../user'

export function Candidates() {
  return (
    <div className="Candidates">
      <Routes>
        <Route path=":userId" element={<UserDetail />} />
      </Routes>
    </div>
  )
}
