import { Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { t } from "../../../../i18n";
import ButtonCustom from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { GetQuote } from "../../../homepage/GetQuote";
import BuildTeamButton from "../../../common/Button/BuildTeamButton";
import FindWorkButton from "../../../common/Button/FindWorkButton";

export default function Experties() {
  const navigate = useNavigate();
  const [showGetQuote, toggleGetQuote] = useState(false);
  const onGetQuoteClick = () => toggleGetQuote(true);

  return (
    <div className="expertiesMainWrap" style={{ position: "relative" }}>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          className="experties_row"
        >
          <Grid
            item
            md={8}
            xs={12}
            lg={8}
            sm={12}
            className="expertiesMain_content"
          >
            <Typography variant="h1">
              {t("Expertise Meets")}
              <span className="bannerHeading">{t("Innovation")}</span>
              {t("in Construction")}
            </Typography>
            <p>
              {t(
                `At Compago we specialize in trained on-demand labor, skilled construction tradesmen and custom building solutions. Our commitment to investing in and educating our team is reflected in our high level of client satisfaction. Our quality sets us apart.`
              )}
            </p>

            <div className="how-it-works-new-btns">
              <BuildTeamButton />
              <FindWorkButton />
            </div>
          </Grid>
          <Grid item md={4} xs={12} lg={4} sm={5}></Grid>
          {/* <div className="experties_us_bg">
            <img src={experties_right} alt="" />
          </div> */}
        </Grid>
      </Container>
      {showGetQuote ? <GetQuote onClose={() => toggleGetQuote(false)} /> : null}
    </div>
  );
}
