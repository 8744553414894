import './Step4.scss'

import _ from 'lodash'
import { t } from '../../../../i18n'
import { useEmployeeSignupRoundTwoContext } from '../../../../hooks'
import { FileUploadButton } from '../../../common'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export function Step4() {
  const { user, setUser } = useEmployeeSignupRoundTwoContext()


  const onImageChange = (prop, val) => {
    const tmp = _.cloneDeep(user)
    const allowedFormats = ["png", "jpg", "jpeg"];
    const fileFormat = val.name.split('.').pop().toLowerCase();

    if (allowedFormats.includes(fileFormat)) {
      _.set(tmp, prop, val)
    }
    setUser(tmp)
  }

  const p1 = t('Show us your work.')
  const p2 = t(
    'Employers would love to see the quality of work that you can perform. Please provide up to five photos.'
  )
  const navigate = useNavigate()
  const back = () => {
    navigate('/signup-round-two/step-3')
  };

  const next = async () => {
    navigate('/signup-round-two/step-5')
  };
  return (
    <Dialog
      fullWidth
      open={true}
      className="step4"
    >
      <DialogTitle mt={2}>
        <Typography variant="h4" color="text.secondary" align="center">
          {t('Signup - Round Two')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {p2}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FileUploadButton
            accept=".png, .jpg,.jpeg"
            value={user?.profile?.example_pic_1}
            onChange={e => onImageChange('profile.example_pic_1', e.target.files[0])}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadButton
            accept=".png, .jpg,.jpeg"
            value={user?.profile?.example_pic_2}
            onChange={e => onImageChange('profile.example_pic_2', e.target.files[0])}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadButton
            accept=".png, .jpg,.jpeg"
            value={user?.profile?.example_pic_3}
            onChange={e => onImageChange('profile.example_pic_3', e.target.files[0])}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadButton
            accept=".png, .jpg,.jpeg"
            value={user?.profile?.example_pic_4}
            onChange={e => onImageChange('profile.example_pic_4', e.target.files[0])}
          />
        </Grid>
        <Grid item xs={12}>
          <FileUploadButton
            accept=".png, .jpg,.jpeg"
            value={user?.profile?.example_pic_5}
            onChange={e => onImageChange('profile.example_pic_5', e.target.files[0])}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button
              fullWidth size="large"
              color="primary"
              variant="contained"
              onClick={back}
            >
              {t('Back')}
            </Button>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              onClick={next}
            >
              {t('Next')}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
