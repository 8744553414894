import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import mission_bg from "../../../../assets/about_page/mission.svg";
import mission from "../../../../assets/about_page/mission_border_line.png";
import vector1 from "../../../../assets/homepage/vector1.png";
import { t } from "../../../../i18n";

export default function OurMission() {
  return (
    <div className="ourMissionWrap">
      <Container maxWidth="xl">
        <img src={mission} alt="" className="mission" />

        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} md={8} lg={8} className="ourMission_content">
            <Typography variant="h1">
              {t(`Our Mission`)}
              <img src={mission_bg} className="mission_icon" />
            </Typography>
            <p>
              {t(
                `We strive to remove the headaches of HR and create an exceptional team for your project through vetting and education. Our clients can expect exceptional quality and enthusiasm from our team. Our goal is to truly provide opportunity for personal and professional growth for our team. We treat them fairly and always pay on time. This results in a team that consistently exceeds client expectations.`
              )}
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
