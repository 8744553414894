import { Resource } from './base'

export class MeResource extends Resource {
  constructor() {
    super('me')
    this.changePassword = this._createEndpoint({ endpointName: 'change_password' })
  }
}

export const me = new MeResource()
