import React from 'react'
import { useNavigate } from "react-router-dom";
import ButtonCustom from '.';
import { t } from "../../../i18n";

const BuildTeamButton = () => {
    const navigate = useNavigate();
    return (
        <ButtonCustom
            variant="contained"
            className="custom-btn"
            onClick={() => navigate("/contact")}
        >
            {t("Build Your Team")}
        </ButtonCustom>
    )
}

export default BuildTeamButton
