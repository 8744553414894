import './GetQuote.scss'

import { Dialog } from '@mui/material'
import { Form } from '../common/Form'
import { t } from '../../i18n'
import { useContactUsForm } from '../Contact'

export function GetQuote({ onClose }) {
  const { state, setState, saving, done, onSubmit } = useContactUsForm()

  return (
    <Dialog onClose={onClose} open>
      <div className="GetQuote">
        <div className="header">{t('Contact us to get a quote or schedule a professional')}</div>
        <div className="body">
          {done ? (
            <div style={{ minHeight: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {t("Thanks! We'll be in touch.")}
            </div>
          ) : (
            <Form
              fields={state}
              onChange={state => setState(state)}
              validate={() => state[0][2].trim() && state[1][2].trim() && state[2][2].trim()}
              onSubmit={onSubmit}
              saving={saving}
            />
          )}
        </div>
      </div>
    </Dialog>
  )
}
