import './Form.scss'

import React, { useState } from 'react'
import { Button, Checkbox, FormLabel, IconButton, InputAdornment, Radio, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import _ from 'lodash'
import { t } from '../../i18n'

export function Form(props) {
  const [show, setShow] = useState(false)

  const onChange = (i, prop, value, e) => {
    const nextState = _.cloneDeep(props.fields)
    nextState[i][2] = value
    if (props.version === 2) {
      props.onChange(prop, value, e)
    } else {
      props.onChange(nextState)
    }
  }

  const onSubmit = (e: Event) => {
    e.preventDefault()
    props.onSubmit(props.fields)
  }

  const renderField = (field, i) => {
    let [label, name, value, type = 'text', extraProps = {}] = field
    extraProps = { ...extraProps }
    const isCheckbox = ['checkbox', 'radio'].includes(type)
    if (type === 'currency') {
      extraProps.InputProps = { startAdornment: <InputAdornment position="start">$</InputAdornment> }
    } else if (type === 'password') {
      extraProps.type = show ? 'text' : 'password'
      extraProps.InputProps = {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShow(!show)}
              onMouseDown={e => e.preventDefault()}
              edge="end"
            >
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }
    }

    let ui
    if (type === 'checkbox') {
      ui = <Checkbox {...extraProps} checked={value} onChange={e => onChange(i, name, e.target.checked, e)} />
    } else if (type === 'radio') {
      ui = <Radio {...extraProps} checked={value} onChange={e => onChange(i, name, e.target.checked, e)} />
    } else if (type === 'formGroup') {
      ui = (
        <div className="form-group">
          <FormLabel>{t(label)}</FormLabel>
          {extraProps.children}
        </div>
      )
    } else {
      ui = (
        <TextField
          {...extraProps}
          label={t(label)}
          value={value}
          onChange={e => onChange(i, name, e.target.value, e)}
        />
      )
    }

    return (
      <div className="field" key={i}>
        {isCheckbox ? <label>{t(label)}</label> : null}
        {ui}
        <div className="input">{type === 'currency' ? <span className="adornment">$</span> : null}</div>
      </div>
    )
  }

  const renderSubmitButton = () => {
    const { validate = () => {} } = props
    const CustomButton = props.submitButton
    const disabled = props.saving || !validate(props.fields)
    let ui
    if (CustomButton === null) {
      return null
    } else if (CustomButton) {
      ui = <CustomButton disabled={disabled} />
    } else {
      ui = (
        <SubmitButton variant="contained" disabled={disabled}>
          {t('Submit')}
        </SubmitButton>
      )
    }
    return ui
  }

  return (
    <form className="Form" onSubmit={onSubmit}>
      {props.fields.map(renderField)}
      {renderSubmitButton()}
    </form>
  )
}

function allNonEmpty(fields) {
  for (const field of fields) {
    const value = field[2]
    if (typeof value === 'string' && value.trim() === '') {
      return false
    }
  }
  return true
}

export const validators = {
  allNonEmpty
}

function SubmitButton(props) {
  const onRef = node => {
    if (node) {
      node.type = 'submit'
    }
  }

  return <Button {...props} ref={onRef} />
}
